import { createSlice } from "@reduxjs/toolkit";

export const teethFormulaSelectSlice = createSlice({
  name: "teethSelect",
  initialState: {
    isSelect: false,
    tabKey: "details",
    activeProductId: "",
  },
  reducers: {
    setIsSelect: (state, action) => {
      state.isSelect = action.payload;
    },
    setTabKeyLeft: (state, action) => {
      state.tabKey = action.payload;
    },
    setActiveProductId: (state, action) => {
      state.activeProductId = action.payload;
    },
  },
});

export const { setIsSelect, setTabKeyLeft, setActiveProductId } =
  teethFormulaSelectSlice.actions;

export default teethFormulaSelectSlice.reducer;
