import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Space, Tree, TreeProps } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { DataNode } from "antd/es/tree";

import styles from "./Products.module.scss";

import useProducts from "@/hooks/useProducts";
import { Product } from "@/root/models/product";
import { Loader, Print } from "@/components/common";
import useEditProductGroup from "@/modules/ProductCatalog/components/Products/hooks/useEditProductGroup";
import { PricelistsApi } from "@/api/admin/pricelists";
import { PricelistTypeEnum } from "@/components/PricelistForm";
import { Pricelist } from "@/root/models/pricelist";
import ProductItem from "./components/ProductItem";
import AddProductModal from "./components/AddProductModal";

const Products = () => {
  const { t } = useTranslation();
  const { products, getProductsLoading } = useProducts();
  const [addModal, setAddModal] = useState(false);
  const [emptyElementDraggable, setEmptyElementDraggable] = useState(false);
  const editProductGroup = useEditProductGroup();
  const [currentPricelist, setCurrentPricelist] = useState<Pricelist>();

  useEffect(() => {
    (async () => {
      const pricelist = await PricelistsApi.getCurrentPricelist(
        PricelistTypeEnum.ProductsCatalog
      );
      setCurrentPricelist(pricelist);
    })();
  }, []);

  const parseProducts = (products: Product[]): TreeProps["treeData"] =>
    products.map((product) => ({
      key: product.productUUID,
      title: <ProductItem product={product} />,
      children: product.children ? parseProducts(product.children) : [],
    }));

  const parsePrintProducts = (products: Product[]): TreeProps["treeData"] =>
    products.map((product) => {
      const price = currentPricelist?.list.find((item) => {
        return (
          "productUUID" in item.item &&
          item.item.productUUID === product.productUUID
        );
      })?.price;

      return {
        key: product.productUUID,
        title: <ProductItem product={product} price={price} />,
        children: product.children ? parsePrintProducts(product.children) : [],
      };
    });

  const handleAddProduct = () => {
    setAddModal(false);
  };

  const findProductByUUID = (
    products: Product[],
    uuid: string
  ): Product | undefined => {
    let foundProduct;
    for (const product of products) {
      if (product.productUUID === uuid) {
        foundProduct = product;
        break;
      } else {
        if (product.children) {
          const candidate = findProductByUUID(product.children, uuid);
          if (candidate) {
            foundProduct = candidate;
            break;
          }
        }
      }
    }
    return foundProduct;
  };

  const handleDrop: TreeProps["onDrop"] = async ({ node, dragNode }) => {
    const dragItemUUID = dragNode.key as string;

    const product: Product | undefined = findProductByUUID(
      products,
      dragItemUUID
    );

    if (product) {
      setEmptyElementDraggable(false);
      if ((node.key as string).includes("empty")) {
        await editProductGroup(product, "");
        return;
      }
      const parentProductUUID = node.key as string;
      await editProductGroup(product, parentProductUUID);
    }
  };

  const allowDrop: TreeProps["allowDrop"] = ({ dropNode }) => {
    // @ts-ignore
    const product: Product = dropNode?.title?.props?.product;
    if (product) {
      return product.isGroup && product.level < 5;
    }

    return true;
  };

  const treeData: DataNode[] = [
    {
      key: "empty-1",
      title: "",
      switcherIcon: <PlusOutlined />,
      style: emptyElementDraggable ? undefined : { pointerEvents: "none" },
    },
    ...(parseProducts(products) as DataNode[]),
    {
      key: "empty-2",
      title: "",
      switcherIcon: <PlusOutlined />,
      style: emptyElementDraggable ? undefined : { pointerEvents: "none" },
    },
  ];

  return (
    <Space direction="vertical">
      <Space>
        <Button size="small" onClick={() => setAddModal(true)}>
          {t("Додати")}
        </Button>
        <Button
          size="small"
          onClick={() => {
            window.print();
          }}
        >
          {t("Друк")}
        </Button>
      </Space>
      {getProductsLoading ? (
        <Loader />
      ) : (
        <>
          <Print>
            <Tree
              className={styles.tree}
              defaultExpandAll
              treeData={parsePrintProducts(products) as DataNode[]}
            />
          </Print>
          <Tree
            className={styles.tree}
            selectable={false}
            allowDrop={allowDrop}
            draggable={{
              icon: false,
            }}
            blockNode
            treeData={treeData}
            onDrop={handleDrop}
            onDragStart={() => setEmptyElementDraggable(true)}
            onDragEnd={() => setEmptyElementDraggable(false)}
          />
        </>
      )}
      {addModal && (
        <AddProductModal
          open={addModal}
          onCancel={() => setAddModal(false)}
          onAdd={handleAddProduct}
        />
      )}
    </Space>
  );
};

export default Products;
