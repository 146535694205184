import { Modal, Table } from "antd";
import { useEffect, useState } from "react";
import styles from "../../InvoicesPanel/InvoicePanel.module.scss";
import { InvoiceWithChildren } from "@/root/models/invoiceWithChildren";
import { ModifiedInvoiceWithItems } from "@/root/models/invoice";
import { ColumnsType } from "antd/es/table";
import { t } from "i18next";

interface InvoiceItemsViewProps {
  instance: InvoiceWithChildren;
  tableInstancesArray: ModifiedInvoiceWithItems[];
  modalOpen: string | null;
  onHandleCancel: () => void;
  type: string;
}

interface CaseItems {
  rowUUID?: string;
  sum?: number;
  item?: { name: string; itemUUID: string };
  itemType?: string;
}

const InvoiceItemsView = ({
  instance,
  tableInstancesArray,
  modalOpen,
  onHandleCancel,
  type,
}: InvoiceItemsViewProps) => {
  const [caseItems, setCaseItems] = useState<CaseItems[]>([]);

  useEffect(() => {
    if (instance) {
      //Find parent invoice
      const sortCases = tableInstancesArray?.find(
        (elem) => elem.invoiceNumber === instance?.parent
      );
      //Filter cases
      const items = sortCases?.children.filter(
        (elem) => elem.case.caseUUID === instance.case?.caseUUID
      );
      //Set items
      if (items?.[0].items) {
        setCaseItems(items?.[0].items);
      }
    }
  }, [instance]);

  const itemsColumns: ColumnsType<CaseItems> = [
    {
      width: 150,
      title: t("Елемент"),
      dataIndex: "item",
      key: "name",
      render: (_: any, instance) => <span>{instance.item?.name}</span>,
    },
    {
      width: 150,
      title: t("Тип елементу"),
      dataIndex: "itemType",
      key: "itemType",
      render: (_: any, instance) => <span>{instance.itemType}</span>,
    },
    {
      width: 100,
      title: t("Сума"),
      dataIndex: "sum",
      key: "sum",
      render: (_: any, instance) => <div>{instance.sum}</div>,
    },
  ];

  return (
    <>
      <Modal
        centered
        width={1000}
        open={modalOpen === instance.key}
        className={styles.modal_footer_hidden}
        onCancel={onHandleCancel}
      >
        <Table
          columns={itemsColumns}
          pagination={false}
          dataSource={caseItems}
        />
      </Modal>
    </>
  );
};

export default InvoiceItemsView;
