import { useEffect } from "react";
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import { Alert, Divider, Form, FormInstance, Input, Select } from "antd";

import { CreateClientEmployeePayload } from "@/api/client/employees";
import useFormAutoFocusInput from "@/hooks/useFormAutoFocusInput";
import useUserTypes from "@/hooks/useUserTypes";
import { UserTypeEnum } from "@/root/types";

dayjs.extend(weekday);
dayjs.extend(localeData);

const { Option } = Select;

interface ClientEmployeeFormProps {
  form: FormInstance<CreateClientEmployeePayload>;
  handleSubmit: (values: CreateClientEmployeePayload) => void;
  error: string;
}

const ClientEmployeeForm = ({
  form,
  handleSubmit,
  error,
}: ClientEmployeeFormProps) => {
  const { t } = useTranslation();
  const { userTypes, getUserTypesLoading } = useUserTypes();
  const focusInputRef = useFormAutoFocusInput();

  useEffect(() => {
    const clientEmployeeType = userTypes.find(
      (type) => type.userTypeUUID === UserTypeEnum["Client's employee"]
    );
    if (clientEmployeeType) {
      form.setFieldValue("userTypeUUID", clientEmployeeType.userTypeUUID);
    }
  }, [userTypes]);

  const filteredUserTypes = userTypes.filter(
    (type) =>
      type.userTypeUUID !== UserTypeEnum["Empty"] &&
      type.userTypeUUID !== UserTypeEnum["Lab's admin"] &&
      type.userTypeUUID !== UserTypeEnum["Employee"]
  );

  return (
    <Form requiredMark={false} form={form} onFinish={handleSubmit}>
      <Form.Item
        rules={[
          {
            required: true,
            message: t("Введіть ім'я") as string,
          },
        ]}
        name="name"
        label={t("Ім'я.name")}
      >
        <Input
          ref={focusInputRef}
          placeholder={t("Введіть значення") as string}
        />
      </Form.Item>

      <Form.Item
        rules={[
          {
            required: true,
            message: t("Заповніть поле") as string,
          },
        ]}
        name="email"
        label={t("Пошта")}
      >
        <Input placeholder={t("Введіть значення") as string} />
      </Form.Item>

      <Form.Item
        rules={[
          {
            required: true,
            message: t("Заповніть поле") as string,
          },
        ]}
        name="userTypeUUID"
        label={t("Тип користувача")}
      >
        <Select listHeight={1000} loading={getUserTypesLoading} allowClear>
          {filteredUserTypes.map((type) => (
            <Option key={type.userTypeUUID} value={type.userTypeUUID}>
              {type.name || " "}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Divider />

      <Form.Item
        rules={[
          {
            required: true,
            message: t("Заповніть поле") as string,
          },
        ]}
        name="login"
        label={t("Логін")}
      >
        <Input placeholder={t("Введіть значення") as string} />
      </Form.Item>

      <Form.Item required name="password" label={t("Пароль")}>
        <Input placeholder={t("Введіть значення") as string} />
      </Form.Item>

      {!!error && <Alert message={error} type="error" />}
    </Form>
  );
};

export default ClientEmployeeForm;
