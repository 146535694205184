import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Tabs, TabsProps } from "antd";

import { SearchInput } from "@/components";
import ClientEmployees from "../ClientEmployees";
import { Patients } from "./components";

const MyCompany = () => {
  const { t } = useTranslation();
  const [tabKey, setTabKey] = useState("patients");

  const items: TabsProps["items"] = [
    {
      key: "patients",
      label: t("Пацієнти"),
      children: <Patients />,
    },
    {
      key: "doctors",
      label: t("Співробітники"),
      children: <ClientEmployees />,
    },
    {
      key: "feedbacks",
      label: t("Зворотній зв'язок"),
    },
  ];

  return (
    <div>
      <div style={{ marginBottom: 20 }}>
        <SearchInput />
      </div>
      <Tabs
        type="card"
        items={items}
        activeKey={tabKey}
        onTabClick={setTabKey}
      />
    </div>
  );
};

export default MyCompany;
