import { CaseChat, ChatTypes, TaskChat } from "@/root/models/chat";
import { ChatObjectEnum, NotExistEnum } from "@/root/types";
import {
  Conversation,
  ConversationList,
  Search,
  Sidebar,
} from "@chatscope/chat-ui-kit-react";
import { Alert } from "antd";
import { t } from "i18next";
import { useState } from "react";
import DropdownChatOptions from "./DropdownChatOptions";

interface ObjectsListProps {
  chats: ChatTypes[];
  selectedChat: ChatTypes | undefined;
  setSelectedChat: (chat: ChatTypes) => void;
  openChatUUID: string | undefined;
  chatNotifications: ChatTypes[] | undefined;
  chatsLoading: boolean;
}

const ObjectsList = ({
  chats,
  selectedChat,
  setSelectedChat,
  openChatUUID,
  chatsLoading,
  chatNotifications,
}: ObjectsListProps) => {
  const [searchValue, setSearchValue] = useState("");

  //Filtered chats on search value
  const filteredChats = chats.filter((elem) => {
    const taskElem = elem as TaskChat;
    const caseElem = elem as CaseChat;

    return elem.chatObject.chatObjectUUID === ChatObjectEnum["Case"]
      ? caseElem.object.caseNumber.includes(searchValue)
      : taskElem.object.taskNumber.includes(searchValue);
  });

  return (
    <Sidebar position="left" scrollable={false}>
      <Search
        placeholder={`${t("Пошук")}...`}
        onChange={(value) => setSearchValue(value)}
        onClearClick={() => setSearchValue("")}
      />

      {!chats.length ? (
        <Alert
          style={{ margin: "0 8px", fontWeight: "500" }}
          message={t("Немає актуальних чатів")}
          type="info"
        />
      ) : (
        <ConversationList loading={chatsLoading}>
          {(searchValue ? filteredChats : chats).map((elem: ChatTypes) => {
            const taskElem = elem as TaskChat;
            const caseElem = elem as CaseChat;

            return (
              <DropdownChatOptions chatUUID={elem.chatUUID} key={elem.chatUUID}>
                <Conversation
                  unreadCnt={Number(
                    chatNotifications?.find(
                      (chatNotif) => chatNotif.chatUUID === elem.chatUUID
                    )?.quantity
                  )}
                  name={
                    elem.chatObject.chatObjectUUID === ChatObjectEnum["Case"]
                      ? `${t("Замовлення №")}  ${caseElem.object.caseNumber}`
                      : `${t("Завдання №")}  ${taskElem.object.taskNumber}`
                  }
                  lastSenderName={
                    !!elem.messages.length &&
                    elem.messages[elem.messages.length - 1].user.name
                  }
                  info={
                    !!elem.messages.length &&
                    elem.messages[elem.messages.length - 1].message
                      .split("<br>")
                      .join("\n")
                  }
                  onClick={() => setSelectedChat(elem)}
                  active={(() => {
                    if (
                      openChatUUID &&
                      openChatUUID !== NotExistEnum["NullUUID"]
                    ) {
                      return openChatUUID === elem.chatUUID;
                    }

                    if (
                      openChatUUID === NotExistEnum["NullUUID"] ||
                      !selectedChat ||
                      !chats.find(
                        (elem) => elem.chatUUID === selectedChat.chatUUID
                      )
                    ) {
                      return chats[0].chatUUID === elem.chatUUID;
                    }

                    if (selectedChat) {
                      return selectedChat.chatUUID === elem.chatUUID;
                    }
                  })()}
                />
              </DropdownChatOptions>
            );
          })}
        </ConversationList>
      )}
    </Sidebar>
  );
};

export default ObjectsList;
