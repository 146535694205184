import { Route, Routes } from "react-router-dom";
import { Cases, EditCase, MakeCase } from "./pages";

const CasesPanel = () => {
  return (
    <Routes>
      <Route path="create" element={<MakeCase />} />
      <Route path="edit" element={<EditCase />} />
      <Route path="*" element={<Cases />} />
    </Routes>
  );
};

export default CasesPanel;
