import { useAppSelector } from "@/hooks/redux";
import useClientsCurrentPricelist from "@/hooks/useClientsCurrentPricelist";
import { PricelistTypeEnum } from "@/components/PricelistForm";
import ReportsPanelPricelist from "./ReportsPanelPricelist";

const ExtrasPricelist = () => {
  const client = useAppSelector((state) => state.userReducer.user?.client);
  const { pricelist, isLoading } = useClientsCurrentPricelist(
    client?.clientUUID || "",
    PricelistTypeEnum.Extras
  );

  return <ReportsPanelPricelist pricelist={pricelist} loading={isLoading} />;
};

export default ExtrasPricelist;
