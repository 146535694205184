import { Button, Modal, Space, notification } from "antd";
import { DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import { t } from "i18next";
import { Invoice } from "@/root/models/invoice";
import { useForm } from "antd/es/form/Form";
import { useAppSelector } from "@/hooks/redux";
import { UserTypeEnum } from "@/root/types";
import { useEffect } from "react";
import useInvoices from "@/hooks/useInvoices";
import { pages } from "@/root/consts";
import { useNavigate } from "react-router-dom";
import InvoiceViewModal from "./InvoiceViewModal";

const { confirm } = Modal;

interface InvoicesTableActionsProps {
  invoice: Invoice;
  modal: string | null;
  onCancel: () => void;
  onModalViewOpen: () => void;
}

const InvoicesTableActions = ({
  invoice,
  modal,
  onCancel,
  onModalViewOpen,
}: InvoicesTableActionsProps) => {
  const userType = useAppSelector((state) => state.userReducer.user?.userType);

  const [form] = useForm();

  const navigate = useNavigate();

  const onDeleteError = (message: string) => {
    notification.error({
      message,
    });
  };

  const onDeleteSuccess = () => {
    notification.success({
      message: t("Рахунок-фактура був успішно видалений"),
    });
  };

  const { deleteInvoice, deleteLoading } = useInvoices({
    onDeleteError,
    onDeleteSuccess,
  });

  const onInvoiceEdit = () => {
    if (invoice.paid) {
      confirm({
        title: t("Рахунок-фактура вже сплачений!"),
        content: t("Ви дійсно хочете внести зміни?"),
        okText: t("Так"),
        okType: "danger",
        cancelText: t("No"),
        onOk: () => {
          if (userType?.userTypeUUID === UserTypeEnum["Lab's admin"]) {
            navigate(pages.admin.invoices.edit.path, { state: invoice });
          } else {
            navigate(pages.lab.invoices.edit.path, { state: invoice });
          }
        },
      });
    } else {
      if (userType?.userTypeUUID === UserTypeEnum["Lab's admin"]) {
        navigate(pages.admin.invoices.edit.path, { state: invoice });
      } else {
        navigate(pages.lab.invoices.edit.path, { state: invoice });
      }
    }
  };

  const handleDelete = async () => {
    await deleteInvoice(invoice.invoiceUUID);
  };

  const handleDeleteButtonClick = () => {
    confirm({
      title: t("Підтвердити видалення"),
      okText: t("Видалити"),
      okType: "danger",
      cancelText: t("Скасувати"),
      onOk: handleDelete,
    });
  };

  useEffect(() => {
    if (modal) {
      form.setFieldsValue({
        invoiceNumber: invoice.invoiceNumber,
        client: invoice.client?.clientUUID,
        description: invoice.description,
        amount: invoice.amount,
        paid: invoice.paid,
      });
    }
  }, [modal]);

  return (
    <>
      {userType?.userTypeUUID === UserTypeEnum["Employee"] ||
      userType?.userTypeUUID === UserTypeEnum["Lab's admin"] ? (
        <Space className="table-actions" direction="vertical">
          <Button
            size="small"
            icon={<EyeOutlined />}
            onClick={onModalViewOpen}
          />
          <Button
            size="small"
            icon={<EditOutlined />}
            onClick={() => onInvoiceEdit()}
          />
          <Button
            size="small"
            icon={<DeleteOutlined />}
            danger
            loading={deleteLoading}
            onClick={() => handleDeleteButtonClick()}
          />
        </Space>
      ) : (
        <Button size="small" icon={<EyeOutlined />} onClick={onModalViewOpen} />
      )}

      <InvoiceViewModal
        modal={modal}
        invoice={invoice}
        form={form}
        onCancel={onCancel}
      />
    </>
  );
};

export default InvoicesTableActions;
