import { ChatTypes } from "@/root/models/chat";
import { apiBaseurl } from "./baseurl";
import makeRequest from "./makeRequest";

export interface CreateChatPayload {
  chatObjectUUID: string;
  objectUUID: string;
  users: (string | undefined)[];
}

export interface MessageActionPayload {
  action: "create" | "update" | "delete";
  messageUUID: string;
  message: string | null;
}

export const ChatsApi = {
  getChats: async () => {
    const data = await makeRequest<ChatTypes[]>({
      url: apiBaseurl + "/chats",
    });
    return data;
  },

  createChat: async (payload: CreateChatPayload): Promise<ChatTypes> => {
    const data = await makeRequest<ChatTypes>({
      url: apiBaseurl + "/chats",
      method: "post",
      data: payload,
    });
    return data;
  },

  deleteChat: async (chatUUID: string): Promise<void> => {
    await makeRequest({
      url: `${apiBaseurl}/chats/${chatUUID}`,
      method: "delete",
    });
  },

  messageAction: async (chatUUID: string, payload: MessageActionPayload) => {
    const data = await makeRequest({
      url: `${apiBaseurl}/chats/${chatUUID}`,
      method: "patch",
      data: payload,
    });
    return data;
  },
};
