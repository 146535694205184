import { useTranslation } from "react-i18next";

import styles from "./OrderDate.module.scss";

import {
  ActionCreatorTypes,
  useCaseFormContext,
} from "@/components/CaseForm/CaseFormContext";
import DateSelect from "../DateSelect";

const CaseDate = () => {
  const { t } = useTranslation();
  const { state, dispatch } = useCaseFormContext();

  const handleSelect = (value: string) => {
    dispatch({
      type: ActionCreatorTypes.SetDate,
      payload: value,
    });
  };

  return (
    <DateSelect
      className={styles.date}
      disabled
      name="date"
      label={t("Дата")}
      value={state.arrival || new Date()}
      onSelect={handleSelect}
      type="arrival"
    />
  );
};

export default CaseDate;
