import { useEffect, useRef, useState } from "react";
import "tui-image-editor/dist/tui-image-editor.css";
// @ts-ignore
import ImageEditor from "@toast-ui/react-image-editor";
import classNames from "classnames";

import { Button, Space } from "antd";

import styles from "./ImageEditor.module.scss";
import whiteTheme from "./theme";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";

interface EditorProps {
  fileUrl: string;
  loading?: boolean;
  onSave?: (file: string) => void;
  onBack?: () => void;
}

const RotateRight = () => {
  return (
    <svg
      width="18px"
      height="18px"
      viewBox="0 0 22 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0769 19C13.5389 19 14.9634 18.532 16.1462 17.6631C17.329 16.7942 18.2094 15.569 18.6612 14.1631C19.1129 12.7572 19.1129 11.2428 18.6612 9.83688C18.2094 8.43098 17.329 7.20578 16.1462 6.33688C14.9634 5.46798 13.5389 5 12.0769 5C10.6149 5 9.19043 5.46799 8.00764 6.33688C6.82485 7.20578 5.94447 8.43098 5.49268 9.83688C5.0409 11.2428 5.0409 12.7572 5.49269 14.1631M6.5 12.7778L5.53846 14.3333L4 13.1667"
        stroke="#464455"
      />
    </svg>
  );
};

const RotateLeft = () => {
  return (
    <svg
      width="18px"
      height="18px"
      viewBox="0 0 22 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9231 19C10.4611 19 9.03659 18.532 7.85379 17.6631C6.671 16.7942 5.79063 15.569 5.33884 14.1631C4.88705 12.7572 4.88705 11.2428 5.33884 9.83688C5.79063 8.43098 6.671 7.20578 7.8538 6.33688C9.03659 5.46798 10.4611 5 11.9231 5C13.3851 5 14.8096 5.46799 15.9924 6.33688C17.1752 7.20578 18.0555 8.43098 18.5073 9.83688C18.9591 11.2428 18.9591 12.7572 18.5073 14.1631M17.5 12.7778L18.4615 14.3333L20 13.1667"
        stroke="#464455"
      />
    </svg>
  );
};

const Editor = ({ fileUrl, loading, onSave, onBack }: EditorProps) => {
  const ref = useRef();
  const [currentMenu, setCurrentMenu] = useState("draw");
  const [brightness, setBrightness] = useState(0);
  const [contrast, setContrast] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      // @ts-ignore
      const editorInstance = ref.current?.getInstance();
      editorInstance.applyFilter("Brightness", { brightness });
    }, 1000);
  }, [brightness]);

  useEffect(() => {
    setTimeout(() => {
      // @ts-ignore
      const editorInstance = ref.current?.getInstance();
      editorInstance.applyFilter("Contrast", { contrast });
    }, 1000);
  }, [contrast]);

  const handleSave = () => {
    if (!ref.current) {
      return;
    }

    // @ts-ignore
    const editorInstance = ref.current.getInstance();

    // editorInstance && onSave(editorInstance.toDataURL());
  };

  const handleContainerClick = () => {
    // @ts-ignore
    const editorInstance = ref.current?.getInstance();
    const menu = editorInstance?.ui.submenu;
    if (currentMenu !== menu) {
      setCurrentMenu(menu);
    }
  };

  const rotate = (deg: number) => {
    if (!ref.current) {
      return;
    }
    // @ts-ignore
    const editorInstance = ref.current.getInstance();
    if (deg === 0) {
      editorInstance.setAngle(deg);
    }
    editorInstance.rotate(deg);
  };

  return (
    <div className={styles.container} onClick={handleContainerClick}>
      <div className={styles.editor}>
        <Space className={styles.buttons}>
          <Button className={styles.save} onClick={onBack}>
            Назад
          </Button>
          <Button
            className={styles.save}
            loading={loading}
            onClick={handleSave}
          >
            Зберегти
          </Button>
        </Space>
        {currentMenu === "filter" && (
          <Space className={classNames(styles.bottomMenu, styles.filterMenu)}>
            <Space>
              Яскравість: {brightness}{" "}
              <Button
                icon={<MinusOutlined />}
                onClick={() =>
                  setBrightness((p) => Number((p - 0.1).toFixed(1)))
                }
              />
              <Button
                icon={<PlusOutlined />}
                onClick={() =>
                  setBrightness((p) => Number((p + 0.1).toFixed(1)))
                }
              />
            </Space>
            <Space>
              Контрастність: {contrast}{" "}
              <Button
                icon={<MinusOutlined />}
                onClick={() => setContrast((p) => Number((p - 0.1).toFixed(1)))}
              />
              <Button
                icon={<PlusOutlined />}
                onClick={() => setContrast((p) => Number((p + 0.1).toFixed(1)))}
              />
            </Space>
          </Space>
        )}
        {currentMenu === "rotate" && (
          <div className={classNames(styles.bottomMenu, styles.rotateButtons)}>
            <Space className={styles.rotateButtons}>
              <Button icon={<RotateRight />} onClick={() => rotate(-90)}>
                -90
              </Button>
              <Button icon={<RotateRight />} onClick={() => rotate(-5)}>
                -5
              </Button>
              <Button icon={<RotateRight />} onClick={() => rotate(-1)}>
                -1
              </Button>
              <Button onClick={() => rotate(0)}>0</Button>
              <Button icon={<RotateLeft />} onClick={() => rotate(1)}>
                1
              </Button>
              <Button icon={<RotateLeft />} onClick={() => rotate(5)}>
                5
              </Button>
              <Button icon={<RotateLeft />} onClick={() => rotate(90)}>
                90
              </Button>
            </Space>
          </div>
        )}
        <ImageEditor
          ref={ref}
          includeUI={{
            loadImage: {
              path: fileUrl && fileUrl,
              name: "SampleImage",
            },
            theme: whiteTheme,
            menu: ["crop", "resize", "shape", "draw", "rotate", "filter"],
            initMenu: "draw",
            uiSize: {
              width: "90vw",
              height: "87vh",
            },
            menuBarPosition: "bottom",
          }}
          selectionStyle={{
            cornerSize: 20,
            rotatingPointOffset: 70,
          }}
          usageStatistics={true}
        />
      </div>
    </div>
  );
};

export default Editor;
