import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import CreateUserModal from "./CreateUserModal";

const CreateUser = () => {
  const [modal, setModal] = useState(false);
  const { t } = useTranslation();

  return (
    <div>
      <Button onClick={() => setModal(true)}>
        {t("Створити співробітника")}
      </Button>
      <CreateUserModal open={modal} onCancel={() => setModal(false)} />
    </div>
  );
};

export default CreateUser;
