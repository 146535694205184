import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Modal, notification, Upload } from "antd";
import { UploadFile, UploadProps } from "antd/es/upload/interface";
import { RcFile } from "antd/lib/upload";

import styles from "./FileUpload.module.scss";

import {
  ActionCreatorTypes,
  useCaseFormContext,
} from "@/components/CaseForm/CaseFormContext";
import { FilesApi } from "@/api/files";

import { AiOutlineDownload } from "react-icons/ai";
import { UploadOutlined } from "@ant-design/icons";
import Editor from "./ImageEditor";

const { confirm } = Modal;

const FileUpload = () => {
  const { t } = useTranslation();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [previewImage, setPreviewImage] = useState("");
  const [imageName, setImageName] = useState("");

  const [isFileEditing, setIsFileEditing] = useState(false);

  const { state, dispatch } = useCaseFormContext();

  useEffect(() => {
    (async () => {
      if (state.attachments.length && state.caseUUID) {
        setFileList(
          await Promise.all(
            state.attachments.map(async (att) => {
              if (!state.caseUUID) {
                return {
                  uid: att.fileUUID,
                  name: att.filename,
                  status: "error",
                  url: "",
                };
              }
              const file = await FilesApi.getCaseFile(
                state.caseUUID,
                att.fileUUID
              );
              const url = URL.createObjectURL(file);
              return {
                uid: att.fileUUID,
                name: att.filename,
                status: "done",
                url,
              };
            })
          )
        );
      }
    })();
  }, []);

  const handleCancel = () => {
    setPreviewImage("");
    setIsFileEditing(false);
  };

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = URL.createObjectURL(file.originFileObj as Blob);
    }

    setPreviewImage(file.url || (file.preview as string));
    setImageName(
      file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
    );
  };

  const handleUploadChange: UploadProps["onChange"] = ({
    fileList: newFileList,
  }) => {
    setFileList(newFileList);
  };

  const isFileNameDuplicate = (file: RcFile) => {
    const isDuplicate = fileList.some(
      (item) => item.status !== "uploading" && item.name === file.name
    );
    if (isDuplicate) {
      notification.error({ message: t("Файл з таким ім'ям вже існує") });
      return true;
    }
    return false;
  };

  const uploadImage: UploadProps["customRequest"] = async ({
    onSuccess,
    onError,
    file,
    onProgress,
  }) => {
    try {
      const rcFile = file as RcFile;

      if (isFileNameDuplicate(rcFile)) {
        setFileList((prev) => prev.filter((f) => f.uid !== rcFile.uid));
        return;
      }
      const res = await FilesApi.createFile(rcFile, (event: any) => {
        onProgress?.({ percent: (event.loaded / event.total) * 100 });
      });
      onSuccess?.("Ok");
      dispatch({
        type: ActionCreatorTypes.AddAttachment,
        payload: { fileUUID: res.fileUUID, filename: rcFile.name },
      });
    } catch (error) {
      if (error instanceof Error) {
        onError?.(error);
      } else {
        onError?.(new Error(t("Невідома помилка") as string));
      }
    }
  };

  const handleDownload = (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.url = URL.createObjectURL(file.originFileObj as Blob);
    }
    if (file.url) {
      const downloadLink = document.createElement("a");
      downloadLink.href = file.url;
      downloadLink.download = file.name;
      downloadLink.click();
    }
  };

  const handleDelete = async (file: UploadFile) => {
    const isConfirmed = await new Promise((res) => {
      confirm({
        title: t("Ви дійсно бажаєте видалити файл?"),
        okText: t("Так"),
        okType: "danger",
        cancelText: t("Ні"),
        onOk: () => {
          res(true);
        },
        onCancel: () => {
          res(false);
        },
      });
    });

    if (isConfirmed) {
      dispatch({
        type: ActionCreatorTypes.DeleteAttachment,
        payload: file.name,
      });
    } else {
      return false;
    }
  };

  const uploadInputRef = useRef(null);

  return (
    <>
      <div>
        <Upload
          ref={uploadInputRef}
          disabled={state.onlyDetailsEdit}
          className={styles.uploadFiles}
          listType="picture"
          multiple
          fileList={fileList}
          customRequest={uploadImage}
          onPreview={handlePreview}
          onChange={handleUploadChange}
          onRemove={handleDelete}
          onDownload={handleDownload}
          showUploadList={{
            showDownloadIcon: true,
            downloadIcon: (
              <AiOutlineDownload
                style={{
                  fontSize: 16,
                  color: "gray",
                }}
              />
            ),
          }}
        >
          <Button icon={<UploadOutlined />}> {t("Завантажити файли")}</Button>
        </Upload>
      </div>
      <Modal
        width={"calc(100vw - 100px)"}
        style={{
          top: 10,
          left: 30,
          marginRight: 40,
          height: "calc(100% - 20px)",
          maxHeight: "100vh",
          overflow: "hidden",
        }}
        bodyStyle={{
          height: "calc(100vh - 60px)",
        }}
        maskClosable={false}
        open={Boolean(previewImage)}
        footer={null}
        onCancel={handleCancel}
        destroyOnClose={true}
      >
        <>
          {isFileEditing ? (
            <Editor
              fileUrl={previewImage}
              onBack={() => setIsFileEditing(false)}
            />
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                flexDirection: "column",
              }}
            >
              <Button
                style={{ marginBottom: 10 }}
                onClick={() => setIsFileEditing(true)}
              >
                {t("Редагувати")}
              </Button>
              <img
                alt="example"
                style={{ objectFit: "contain", width: "100%", height: "100%" }}
                src={previewImage}
              />
              <div style={{ marginTop: 10, fontSize: 18, fontWeight: "500" }}>
                {imageName}
              </div>
            </div>
          )}
        </>
      </Modal>
    </>
  );
};

export default FileUpload;
