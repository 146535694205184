import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import { TasksQueryProps } from "@/modules/Tasks";
import CreateTaskModal from "../CreateTaskModal";

interface CreateTaskProps {
  tasksQueryProps: TasksQueryProps;
}

const CreateTask = ({ tasksQueryProps }: CreateTaskProps) => {
  const [modal, setModal] = useState(false);
  const { t } = useTranslation();

  return (
    <div>
      <Button onClick={() => setModal(true)}>{t("Створити завдання")}</Button>
      <CreateTaskModal
        open={modal}
        onCancel={() => setModal(false)}
        tasksQueryProps={tasksQueryProps}
      />
    </div>
  );
};

export default CreateTask;
