import { Route, Routes } from "react-router-dom";
import MakePayment from "./pages/MakePayment";
import EditPayment from "./pages/EditPayment";
import Payments from "./pages/Payments";

const PaymentsPanel = () => {
  return (
    <Routes>
      <Route path="create" element={<MakePayment />} />
      <Route path="edit" element={<EditPayment />} />
      <Route path="*" element={<Payments />} />
    </Routes>
  );
};

export default PaymentsPanel;
