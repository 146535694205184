import { useTranslation } from "react-i18next";
import { Form, Select } from "antd";

import styles from "./SelectTeethFormulaNumbering.module.scss";

import {
  ActionCreatorTypes,
  useCaseFormContext,
} from "@/components/CaseForm/CaseFormContext";
import useTeethFormulaNumberings from "@/hooks/useTeethFormulaNumberings";

const { Option } = Select;

const SelectTeethFormulaNumbering = () => {
  const { t } = useTranslation();
  const { teethFormulaNumberings, getNumberingsLoading } =
    useTeethFormulaNumberings();
  const { state, dispatch } = useCaseFormContext();

  const handleSelect = (value: string) => {
    dispatch({
      type: ActionCreatorTypes.SetTeethFormulaNumberingUUID,
      payload: value,
    });
  };

  return (
    <div className={styles.container}>
      <Form.Item
        className={styles.formItem}
        labelCol={{ span: 10 }}
        label={t("Нумерація")}
        rules={[{ required: true, message: t("Заповніть поле") as string }]}
      >
        <Select
          listHeight={1000}
          showSearch
          loading={getNumberingsLoading}
          value={state.teethFormulaNumberingUUID}
          optionFilterProp="children"
          onSelect={handleSelect}
        >
          {teethFormulaNumberings.map((type) => (
            <Option
              key={type.teethFormulaNumberingUUID}
              value={type.teethFormulaNumberingUUID}
            >
              {type.name || " "}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </div>
  );
};

export default SelectTeethFormulaNumbering;
