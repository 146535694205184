import activeProductTeethSlice from "./reducers/activeProductTeethSlice";
import teethFormulaSelectSlice from "./reducers/teethFormulaSelectSlice";
import productLockSlice from "./reducers/productLockSlice";
import dataFilterSlice from "./reducers/dataFilterSlice";
import chosenItemsForParamsSlice from "./reducers/chosenItemsForParamsSlice";
import chatSlice from "./reducers/chatSlice";
import { configureStore } from "@reduxjs/toolkit";

import userReducer from "./reducers/user/userSlice";

export const store = configureStore({
  reducer: {
    userReducer,
    teethFormulaSelectSlice,
    activeProductTeethSlice,
    productLockSlice,
    dataFilterSlice,
    chosenItemsForParamsSlice,
    chatSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
