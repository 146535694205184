import { CaseFormState } from "@/components/CaseForm/CaseFormContext";
import useFilters from "@/hooks/useFilters";
import ParametersGroupItem from "@/components/CaseForm/components/Parameters/ParametersGroup";

interface ParametersProps {
  activeJobID: string;
  product: CaseFormState.Job.Product;
  activeTooth: string | null;
}

const Parameters = ({ activeJobID, product, activeTooth }: ParametersProps) => {
  const { filters } = useFilters();

  return (
    <div>
      {product.product?.productsParametersGroups?.map((group) => (
        <div key={group.productsParametersGroupUUID}>
          <ParametersGroupItem
            activeTooth={activeTooth}
            activeJobID={activeJobID}
            activeProductID={product.jobProductUUID}
            group={group}
            allFilters={filters}
          />
        </div>
      ))}
    </div>
  );
};

export default Parameters;
