import { ReactNode, useEffect, useState } from "react";
import dayjs from "dayjs";
import "dayjs/locale/uk";

import { ConfigProvider } from "antd";
import enUS from "antd/locale/en_US";
import ua from "antd/locale/uk_UA";

interface LanguageProviderProps {
  children?: ReactNode;
}

const LanguageProvider = ({ children }: LanguageProviderProps) => {
  const language = process.env.REACT_APP_LANG;
  const [locale, setLocale] = useState(enUS);

  useEffect(() => {
    if (language) {
      const loc = language === "en" ? enUS : ua;
      setLocale(loc);
      dayjs.locale(language);
    }
  }, [language]);

  return <ConfigProvider locale={locale}>{children}</ConfigProvider>;
};

export default LanguageProvider;
