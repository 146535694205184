import { Button, Space, notification } from "antd";
import { t } from "i18next";
import { useForm } from "antd/es/form/Form";
import { useEffect } from "react";
import useInvoices from "@/hooks/useInvoices";
import { InvoiceCreatePayloadProps } from "@/api/invoices";
import InvoiceForm from "../components/InvoiceForm";
import { Block } from "@/components/common";
import styles from "@/components/LaboratoryCaseForm/LaboratoryCaseForm.module.scss";
import { useNavigate } from "react-router-dom";

const MakeInvoice = () => {
  const [form] = useForm();
  const navigate = useNavigate();

  const navigateToInvoices = () => {
    // it is safe to use -1 (back), because access to /create page is through other pages
    navigate(-1);
  };

  const onCreateSuccess = () => {
    navigateToInvoices();
    notification.success({
      message: t("Рахунок-фактура був успішно створений"),
    });
  };

  const {
    createInvoice,
    createError,
    createLoading: loading,
  } = useInvoices({ onCreateSuccess });

  //Create error
  useEffect(() => {
    if (!!createError) {
      notification.error({
        message: createError,
      });
    }
  }, [createError]);

  const onInvoiceCreate = () => {
    form.submit();
  };

  const invoiceCreate = async (values: InvoiceCreatePayloadProps) => {
    createInvoice({ ...values });
  };

  return (
    <>
      <div className={styles.actionButtons}>
        <Button htmlType="button" onClick={() => navigateToInvoices()}>
          {t("Назад")}
        </Button>
        <Space>
          <Button
            htmlType="button"
            disabled={loading}
            onClick={() => navigateToInvoices()}
          >
            {t("Скасувати")}
          </Button>
          <Button
            onClick={() => {
              onInvoiceCreate();
            }}
            loading={loading}
          >
            {t("Зберегти")}
          </Button>
        </Space>
      </div>
      <Block style={{ height: "calc(100vh - 62px)" }}>
        <InvoiceForm
          handleInvoiceCreate={invoiceCreate}
          form={form}
          type="create"
        />
      </Block>
    </>
  );
};

export default MakeInvoice;
