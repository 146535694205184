import AdminClients from "@/components/AdminClients";
import { DATE_FORMAT } from "@/root/consts";
import { Form, DatePicker, Button } from "antd";
import { t } from "i18next";
import { useState } from "react";
import dayjs from "dayjs";
import { PaymentSearchParamsGroup } from "../pages/Payments";
import { useIsLab } from "@/root/models/isLab";

interface SearchPaymentsProps {
  onSearch: (values: PaymentSearchParamsGroup) => void;
}

interface PaymentSearchParams {
  adminClients: string[] | undefined;
  date: [string, string];
}

const SearchPayment = ({ onSearch }: SearchPaymentsProps) => {
  const { RangePicker } = DatePicker;

  const { isLab } = useIsLab();

  const [chosenAdminClients, setChosenAdminClients] = useState<string[]>([]);

  const handleSubmit = (values: PaymentSearchParams) => {
    onSearch({
      adminClients: isLab ? values.adminClients?.join(",") : undefined,
      date: values.date
        ?.map((d) => dayjs(new Date(d)).format(DATE_FORMAT))
        .join(","),
    });
  };

  return (
    <Form requiredMark={false} onFinish={handleSubmit}>
      <Form.Item name="date" label={t("Дата_one")}>
        <RangePicker format={DATE_FORMAT} />
      </Form.Item>

      {isLab && (
        <AdminClients
          chosenAdminClients={chosenAdminClients}
          setChosenAdminClients={setChosenAdminClients}
        />
      )}
      <div style={{ margin: "10px 0" }}>
        <Button htmlType="submit">{t("Шукати")}</Button>
      </div>
    </Form>
  );
};

export default SearchPayment;
