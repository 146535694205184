import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { Alert, Button, Modal, Space, Table, TreeSelect } from "antd";
import { ColumnsType } from "antd/es/table";
import { DeleteOutlined } from "@ant-design/icons";

import styles from "../../LaboratoryCaseForm.module.scss";

import {
  ActionCreatorTypes,
  CaseFormState,
  useCaseFormContext,
} from "@/components/CaseForm/CaseFormContext";
import useExtras from "@/hooks/useExtras";
import Sum from "../Sum";
import Prices from "../Prices";
import { Extra } from "@/root/models/extra";
import { CurrentPricelistApi } from "@/api/client/currentPricelist";
import { PricelistTypeEnum } from "@/components/PricelistForm/ClientPricelistForm";
import { ClientPricelist } from "@/root/models/pricelist";

const { confirm } = Modal;

const Extras = () => {
  const { t } = useTranslation();
  const { state, dispatch } = useCaseFormContext();
  const { extras: allExtras } = useExtras();

  const [currentPricelist, setCurrentPrice] = useState<ClientPricelist>();

  useEffect(() => {
    (async () => {
      if (!state.client) {
        return;
      }
      try {
        const pricelist = await CurrentPricelistApi.getPricelist({
          clientUUID: state.client.clientUUID,
          pricelistTypeUUID: PricelistTypeEnum.Extras,
        });
        if ("clientsPricelistUUID" in pricelist) {
          setCurrentPrice(pricelist);
        }
      } catch (e) {}
    })();
  }, [state.client]);

  const parseTree = (extras: Extra[]): any => {
    return extras.map((extra) => ({
      key: extra.extraUUID,
      title: (
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <span>{extra.name}</span>
          <span>
            {currentPricelist?.list
              .find(
                (e) =>
                  "extraUUID" in e.item && e.item.extraUUID === extra.extraUUID
              )
              ?.price.toString()
              .replace(".", ",")}
          </span>
        </div>
      ),
      value: extra.extraUUID,
      selectable: !extra.isGroup,
      children: extra.children ? parseTree(extra.children) : undefined,
    }));
  };

  const handleExtraSelect = (jobID: string, extraUUID: string) => {
    const extra = allExtras.find((e) => e.extraUUID === extraUUID);

    if (!extra) {
      return;
    }

    const price = currentPricelist?.list.find(
      (e) => "extraUUID" in e.item && e.item.extraUUID === extra.extraUUID
    )?.price;

    dispatch({
      type: ActionCreatorTypes.AddJobExtra,
      payload: { jobID, extra, price },
    });
  };

  const handleExtraDelete = (jobID: string, extraID: string) => {
    confirm({
      title: t("Підтвердити видалення"),
      okText: t("Видалити"),
      okType: "danger",
      cancelText: t("Скасувати"),
      onOk: () => {
        dispatch({
          type: ActionCreatorTypes.DeleteJobExtra,
          payload: { jobID, extraID },
        });
      },
    });
  };

  const handlePriceChange = (
    jobID: string,
    extraID: string,
    price: number | null
  ) => {
    dispatch({
      type: ActionCreatorTypes.EditExtraPrice,
      payload: { jobID, extraID, price },
    });
  };

  const columns: ColumnsType<CaseFormState.Job> = [
    {
      key: "job",
      dataIndex: "jobName",
      title: t("Робота"),
      render: (jobName: string, job) => (
        <Space direction="vertical">
          <span>{jobName}</span>
          {!!job.error && <Alert message={job.error} type="error" />}
        </Space>
      ),
    },
    {
      key: "extras",
      dataIndex: "extras",
      title: t("Додаткові роботи і послуги"),
      render: (extras: CaseFormState.Job.Extra[] | undefined, job) => (
        <div
          className={classNames(
            styles.cell,
            "laboratory-case-cell--no-pudding"
          )}
        >
          {extras?.map((extra, index) => (
            <div
              className={styles.extraItem}
              key={extra.id}
              style={{
                marginBottom: index + 1 === extras?.length ? 52 : undefined,
              }}
            >
              {extra.extra.name}
              <Button
                icon={<DeleteOutlined />}
                onClick={() => handleExtraDelete(job.jobID, extra.id)}
              />
            </div>
          ))}
          <div className={styles.addCellItem}>
            <TreeSelect
              popupClassName={styles.extrasSelect}
              style={{ width: "100%" }}
              multiple
              treeDefaultExpandAll
              tagRender={() => <span />}
              value={[]}
              onSelect={(extra) => handleExtraSelect(job.jobID, extra)}
              treeData={parseTree(allExtras)}
            />
            <span className={styles.placeholder}>
              {t("Додаткові роботи і послуги")}
            </span>
          </div>
        </div>
      ),
      width: "50%",
    },
    {
      key: "quantity",
      dataIndex: "extras",
      title: t("Кількість"),
      render: (extras: CaseFormState.Job.Extra[] | undefined) => (
        <div>{extras?.length}</div>
      ),
    },
    {
      key: "price",
      dataIndex: "extras",
      title: t("Ціна"),
      render: (extras: CaseFormState.Job.Extra[] | undefined, job) => (
        <Prices
          job={job}
          elements={extras?.map((extra) => ({
            id: extra.id,
            price: extra.price,
            isError: !!extra.error,
          }))}
          onPriceChange={handlePriceChange}
        />
      ),
      className: styles.cellBaseline,
      width: "200px",
    },
  ];

  const totalSum = state.jobs.reduce(
    (sum, job) =>
      sum +
      (job.extras?.reduce((sum, extra) => (extra.price ?? 0) + sum, 0) || 0),
    0
  );

  return (
    <div>
      <Table
        bordered
        pagination={false}
        columns={columns}
        dataSource={state.jobs.map((job) => ({ ...job, key: job.jobID }))}
      />
      <Sum price={totalSum} />
    </div>
  );
};

export default Extras;
