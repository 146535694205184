import { Fragment } from "react";
import { useTranslation } from "react-i18next";

import styles from "../Results.module.scss";

import { CaseFormState } from "@/components/CaseForm/CaseFormContext";
import useProducts from "@/hooks/useProducts";
import GroupItem from "@/components/CaseForm/components/Results/GroupItem";

interface ProductItemProps {
  displayParameters: boolean;
  product: CaseFormState.Job.Product;
}

const ProductItem = ({ displayParameters, product }: ProductItemProps) => {
  const { t } = useTranslation();
  const { findProductByID } = useProducts();

  if (!product.product) {
    return null;
  }

  const fullProduct = findProductByID(product.product.productUUID);
  return (
    <Fragment key={product.jobProductUUID}>
      <div className={styles.row}>
        {t("Виріб")}: {product.product?.name}
      </div>
      <div style={{ display: "block" }}>
        {!product.differentTeethParameters ? (
          <div>
            <div>
              {t("Зуби")}:{" "}
              {product.teeth?.map((tooth) => tooth.slice(4)).join(", ")}
            </div>
            {displayParameters && (
              <>
                <div>{t("Параметри")}:</div>
                <div>
                  {product.productsParametersGroups?.map((group) => (
                    <GroupItem
                      key={group.productsParametersGroupUUID}
                      group={group}
                      product={product}
                      fullProduct={fullProduct}
                      tooth={""}
                    />
                  ))}
                </div>
              </>
            )}
          </div>
        ) : displayParameters ? (
          product.teeth?.map((tooth) => (
            <div key={tooth}>
              <div>
                {t("Зуб")}: {tooth.slice(4)}
              </div>

              <div>{t("Параметри")}:</div>
              <div>
                {product.productsParametersGroups?.map((group) => (
                  <GroupItem
                    key={group.productsParametersGroupUUID}
                    group={group}
                    product={product}
                    fullProduct={fullProduct}
                    tooth={tooth}
                  />
                ))}
              </div>
            </div>
          ))
        ) : (
          <div>
            {t("Зуби")}:{" "}
            {product.teeth?.map((tooth) => tooth.slice(4)).join(", ")}
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default ProductItem;
