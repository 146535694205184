import { Alert, Space, Table } from "antd";
import { t } from "i18next";
import useModalOpen from "@/hooks/useModalOpen";
import { useIsLab } from "@/root/models/isLab";
import { ColumnsType } from "antd/es/table";
import useUnitsOfMeasure from "@/hooks/useUnitsOfMeasure";
import { UnitsOfMeasure } from "@/root/models/unitsOfMeasure";
import CreateUnitOfMeasure from "./CreateUnitOfMeasure";
import UnitsOfMeasureTableActions from "./UnitsOfMeasureTableActions";

const UnitsOfMeasureTab = () => {
  const { unitsOfMeasure, getUnitsOfMeasureLoading, getUnitsOfMeasureError } =
    useUnitsOfMeasure();

  const { modal, onHandleCancel, onEditSuccess, isModalOpen } = useModalOpen();

  const { isAdmin } = useIsLab();

  const unitsOfMeasureColumns: ColumnsType<UnitsOfMeasure> = [
    {
      title: t("Назва"),
      dataIndex: "name",
      key: "name",
    },
  ];

  if (isAdmin) {
    unitsOfMeasureColumns.splice(1, 0, {
      title: "",
      key: "action",
      render: (unitOfMeasure: UnitsOfMeasure) => (
        <UnitsOfMeasureTableActions
          onEditSuccess={onEditSuccess}
          modal={modal}
          onCancel={onHandleCancel}
          onModalOpen={() => {
            isModalOpen(unitOfMeasure.unitOfMeasureUUID);
          }}
          unitOfMeasure={unitOfMeasure}
        />
      ),
    });
  }

  return (
    <Space direction="vertical">
      {isAdmin && <CreateUnitOfMeasure />}
      {getUnitsOfMeasureError ? (
        <Alert type="error" message={getUnitsOfMeasureError as string} />
      ) : (
        <Table
          style={{ userSelect: "none" }}
          columns={unitsOfMeasureColumns}
          dataSource={unitsOfMeasure.map((u) => ({
            ...u,
            key: u.unitOfMeasureUUID,
          }))}
          pagination={false}
          loading={getUnitsOfMeasureLoading}
          onRow={(unitOfMeasure) => ({
            onDoubleClick: () => isModalOpen(unitOfMeasure.unitOfMeasureUUID),
          })}
        />
      )}
    </Space>
  );
};

export default UnitsOfMeasureTab;
