import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Space, Table } from "antd";
import usePricelists from "@/hooks/usePricelists";
import { Pricelist } from "@/root/models/pricelist";
import { useAppSelector } from "@/hooks/redux";
import { UserTypeEnum } from "@/root/types";
import useModalOpen from "@/hooks/useModalOpen";
import { GrDocument, GrDocumentVerified } from "react-icons/gr";
import PricelistsTableActions from "./components/PricelistsTableActions";
import CreatePricelist from "./components/CreatePricelist";
import ActivePricelist from "./components/ActivePricelist";
import PrintPricelist from "../PrintPricelist";

const Pricelists = () => {
  const { t } = useTranslation();
  const {
    pricelists,
    getPricelistsLoading,
    createPricelist,
    deletePricelist,
    editPricelist,
  } = usePricelists();
  const [activePricelist, setActivePricelist] = useState<Pricelist>();
  const userType = useAppSelector((state) => state.userReducer.user?.userType);

  const { modal, onHandleCancel, onEditSuccess, isModalOpen } = useModalOpen();

  const pricelistsColumn = [
    {
      width: 60,
      dataIndex: "applied",
      key: "applied",
      render: (applied: Pricelist["applied"]) => (
        <div style={{ paddingLeft: 10, paddingTop: 5 }}>
          {applied ? (
            <GrDocumentVerified size={18} />
          ) : (
            <GrDocument size={18} />
          )}
        </div>
      ),
    },
    {
      title: t("Номер"),
      dataIndex: "number",
      key: "number",
    },
    {
      title: t("Дата"),
      dataIndex: "date",
      key: "date",
    },
    {
      title: t("Дата активації"),
      dataIndex: "activationDate",
      key: "activationDate",
    },
    {
      title: t("Тип"),
      dataIndex: "pricelistType",
      key: "pricelistType",
      render: (type: Pricelist.Type) => <span>{type.name}</span>,
    },
    {
      title: t("Опис"),
      dataIndex: "description",
      key: "description",
    },
  ];

  userType?.userTypeUUID === UserTypeEnum["Lab's admin"] &&
    pricelistsColumn.push({
      title: "",
      key: "action", // @ts-ignore
      render: (_: any, pricelist: Pricelist) => (
        <PricelistsTableActions
          pricelist={pricelist}
          onDelete={deletePricelist}
          onEdit={editPricelist}
          onEditSuccess={onEditSuccess}
          modal={modal}
          onCancel={onHandleCancel}
          onModalOpen={() => {
            isModalOpen(pricelist.pricelistUUID);
          }}
        />
      ),
    });

  return (
    <Space direction="vertical">
      <Space>
        {userType?.userTypeUUID === UserTypeEnum["Lab's admin"] && (
          <CreatePricelist onCreate={createPricelist} />
        )}
        <Button disabled={!activePricelist} onClick={() => window.print()}>
          {t("Друк")}
        </Button>
      </Space>
      {activePricelist && <PrintPricelist pricelist={activePricelist} />}
      <div style={{ display: "flex", gap: 10 }}>
        <Table
          style={{ userSelect: "none" }}
          columns={pricelistsColumn}
          dataSource={pricelists.map((p) => ({ ...p, key: p.pricelistUUID }))}
          pagination={false}
          expandedRowKeys={pricelists.map((p) => p.pricelistUUID)}
          expandRowByClick
          loading={getPricelistsLoading}
          onRow={(pricelist) => ({
            onClick: () => setActivePricelist(pricelist),
            onDoubleClick: () => isModalOpen(pricelist.pricelistUUID),
          })}
        />
        {activePricelist && (
          <ActivePricelist pricelist={activePricelist} presentation />
        )}
      </div>
    </Space>
  );
};

export default Pricelists;
