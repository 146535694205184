import TextArea from "antd/es/input/TextArea";

import {
  ActionCreatorTypes,
  useCaseFormContext,
} from "@/components/CaseForm/CaseFormContext";

const Description = () => {
  const { state, dispatch } = useCaseFormContext();

  const handleChange = (value: string) => {
    dispatch({
      type: ActionCreatorTypes.SetDescription,
      payload: value,
    });
  };

  return (
    <TextArea
      disabled={state.onlyDetailsEdit}
      style={{ minHeight: "100%", resize: "none" }}
      value={state.description}
      onChange={(e) => handleChange(e.target.value)}
    />
  );
};

export default Description;
