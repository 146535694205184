import { Skeleton } from "antd";

const TableSkeleton = () => {
  const skeletons = Array.from({ length: 8 }, (_, index) => (
    <Skeleton.Input
      key={index}
      active={true}
      size="default"
      style={{ width: "100%" }}
    />
  ));
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 20,
        marginTop: 20,
      }}
    >
      {skeletons}
    </div>
  );
};

export default TableSkeleton;
