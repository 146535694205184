import { ReactNode, useState } from "react";
import { TOOTH_COLOR } from "@/root/consts";
import { TeethFormulaNumberingEnum } from "@/root/types";
import { useIsLab } from "@/root/models/isLab";

export interface ITooth {
  position: number;
  id: string;
  jobProductUUID?: string;
  jaw: "upper" | "lower";
  connectorNode?: ReactNode;
  chainNode?: {
    default: ReactNode;
    hoverArea: ReactNode;
    opened: ReactNode;
    closed: ReactNode;
  };
  numberNode: {
    FDI: ReactNode;
    Palmer: ReactNode;
    Universal: ReactNode;
  };
  toothNode: ReactNode;
  crownNode: ReactNode;
  currentProductNode: ReactNode;
  isCurrent: boolean;
  extraProductCircleNode: ReactNode;
  implantCircleNode: ReactNode;
  tapped: boolean;
  arc: boolean;
  isPoint: boolean;
  isCrown: boolean;
  isConnected: boolean;
  isChainOpen: boolean;
  containAnotherProduct: boolean;
  anotherProductColor?: string;
  selected: boolean;
  color: string | null;
}

interface ToothProps {
  tooth: ITooth;
  formulaNumbering: string;
  onClick: (id: ITooth["id"]) => void;
  onChainClick?: (id: ITooth["id"]) => void;
  isProductLock?: boolean;
}

const Tooth = ({
  tooth,
  formulaNumbering,
  onClick,
  onChainClick,
  isProductLock,
}: ToothProps) => {
  const { isLab } = useIsLab();

  const [isActive, setIsActive] = useState(false);

  const numberNode =
    formulaNumbering === TeethFormulaNumberingEnum.FDI
      ? tooth.numberNode.FDI
      : formulaNumbering === TeethFormulaNumberingEnum.Palmer
      ? tooth.numberNode.Palmer
      : tooth.numberNode.Universal;

  return (
    <>
      {isLab ? null : (
        <g
          fill={"#000000"}
          onClick={() => {
            if (!isProductLock) {
              onChainClick?.(tooth.id);
            }
          }}
          onMouseEnter={() => setIsActive(true)}
          onMouseLeave={() => setIsActive(false)}
        >
          {tooth.chainNode?.hoverArea}
          {isActive && !tooth.isChainOpen
            ? tooth.chainNode?.opened
            : isActive && tooth.isChainOpen
            ? tooth.chainNode?.closed
            : tooth.chainNode?.default}
        </g>
      )}

      <g
        onClick={() => {
          if (!isProductLock) {
            onClick(tooth.id);
          }
        }}
      >
        {numberNode}
        {tooth.selected && <g fill={TOOTH_COLOR}>{tooth.toothNode}</g>}
        <g
          fill={
            tooth.tapped
              ? tooth.color?.trim()
                ? tooth.color
                : "#D8D8D8"
              : TOOTH_COLOR
          }
          style={{
            opacity: tooth.selected ? 0.35 : undefined,
          }}
        >
          {tooth.toothNode}
        </g>
        {tooth.containAnotherProduct && (
          <g fill={tooth.anotherProductColor || undefined}>
            {tooth.extraProductCircleNode}
          </g>
        )}

        {tooth.tapped && tooth.isPoint && tooth.implantCircleNode}
        {tooth.isCrown && <>{tooth.tapped && tooth.crownNode}</>}
        {tooth.isCurrent && !tooth.isCrown && <>{tooth.currentProductNode}</>}
        {tooth.arc && tooth.crownNode}

        {tooth.isConnected && (
          <>
            {tooth.selected && <g fill={"white"}>{tooth.connectorNode}</g>}
            <g
              fill={
                tooth.tapped
                  ? tooth.color?.trim()
                    ? tooth.color
                    : "#D8D8D8"
                  : "white"
              }
              style={{
                opacity: tooth.selected ? 0.35 : undefined,
              }}
            >
              {tooth.connectorNode}
            </g>
          </>
        )}
      </g>
    </>
  );
};

export default Tooth;
