import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Button,
  Checkbox,
  Dropdown,
  InputNumber,
  Modal,
  Space,
  TreeSelect,
} from "antd";

import { Product } from "@/root/models/product";
import useProducts from "@/hooks/useProducts";
import {
  ActionCreatorTypes,
  CaseFormState,
  useCaseFormContext,
} from "@/components/CaseForm/CaseFormContext";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import CatalogSearch from "../CatalogSearch";
import Price from "@/components/LaboratoryCaseForm/components/Price";

interface ChooseProductProps {
  activeJob?: CaseFormState.Job;
  activeProduct?: CaseFormState.Job.Product;
  isLast?: boolean;
}

const { confirm } = Modal;

const isProduct = (product: Product | null): product is Product => !!product;

export const filterProductsByName = (
  product: Product[],
  name: string
): Product[] => {
  return product
    .map<Product | null>((product) => {
      const productCopy = { ...product };
      if (productCopy.name.toLowerCase().includes(name.toLowerCase())) {
        return productCopy;
      }

      if (
        productCopy.children &&
        filterProductsByName(productCopy.children, name).length
      ) {
        productCopy.children = filterProductsByName(productCopy.children, name);
        return productCopy;
      }

      return null;
    })
    .filter(isProduct);
};

const ChooseProduct = ({
  activeJob,
  activeProduct,
  isLast,
}: ChooseProductProps) => {
  const { t } = useTranslation();
  const { products, findProductByID, getParameterGroup, getProductsLoading } =
    useProducts();
  const { dispatch } = useCaseFormContext();
  const [addNewProductPopup, setAddNewProductPopup] = useState(false);
  const [productsSearchValue, setProductsSearchValue] = useState("");

  const activeJobID = activeJob?.jobID;

  const filteredProducts = useMemo(() => {
    let filteredProducts = products;

    if (productsSearchValue) {
      filteredProducts = filterProductsByName(
        filteredProducts,
        productsSearchValue
      );
    }

    return filteredProducts;
  }, [productsSearchValue, products]);

  const parseTree = (products: Product[]): any => {
    return products.map((product) => ({
      key: product.productUUID,
      title: product.name,
      value: product.productUUID,
      selected: activeProduct?.product?.productUUID === product.productUUID,
      children: product.children ? parseTree(product.children) : undefined,
    }));
  };

  const handleDifferentTeethParametersCheck = (checked: boolean) => {
    if (!activeProduct) {
      return;
    }
    dispatch({
      type: ActionCreatorTypes.SetDifferentTeethParametersChecked,
      payload: {
        checked,
        activeJobID,
        activeProductID: activeProduct.jobProductUUID,
      },
    });
  };

  const handleCombinedProductCheck = (checked: boolean) => {
    if (!activeProduct) {
      return;
    }
    dispatch({
      type: ActionCreatorTypes.SetCombinedProductChecked,
      payload: {
        checked,
        activeJobID,
        activeProductID: activeProduct.jobProductUUID,
      },
    });
  };

  const getProductData = (productUUID: string) => {
    const product = findProductByID(productUUID);
    if (!product) {
      return;
    }
    const group = getParameterGroup(product);
    const teethColor = group.color;

    return {
      product,
      group,
      teethColor,
    };
  };

  const addProduct = (productUUID: string) => {
    const productData = getProductData(productUUID);
    if (!productData) {
      return;
    }

    const { product, teethColor } = productData;

    dispatch({
      type: ActionCreatorTypes.AddProduct,
      payload: { activeJobID, product, teethColor },
    });
  };

  const handleSelect = (productUUID: string) => {
    const productData = getProductData(productUUID);
    if (!productData) {
      return;
    }

    const { product, teethColor } = productData;

    if (activeProduct) {
      dispatch({
        type: ActionCreatorTypes.SetProduct,
        payload: {
          activeJobID,
          productUUID: activeProduct.jobProductUUID,
          product,
          teethColor,
        },
      });
    } else {
      dispatch({
        type: ActionCreatorTypes.AddProduct,
        payload: { activeJobID, product, teethColor },
      });
    }
  };

  const handleDeleteClick = () => {
    if (!activeProduct) {
      return;
    }

    confirm({
      title: t("Підтвердити видалення"),
      okText: t("Видалити"),
      okType: "danger",
      cancelText: t("Скасувати"),
      onOk: () => {
        dispatch({
          type: ActionCreatorTypes.DeleteProduct,
          payload: { activeJobID, productUUID: activeProduct.jobProductUUID },
        });
      },
    });
  };

  const handlePriceChange = (
    jobID: string,
    productUUID: string,
    price: number | null
  ) => {
    dispatch({
      type: ActionCreatorTypes.SetProductPrice,
      payload: { jobID, productUUID, price },
    });
  };

  const handleQuantityChange = (value: number | null) => {
    if (!activeProduct) {
      return;
    }

    dispatch({
      type: ActionCreatorTypes.SetProductQuantity,
      payload: {
        quantity: value ?? 0,
        activeJobID,
        activeProductUUID: activeProduct.jobProductUUID,
      },
    });
  };

  return (
    <>
      <div style={{ paddingBottom: isLast ? 48 : undefined }}>
        {activeProduct && (
          <>
            <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
              <TreeSelect
                dropdownRender={(menu) => {
                  return (
                    <Space direction="vertical" style={{ width: "100%" }}>
                      <CatalogSearch onSearch={setProductsSearchValue} />
                      {menu}
                    </Space>
                  );
                }}
                style={{ width: "100%" }}
                placeholder={t("Оберіть виріб")}
                value={activeProduct?.product?.productUUID}
                loading={getProductsLoading}
                treeDefaultExpandAll
                treeData={parseTree(filteredProducts)}
                onSelect={handleSelect}
              />
              <Button icon={<DeleteOutlined />} onClick={handleDeleteClick} />
            </div>
          </>
        )}
        <Space direction="vertical" style={{ width: "100%" }}>
          {activeProduct && (
            <>
              <div>
                <Checkbox
                  checked={activeProduct.differentTeethParameters}
                  onChange={(e) =>
                    handleDifferentTeethParametersCheck(e.target.checked)
                  }
                >
                  {t("Різні параметри зубів")}
                </Checkbox>
              </div>
              <div>
                <Checkbox
                  checked={activeProduct.combinedProduct}
                  onChange={(e) => handleCombinedProductCheck(e.target.checked)}
                >
                  {t("Комбінований виріб")}
                </Checkbox>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Space>
                  {t("Кількість") + ":"}
                  <InputNumber
                    value={activeProduct.quantity}
                    onChange={handleQuantityChange}
                  />
                </Space>
                <Space>
                  {t("Ціна") + ":"}
                  <Price
                    key={activeProduct.jobProductUUID}
                    job={activeJob}
                    product={activeProduct}
                    onPriceChange={(productUUID, price) =>
                      handlePriceChange(activeJob!.jobID, productUUID, price)
                    }
                  />
                </Space>
              </div>
            </>
          )}
          {isLast && (
            <Dropdown
              onOpenChange={setAddNewProductPopup}
              trigger={["click"]}
              dropdownRender={() => (
                <div style={{ marginTop: "-40px" }}>
                  <TreeSelect
                    open={addNewProductPopup}
                    popupMatchSelectWidth={400}
                    style={{ visibility: "collapse" }}
                    placeholder={t("Додати виріб")}
                    loading={getProductsLoading}
                    treeDefaultExpandAll
                    treeData={parseTree(products)}
                    onSelect={addProduct}
                  />
                </div>
              )}
            >
              <Button icon={<PlusOutlined />} onClick={() => {}} />
            </Dropdown>
          )}
        </Space>
      </div>
    </>
  );
};

export default ChooseProduct;
