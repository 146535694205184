import { useTranslation } from "react-i18next";
import { Form, Select } from "antd";

import styles from "./SelectTeethFormulaType.module.scss";

import {
  ActionCreatorTypes,
  useCaseFormContext,
} from "@/components/CaseForm/CaseFormContext";
import useTeethFormulaTypes from "@/hooks/useTeethFormulaTypes";
import classNames from "classnames";

const { Option } = Select;

const SelectTeethFormulaType = () => {
  const { t } = useTranslation();
  const { teethFormulaTypes, getTypesLoading } = useTeethFormulaTypes();
  const { state, dispatch } = useCaseFormContext();

  const handleSelect = (value: string) => {
    dispatch({
      type: ActionCreatorTypes.SetTeethFormulaTypeUUID,
      payload: value,
    });
  };

  return (
    <div className={classNames(styles.container, styles.type)}>
      <Form.Item
        className={styles.formItem}
        labelCol={{ span: 10 }}
        label={t("Тип формули")}
        rules={[{ required: true, message: t("Заповніть поле") as string }]}
      >
        <Select
          disabled={state.teethFormulaInputDisabled}
          listHeight={1000}
          showSearch
          loading={getTypesLoading}
          value={state.teethFormulaTypeUUID}
          optionFilterProp="children"
          onSelect={handleSelect}
        >
          {teethFormulaTypes.map((type) => (
            <Option
              key={type.teethFormulaTypeUUID}
              value={type.teethFormulaTypeUUID}
            >
              {type.name || " "}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </div>
  );
};

export default SelectTeethFormulaType;
