import {
  InvoiceCreatePayloadProps,
  InvoiceEditPayloadProps,
  InvoicesApi,
} from "@/api/invoices";
import { ResponseDataWithPagination } from "@/api/types";
import { Invoice } from "@/root/models/invoice";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

interface UseInvoicesProps {
  pageLimit?: number;
  page?: number;
  date?: string;
  onEditSuccess?: () => void;
  onDeleteError?: (message: string) => void;
  onCreateSuccess?: () => void;
  onDeleteSuccess?: () => void;
  clientUUID?: string;
  paid?: boolean;
}

const useInvoices = ({
  page,
  clientUUID,
  date,
  paid,
  onDeleteError,
  onEditSuccess,
  onCreateSuccess,
  onDeleteSuccess,
}: UseInvoicesProps) => {
  const queryClient = useQueryClient();

  const {
    data: invoices,
    isFetching: getInvoicesLoading,
    error: getInvoicesError,
    refetch,
  } = useQuery({
    queryKey: ["invoices", clientUUID, date, paid],
    queryFn: () => InvoicesApi.getInvoices({ page, clientUUID, date, paid }),
  });

  const deleteInvoiceMutation = useMutation({
    mutationFn: InvoicesApi.delete,
    onError: (error) => onDeleteError?.((error as Error).message),
    onSuccess: (_, invoiceUUID) => {
      queryClient.setQueryData(
        ["invoices", clientUUID, date, paid],
        (
          prev: ResponseDataWithPagination<Invoice[]> | Invoice[] | undefined
        ) => {
          if (!prev) {
            return [];
          }

          if ("data" in prev) {
            return {
              data: (prev?.data || []).filter(
                (p) => p.invoiceUUID !== invoiceUUID
              ),
              page: prev.page,
              pages: prev.pages,
            };
          }

          return (prev || []).filter((p) => p.invoiceUUID !== invoiceUUID);
        }
      );
      onDeleteSuccess?.();
    },
  });

  const deleteInvoice = async (id: string) => {
    await deleteInvoiceMutation.mutateAsync(id);
  };

  const createInvoiceMutation = useMutation({
    mutationFn: InvoicesApi.create,
    onSuccess: (invoice) => {
      queryClient.setQueryData(
        ["invoices", clientUUID, date, paid],
        (
          prev: ResponseDataWithPagination<Invoice[]> | Invoice[] | undefined
        ) => {
          if (!prev) {
            return [invoice];
          }

          if ("data" in prev) {
            return {
              data: [...prev.data, invoice],
              page: prev.page,
              pages: prev.pages,
            };
          }
          return [...prev, invoice];
        }
      );
      onCreateSuccess?.();
    },
  });

  const editInvoiceMutation = useMutation({
    mutationFn: InvoicesApi.edit,
    onSuccess: (invoice: Invoice) => {
      queryClient.setQueryData(
        ["invoices", clientUUID, date, paid],
        (
          prev: ResponseDataWithPagination<Invoice[]> | Invoice[] | undefined
        ) => {
          onEditSuccess?.();

          if (!prev) {
            return [];
          }

          if ("data" in prev) {
            return {
              data: (prev.data || []).map((prevInvoice) => {
                if (prevInvoice.invoiceUUID === invoice.invoiceUUID) {
                  return invoice;
                }
                return prevInvoice;
              }),
              page: prev.page,
              pages: prev.pages,
            };
          }

          return (prev || []).map((prevInvoice) => {
            if (prevInvoice.invoiceUUID === invoice.invoiceUUID) {
              return invoice;
            }
            return prevInvoice;
          });
        }
      );
    },
  });

  const createInvoice = (payload: InvoiceCreatePayloadProps) => {
    createInvoiceMutation.mutate({ payload });
  };

  const editInvoice = (id: string, payload: InvoiceEditPayloadProps) => {
    editInvoiceMutation.mutate({ id, payload });
  };

  const createError = (createInvoiceMutation.error as Error)?.message as string;

  const editError = (editInvoiceMutation.error as Error)?.message as string;

  return {
    invoices: invoices?.data || [],
    invoicePages: invoices?.pages,
    getInvoicesLoading,
    getInvoicesError,
    createLoading: createInvoiceMutation.isLoading,
    editLoading: editInvoiceMutation.isLoading,
    deleteLoading: deleteInvoiceMutation.isLoading,
    deleteInvoice,
    editInvoice,
    createInvoice,
    editError,
    createError,
    refetch,
  };
};

export default useInvoices;
