import { useTranslation } from "react-i18next";
import { QRCode } from "antd";
import { Settings } from "@/root/models/settings";

interface WarrantyProps {
  warrantyNumber: number;
  secret: string;
  patientCode: string;
  settings: Settings | undefined;
}

const Warranty = ({
  warrantyNumber,
  secret,
  patientCode,
  settings,
}: WarrantyProps) => {
  const { t } = useTranslation();

  return (
    <div style={{ lineHeight: 1.5, textAlign: "center" }}>
      <div style={{ marginBottom: 30 }}>{t("Шановний клієнте!")}</div>
      <div>
        {t(
          "Для перевірки гарантійних зобов’язань на отриманий виріб з боку лабораторії відскануйте будь-ласка наступний QR код:"
        )}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: 30,
          marginTop: 15,
        }}
      >
        {/* //TODO: Test different links */}
        {settings?.warantyPaswordUsage ? (
          <QRCode
            value={`${settings?.warrantyPageAddress}/${patientCode}?secret=${secret}`}
          />
        ) : (
          <QRCode
            value={`${settings?.warrantyPageAddress}/${warrantyNumber}`}
          />
        )}
      </div>
      <div>{t("або перейдіть в кабінет пацієнта вручну:")}</div>
      <div>{settings?.warrantyPageAddress}</div>
      {settings?.warantyPaswordUsage ? (
        <div>
          {t("і введіть наступний код пацієнта") + ":"}&nbsp;
          {patientCode}&nbsp;
          <span>
            {t("з секретним ключем") + ":"}&nbsp; {secret}
          </span>
        </div>
      ) : (
        <div>
          {t("і введіть наступний номер гарантії") + ":"}&nbsp;
          {warrantyNumber}
        </div>
      )}
    </div>
  );
};

export default Warranty;
