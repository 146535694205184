import { useTranslation } from "react-i18next";
import { Space, Table } from "antd";
import usePans from "@/hooks/usePans";
import { Pan } from "@/root/models/pan";
import useModalOpen from "@/hooks/useModalOpen";
import PansTableActions from "./components/PansTableActions";
import CreatePan from "./components/CreatePan";

const Pans = () => {
  const { t } = useTranslation();
  const { pans, getPansLoading } = usePans();
  const { modal, onHandleCancel, onEditSuccess, isModalOpen } = useModalOpen();

  const pansColumn = [
    {
      title: t("Назва"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("Номер замовлення"),
      dataIndex: "case",
      key: "caseNumber",
      render: (panCase: Pan.Case) => <span>{panCase.caseNumber}</span>,
    },
    {
      title: "",
      key: "action",
      render: (_: any, pan: Pan) => (
        <PansTableActions
          onEditSuccess={onEditSuccess}
          modal={modal}
          onCancel={onHandleCancel}
          onModalOpen={() => {
            isModalOpen(pan.panUUID);
          }}
          pan={pan}
        />
      ),
    },
  ];

  return (
    <Space direction="vertical">
      <CreatePan />
      <Table
        style={{ userSelect: "none" }}
        columns={pansColumn}
        dataSource={pans.map((p) => ({ ...p, key: p.panUUID }))}
        pagination={false}
        loading={getPansLoading}
        onRow={(pan) => ({
          onDoubleClick: () => isModalOpen(pan.panUUID),
        })}
      />
    </Space>
  );
};

export default Pans;
