import { useTranslation } from "react-i18next";
import { Button, Checkbox, Descriptions, Space, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { EyeOutlined } from "@ant-design/icons";
import useTasks from "@/hooks/useTasks";
import { Loader } from "@/components/common";
import { Task } from "@/root/models/task";
import { useAppSelector } from "@/hooks/redux";
import { TaskStatusEnum } from "@/root/types";
import EditTaskModal from "@/modules/CourierInfo/components/EditTaskModal";

interface TaskProps {
  id: string;
  onCaseWatch: (id: string) => void;
}

const TaskInfo = ({ id, onCaseWatch }: TaskProps) => {
  const { t } = useTranslation();
  const currentCourierUUID = useAppSelector(
    (state) => state.userReducer.user?.userUUID
  );
  const { tasks, getTasksLoading, getTasksError } = useTasks({
    taskNumber: id,
  });

  if (getTasksError) {
    return <span>{getTasksError as string}</span>;
  }

  const columns: ColumnsType<{ case: Task.Case; use: boolean }> = [
    {
      key: "number",
      dataIndex: "case",
      title: t("Номер"),
      render: (c: Task.Case) => (
        <Space>
          {c.caseNumber}
          <Button
            icon={<EyeOutlined />}
            onClick={() => onCaseWatch(c.caseNumber)}
          />
        </Space>
      ),
    },
    {
      key: "status",
      dataIndex: "case",
      title: t("Статус"),
      render: (c: Task.Case) => c.status?.name,
    },
    {
      key: "used",
      dataIndex: "use",
      title: t("Використано"),
      render: (used: boolean) => <Checkbox checked={used} disabled />,
    },
  ];

  const task = tasks[0];
  const canEdit =
    task &&
    currentCourierUUID === task.courier.courierUUID &&
    (task.taskStatus.taskStatusUUID === TaskStatusEnum.New ||
      task.taskStatus.taskStatusUUID === TaskStatusEnum["In progress"]);

  return (
    <div>
      {getTasksLoading ? (
        <div style={{ marginTop: 30 }}>
          <Loader center />
        </div>
      ) : task ? (
        <Space direction="vertical" style={{ width: "100%" }}>
          <Descriptions
            bordered
            column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
          >
            <Descriptions.Item label={t("Номер")}>
              {task.taskNumber}
            </Descriptions.Item>
            <Descriptions.Item label={t("Контрагент")}>
              {task.client.name}
            </Descriptions.Item>
            <Descriptions.Item label={t("Статус завдання")}>
              {task.taskStatus.name}
            </Descriptions.Item>
            <Descriptions.Item label={t("Дата завершення")}>
              {task.dueDate.slice(0, 16)}
            </Descriptions.Item>
            <Descriptions.Item label={t("Дата виконання")}>
              {task.completionDate.slice(0, 16)}
            </Descriptions.Item>
          </Descriptions>
          <Table
            pagination={false}
            dataSource={task.cases.map((c) => ({ ...c, key: c.case.caseUUID }))}
            columns={columns}
          />
          {canEdit && <EditTaskModal task={task} />}
        </Space>
      ) : (
        t("Завдання не знайдено")
      )}
    </div>
  );
};

export default TaskInfo;
