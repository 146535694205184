import { PaymentEditPayloadProps } from "@/api/payments";
import { usePayments } from "@/hooks/usePayments";
import { ModifiedInvoiceWithItems } from "@/root/models/invoice";
import React, { useEffect, useState } from "react";
import { PaymentForm } from "../components/PaymentForm";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "antd/es/form/Form";
import { Payment } from "@/root/models/payment";
import { Block, Print } from "@/components/common";
import { Button, Space, notification } from "antd";
import { t } from "i18next";
import styles from "@/components/LaboratoryCaseForm/LaboratoryCaseForm.module.scss";
import PaymentPrint from "../components/PaymentPrint";
import useInfo from "@/hooks/useInfo";
import { generateFileName } from "@/utils/generateFileName";

const EditPayment = () => {
  const [form] = useForm();
  const location = useLocation();
  const navigate = useNavigate();

  const { info } = useInfo();

  const payment = location.state as Payment;

  const navigateToPayments = () => {
    // it is safe to use -1 (back), because access to /create page is through other pages
    navigate(-1);
  };

  const onEditSuccess = () => {
    navigateToPayments();
    notification.success({
      message: t("Оплата була успішно змінена"),
    });
  };

  const { editPayment, editError, editLoading } = usePayments({
    onEditSuccess,
  });

  const [paymentInvoicesForEdit, setPaymentInvoicesForEdit] = useState<
    ModifiedInvoiceWithItems[]
  >([]);

  const paymentEditSubmit = () => {
    form.submit();
  };

  //Edit error
  useEffect(() => {
    if (!!editError) {
      notification.error({
        message: editError,
      });
    }
  }, [editError]);

  //Fill payment from
  useEffect(() => {
    if (payment) {
      form.setFieldsValue({
        paymentNumber: payment.paymentNumber,
        client: payment.client.clientUUID,
        description: payment.description,
        amount: payment.amount,
      });
    }
  }, [payment]);

  const handleEdit = async (values: PaymentEditPayloadProps) => {
    editPayment(payment.paymentUUID, {
      description: values.description,
      amount: values.amount,
      clientUUID: payment.client.clientUUID,
      items: paymentInvoicesForEdit
        .map((invoice) =>
          invoice.children?.map((elem) =>
            elem.items?.map((item) => ({
              caseUUID: elem.case.caseUUID,
              rowUUID: item.rowUUID,
              sum: item.sum,
              invoiceUUID: invoice.invoiceUUID,
            }))
          )
        )
        .flat(2),
    });
  };
  return (
    <>
      <div className={styles.actionButtons}>
        <Button htmlType="button" onClick={() => navigateToPayments()}>
          {t("Назад")}
        </Button>
        <Space>
          <Button
            htmlType="button"
            onClick={() => {
              generateFileName("Payment", payment.paymentNumber, payment.date);
              window.print();
            }}
          >
            {t("Надрукувати оплату")}
          </Button>

          <Button
            htmlType="button"
            disabled={editLoading}
            onClick={() => navigateToPayments()}
          >
            {t("Скасувати")}
          </Button>
          <Button onClick={() => paymentEditSubmit()} loading={editLoading}>
            {t("Зберегти")}
          </Button>
          <Print>
            <PaymentPrint info={info} payment={payment} />
          </Print>
        </Space>
      </div>
      <Block style={{ height: "calc(100vh - 62px)" }}>
        <PaymentForm
          form={form}
          paymentItems={payment.items}
          handlePaymentEdit={handleEdit}
          type="edit"
          setEditedItems={setPaymentInvoicesForEdit}
          chosenClinic={payment.client.clientUUID}
        />
      </Block>
    </>
  );
};

export default EditPayment;
