import AdminClients from "@/components/AdminClients";
import { DATE_FORMAT } from "@/root/consts";
import { Form, DatePicker, Button } from "antd";
import { t } from "i18next";
import { useState } from "react";
import dayjs from "dayjs";
import { useIsLab } from "@/root/models/isLab";
import { useForm } from "antd/es/form/Form";
import { InvoiceSearchParamsGroup } from "../pages/Invoices";
import PaidSelect from "./PaidSelect";

interface SearchInvoiceProps {
  onSearch: (values: InvoiceSearchParamsGroup) => void;
}

const SearchInvoice = ({ onSearch }: SearchInvoiceProps) => {
  const { RangePicker } = DatePicker;

  const [form] = useForm();

  const [chosenAdminClients, setChosenAdminClients] = useState<string[]>([]);
  const [casePaid, setCasePaid] = useState("No");
  const [date, setDate] = useState<string>();

  const { isLab } = useIsLab();

  const handleSubmit = () => {
    onSearch({
      adminClients: chosenAdminClients?.join(",") || undefined,
      date: date,
      paid: casePaid === "No" ? false : casePaid === "Yes" ? true : undefined,
    });
  };

  return (
    <Form requiredMark={false} onFinish={handleSubmit} form={form}>
      <Form.Item name="date" label={t("Дата_one")}>
        <RangePicker
          format={DATE_FORMAT}
          //@ts-ignore
          onChange={(value: RangeValue<dayjs.Dayjs>) =>
            setDate(
              value
                ?.map((d: string) => dayjs(new Date(d)).format(DATE_FORMAT))
                ?.join(",") || undefined
            )
          }
        />
      </Form.Item>
      <div style={{ display: "flex", gap: "30px" }}>
        {isLab ? (
          <AdminClients
            chosenAdminClients={chosenAdminClients}
            setChosenAdminClients={setChosenAdminClients}
          />
        ) : null}
        <PaidSelect casePaid={casePaid} setCasePaid={setCasePaid} />
      </div>
      <div style={{ margin: "10px 0" }}>
        <Button htmlType="submit">{t("Шукати")}</Button>
      </div>
    </Form>
  );
};

export default SearchInvoice;
