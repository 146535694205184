import { useAppSelector } from "@/hooks/redux";
import { ChatTypes } from "@/root/models/chat";
import {
  ChatContainer,
  ConversationHeader,
  Message,
  MessageInput,
  MessageList,
  MessageSeparator,
} from "@chatscope/chat-ui-kit-react";
import { t } from "i18next";
import { useEffect, useState } from "react";

import styles from "../ChatPanel.module.scss";
import useChatMessagesWithSeparator from "../hooks/useChatMessagesWithSeparator";
import { ChatsApi } from "@/api/chats";

import { v4 as uuidv4 } from "uuid";
import DropdownMessageOptions from "./DropdownMessageOptions";

interface ChatProps {
  selectedChat: ChatTypes | undefined;
  refetch: () => void;
  chats: ChatTypes[];
  refetchChatNotif: () => void;
  chatNotifications: ChatTypes[] | undefined;
  deleteChatNotifications: (
    chatUUID: string,
    messageUUID: string
  ) => Promise<void>;
}

const Chat = ({
  selectedChat,
  refetch,
  chats,
  refetchChatNotif,
  chatNotifications,
  deleteChatNotifications,
}: ChatProps) => {
  const userUUID = useAppSelector((state) => state.userReducer.user?.userUUID);

  const [messageInput, setMessageInput] = useState("");

  const { messagesWithSeparators } = useChatMessagesWithSeparator(
    chats?.find((elem) => elem.chatUUID === selectedChat?.chatUUID)?.messages,
    chatNotifications?.find((elem) => elem.chatUUID === selectedChat?.chatUUID)
      ?.messages
  );

  //Delete chat notifications
  useEffect(() => {
    if (selectedChat) {
      (() => {
        const foundChat = chatNotifications?.find(
          (elem) => elem.chatUUID === selectedChat?.chatUUID
        );

        if (foundChat?.chatUUID && !!foundChat.messages.length) {
          return foundChat?.messages.map((elem) =>
            deleteChatNotifications(foundChat?.chatUUID, elem.messageUUID)
          );
        }

        refetchChatNotif();
      })();
    }
  }, [selectedChat, chatNotifications]);

  //Create message
  const messageCreate = async () => {
    try {
      if (selectedChat) {
        await ChatsApi.messageAction(selectedChat?.chatUUID, {
          action: "create",
          messageUUID: uuidv4(),
          message: messageInput.split("&nbsp;").join(""),
        });
        await refetch();
        await refetchChatNotif();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <ChatContainer>
        <ConversationHeader>
          <ConversationHeader.Back />

          {/* All chat users */}
          <ConversationHeader.Content
            userName={selectedChat?.users.map((user) => user.name).join(", ")}
          />
        </ConversationHeader>

        <MessageList>
          {messagesWithSeparators?.map((elem) => {
            //Showing separator line with date
            if (!!elem.isSeparator) {
              return <MessageSeparator key={elem.date} content={elem.date} />;
            } else if (elem.isUndreadMessagesSeparator) {
              return (
                <div key={elem.key} className={styles.unreadMessages}>
                  {elem.message}
                </div>
              );
            } else {
              // Using this way to correct display sent time instead of new Date ()
              // to awoid display 0's like 00:00 and not 0:0
              const time = elem.dt?.split(" ")[1].split(":");
              const sentHour = time?.[0];
              const sentMinutes = time?.[1];
              const sentTime = `${sentHour}:${sentMinutes}`;

              return (
                <DropdownMessageOptions
                  selectedChat={selectedChat}
                  messageUUID={elem.messageUUID}
                  key={elem.messageUUID}
                  refetch={refetch}
                >
                  <Message
                    model={{
                      sender: elem.user?.name,
                      direction:
                        userUUID === elem.user?.userUUID
                          ? "outgoing"
                          : "incoming",
                      position: "last",
                    }}
                  >
                    <Message.CustomContent>
                      <div className={styles.message_container}>
                        <div style={{ userSelect: "text" }}>
                          {elem.message?.split("<br>").join("\n")}
                        </div>
                        <div className={styles.sentTime}>{sentTime}</div>
                      </div>
                    </Message.CustomContent>
                  </Message>
                </DropdownMessageOptions>
              );
            }
          })}
        </MessageList>

        <MessageInput
          disabled={!selectedChat}
          placeholder={t("Напишіть повідомлення...") as string}
          onChange={(value) => {
            setMessageInput(value);
          }}
          onSend={() => messageCreate()}
        />
      </ChatContainer>
    </>
  );
};

export default Chat;
