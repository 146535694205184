import TaskPhotos, { TaskPhoto } from "@/modules/Tasks/components/TaskPhotos";
import { Block } from "@/components/common";
import { Task } from "@/root/models/task";
import { useForm } from "antd/es/form/Form";
import { CSSProperties, useEffect, useState } from "react";
import TaskForm from "../TaskForm";

interface ChosenTaskProps {
  task: Task;
  style: CSSProperties;
}

const ChosenTask = ({ task, style }: ChosenTaskProps) => {
  const [form] = useForm();
  const [photos, setPhotos] = useState<TaskPhoto[]>([]);

  useEffect(() => {
    setPhotos(task.attachments);
  }, [task]);

  return (
    <div style={style}>
      <Block>
        <TaskForm type="view" cases={task.cases} form={form} task={task} />
        <TaskPhotos
          taskUUID={task.taskUUID}
          photos={photos}
          presentation={true}
        />
      </Block>
    </div>
  );
};

export default ChosenTask;
