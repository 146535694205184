import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import { Pricelist } from "@/root/models/pricelist";
import CreatePricelistModal from "./CreatePricelistModal";

interface CreatePricelistProps {
  onCreate: (pricelist: Pricelist) => void;
}

const CreatePricelist = ({ onCreate }: CreatePricelistProps) => {
  const [modal, setModal] = useState(false);
  const { t } = useTranslation();

  return (
    <div>
      <Button onClick={() => setModal(true)}>{t("Створити прайс лист")}</Button>
      <CreatePricelistModal
        open={modal}
        onCancel={() => setModal(false)}
        onCreate={onCreate}
      />
    </div>
  );
};

export default CreatePricelist;
