import { jaws } from "@/root/consts";

export enum LoadingStatus {
  DEFAULT = "DEFAULT",
  LOADING = "LOADING",
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
}

export enum UserTypeEnum {
  "Client's employee" = "7fd6c354-ff3b-4b04-9256-8dee24d116bd",
  "Client's admin" = "65e019a5-ff5d-4d3b-83da-720dd6f2d4d5",
  "Empty" = "97110491-3cb7-4b27-afc9-194dabac31c5",
  "Employee" = "f6293af1-f990-4484-b088-1fb9e55d3f29",
  "Lab's admin" = "a003b0f5-8cb4-4acb-82a4-c5ef38d6e9d8",
  "Courier" = "e2c198fb-c8a9-4695-96f4-d90c51b6cea4",
}

export enum CaseStatusEnum {
  "New" = "15616f93-5ad0-4b22-a3c1-a716f1b5520a",
  "Declined" = "19456f9c-b5d5-4aac-91d1-4c9d63b7f677",
  "Ready for pickup" = "13c116be-26b1-4f15-aba1-08f7b3f898a5",
  "Assigned to the Driver" = "bac2d95b-5c9c-4f4b-b549-2b6891b05105",
  "Heading to the lab" = "83fbc24d-a7a0-4c05-ae3e-fc0f6aa953d7",
  "At the lab" = "73971225-7585-4575-a12f-4f086641db08",
  "Assigned to Driver for dental clinic" = "8a063b7d-04f8-4899-90a0-a2d37fdea86f",
  "In Process" = "b2ee7744-bf8a-4958-b99a-6a8e17f3d1ef",
  "Done" = "4729ef9b-5820-4b3c-a82c-649e9dd49230",
  "Ready for delivery" = "8636779b-8259-4e5e-a0ad-a795a8e17cc3",
  "Heading to the clinic" = "9a33656d-dff4-4ff5-817f-ab99a15a2d5f",
  "Delivered" = "251fa44d-7561-4d98-b94c-7526857382aa",
}

export enum TaskStatusEnum {
  "New" = "947effc1-ef77-4aa4-88e5-1e44011ab83f",
  "In progress" = "632ebb25-0246-4795-b8b4-ff58bc91c209",
  "Completed" = "762aa2ca-871a-4904-9976-ca434456249c",
}

export enum TeethFormulaTypeEnum {
  "child" = "53553231-e2b5-4d53-a0b4-cc9988082bd2",
  "adult" = "4fcd8b2e-435f-4a8d-ac4a-4f8ff2c25caa",
}

export enum TeethFormulaNumberingEnum {
  "FDI" = "9a4f4fa0-f4bc-4ad2-b175-c7cef2a3a353",
  "Palmer" = "008baeae-883d-4103-a35c-3b55381358ac",
  "Universal" = "e8e54c21-3e9f-4cbf-8e19-78b2b8a59197",
}

export enum CouriersAvailabilityStatusEnum {
  "Available" = "6a822c37-fb57-498d-a369-6811a377984a",
  "Unavailable" = "81b7e740-dd0c-4eed-9d2f-afc42f91de46",
}

export enum Location {
  "Patient" = "patients-portal",
  "Case" = "case-warranty",
}

export enum ReportSettingItemEnum {
  "Client" = "Контрагент",
  "Doctor" = "Лікар",
  "Patient" = "Пацієнт",
  "Cases" = "Замовлення",
  "Product" = "Номенклатура",
  "Status" = "Статус",
}

export enum ChatObjectEnum {
  "Case" = "2aca5c62-ef02-43fa-93b2-6c2fb37c2770",
  "Task" = "a507b445-f48b-4351-bd8e-b868ee7086eb",
}

export enum NotExistEnum {
  "NullUUID" = "00000000-0000-0000-0000-000000000000",
}

export interface Metadata {
  version: string;
}

export type JawType = (typeof jaws)[number];
