import { GroupedInvoiceCase, Invoice } from "@/root/models/invoice";
import {
  Alert,
  Divider,
  Form,
  FormInstance,
  Input,
  Select,
  Tabs,
  TabsProps,
} from "antd";
import { t } from "i18next";
import styles from "../InvoicePanel.module.scss";
import { useEffect, useState } from "react";
import useAdminClients from "@/hooks/useAdminClients";
import { useIsLab } from "@/root/models/isLab";
import {
  InvoiceCreatePayloadProps,
  InvoiceEditPayloadProps,
} from "@/api/invoices";
import dayjs from "dayjs";
import { DATE_FORMAT } from "@/root/consts";
import { PaidCheckbox } from "./PaidCheckbox";

import "animate.css";
import useInvoiсesTable from "../hooks/useInvoiсesTable";
import UnpaidCasesFilterForm from "./UnpaidCasesFilterForm";
import TableSkeleton from "@/components/TableSkeleton";
import CustomCasesTable from "./CustomCasesTable";

interface InvoiceViewFormProps {
  form: FormInstance;
  type: "create" | "view" | "edit";
  paid?: boolean;
  handleInvoiceCreate?: (payload: InvoiceCreatePayloadProps) => void;
  handleEdit?: (values: InvoiceEditPayloadProps) => Promise<void>;
  applied?: boolean;
  invoiceCases?: Invoice.InvoiceCase[];
  invoiceClient?: string;
  setInvoiceCasesForEdit?: (invoiceCases: Invoice.Products[]) => void;
}

const { TextArea } = Input;
const { Option } = Select;

const InvoiceForm = ({
  form,
  type,
  paid = false,
  invoiceCases = [] as Invoice.InvoiceCase[],
  handleInvoiceCreate,
  handleEdit,
  invoiceClient = "",
  setInvoiceCasesForEdit,
}: InvoiceViewFormProps) => {
  const { isLab } = useIsLab();

  const { adminClients, getAdminClientsLoading } = useAdminClients({});
  const [chosenClient, setChosenClient] = useState(invoiceClient);

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>();

  const [tab, setTab] = useState("unpaid");

  const {
    //First table
    invoiceProducts,
    setInvoiceProducts,

    //Second table
    unpaidInvoiceCases,

    loading,
  } = useInvoiсesTable(invoiceCases, chosenClient);

  const [filteredUnpaidCases, setFilteredUnpaidCases] = useState<
    GroupedInvoiceCase[]
  >([]);

  //Additional cases are cases we are adding to selected cases in order to have possibility
  //without problems to add or remove cases if selected table already has added cases
  //e.g. in Invoice Edit
  const [additionalCases, setAdditionalCases] = useState<GroupedInvoiceCase[]>(
    []
  );

  const onFilter = (filteredArray: GroupedInvoiceCase[]) => {
    setFilteredUnpaidCases(filteredArray);
  };

  //Fill client field on Case Edit
  useEffect(() => {
    if (type === "edit" && !chosenClient) {
      setChosenClient(form.getFieldValue("client"));
    }
  }, [type, chosenClient]);

  const onlyChildrenItems = invoiceProducts
    .concat(additionalCases)
    .map((elem) => elem.children)
    .flat();

  //Set added cases to edit form by Invoice edit
  useEffect(() => {
    if (type === "edit" && !!onlyChildrenItems.length) {
      setInvoiceCasesForEdit?.(onlyChildrenItems);
    }
  }, [onlyChildrenItems.length]);

  //Invoice create
  const handleSubmit = (values: any) => {
    handleInvoiceCreate &&
      handleInvoiceCreate({
        clientUUID: chosenClient,
        description: values.description || "",
        amount: values.amount,
        paid: false,
        dueDate: dayjs(new Date()).format(DATE_FORMAT),
        cases: onlyChildrenItems.map((instanse) => ({
          caseUUID: instanse.caseUUID,
          rowUUID: instanse.rowUUID,
          sum: instanse.sum,
          description: "",
        })),
      });
  };

  // Recalculate sum on invoice products remove
  useEffect(() => {
    form.setFieldValue(
      "amount",
      onlyChildrenItems.reduce((prev, cur) => prev + cur?.sum, 0)
    );
  }, [onlyChildrenItems]);

  const invoiceTables: TabsProps["items"] = [
    {
      key: "unpaid",
      label: t("Неоплачені"),
      children: (
        <>
          <div>
            {!chosenClient ? (
              <Alert
                type="info"
                message={t("Оберіть спочатку контрагента")}
                style={{ width: "50%", marginTop: 20 }}
              />
            ) : chosenClient && loading ? (
              <TableSkeleton />
            ) : (
              <CustomCasesTable
                tableInstancesArray={
                  type === "create" ? filteredUnpaidCases : unpaidInvoiceCases
                }
                setAdditionalCases={setAdditionalCases}
                setSelectedRowKeys={setSelectedRowKeys}
                selectedRowKeys={selectedRowKeys}
                type={type}
                mainTable={true}
              />
            )}
          </div>
        </>
      ),
    },
    {
      key: "selected",
      label: t("Обрані"),
      children: (
        <CustomCasesTable
          tableInstancesArray={invoiceProducts.concat(additionalCases)}
          type={type}
          alreadyAddedInstancesArray={invoiceProducts}
          setAlreadyAddedInstances={setInvoiceProducts}
        />
      ),
    },
  ];

  return (
    <>
      <Form
        className={styles.formItem_input}
        requiredMark={false}
        form={form}
        onFinish={type === "edit" ? handleEdit : handleSubmit}
      >
        <div
          style={{
            display: "flex",
            gap: 50,
          }}
        >
          <Form.Item
            labelCol={{ span: 5 }}
            name="invoiceNumber"
            label={t("Номер рахунку-фактури")}
            style={{ flex: 1 }}
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            name="description"
            label={t("Опис")}
            style={{ flex: 1 }}
            labelCol={{ span: 4 }}
          >
            <TextArea disabled={type === "view"} rows={1} />
          </Form.Item>
        </div>
        {isLab ? (
          <div style={{ display: "flex", gap: 50 }}>
            <Form.Item
              name="client"
              label={t("Контрагент")}
              rules={[
                { required: true, message: t("Заповніть поле") as string },
              ]}
              labelCol={{ span: 5 }}
              style={{ flex: 1 }}
            >
              <Select
                listHeight={1000}
                loading={getAdminClientsLoading}
                optionFilterProp="children"
                disabled={(type !== "create" || paid) && true}
                onChange={(value: string) => setChosenClient(value)}
              >
                {adminClients.map((client) => (
                  <Option key={client.clientUUID} value={client.clientUUID}>
                    {client.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="amount"
              label={t("Повна сума")}
              style={{ flex: 1 }}
              labelCol={{ span: 4 }}
            >
              <Input
                type="number"
                disabled={(type === "view" || !!invoiceProducts.length) && true}
              />
            </Form.Item>
          </div>
        ) : null}

        {type !== "create" && (
          <PaidCheckbox
            type={type}
            disable={type === "view" || paid}
            paid={paid}
          />
        )}
        <Divider />
        {type === "create" && (
          <UnpaidCasesFilterForm
            onFilter={onFilter}
            unpaidInvoiceCases={unpaidInvoiceCases}
          />
        )}
        {(type === "create" || type === "edit") && (
          <Tabs
            tabBarStyle={{ marginBottom: 0 }}
            type="card"
            items={invoiceTables}
            activeKey={tab}
            onTabClick={setTab}
          />
        )}

        {type === "view" && (
          <CustomCasesTable
            tableInstancesArray={invoiceProducts.concat(additionalCases)}
            type={type}
          />
        )}
      </Form>
    </>
  );
};

export default InvoiceForm;
