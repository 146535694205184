import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import { Alert, Form, FormInstance, Input, Select } from "antd";

import { CreateUserPayload } from "@/api/admin/users";
import useUserTypes from "@/hooks/useUserTypes";
import useFormAutoFocusInput from "@/hooks/useFormAutoFocusInput";

dayjs.extend(weekday);
dayjs.extend(localeData);

const { Option } = Select;

interface UserFormProps {
  form: FormInstance<CreateUserPayload>;
  handleSubmit: (values: CreateUserPayload) => void;
  error: string;
}

const UserForm = ({ form, handleSubmit, error }: UserFormProps) => {
  const { t } = useTranslation();
  const { userTypes, getUserTypesLoading } = useUserTypes();
  const focusInputRef = useFormAutoFocusInput();

  const filteredUserTypes = userTypes.filter(
    (type) =>
      type.name !== "" &&
      type.name !== "Client's employee" &&
      type.name !== "Client's admin" &&
      type.name !== "Dental clinic's doctor"
  );

  return (
    <Form requiredMark={false} form={form} onFinish={handleSubmit}>
      <Form.Item
        rules={[
          {
            required: true,
            message: t("Заповніть поле") as string,
          },
        ]}
        name="name"
        label={t("Ім'я.name")}
      >
        <Input
          ref={focusInputRef}
          placeholder={t("Введіть значення") as string}
        />
      </Form.Item>

      <Form.Item
        rules={[
          {
            required: true,
            message: t("Заповніть поле") as string,
          },
        ]}
        name="login"
        label={t("Логін")}
      >
        <Input placeholder={t("Введіть значення") as string} />
      </Form.Item>

      <Form.Item
        rules={[
          {
            required: true,
            message: t("Заповніть поле") as string,
          },
        ]}
        name="password"
        label={t("Пароль")}
      >
        <Input.Password placeholder={t("Введіть значення") as string} />
      </Form.Item>

      <Form.Item name="email" label={t("Пошта")}>
        <Input placeholder={t("Введіть значення") as string} />
      </Form.Item>

      <Form.Item
        rules={[
          {
            required: true,
            message: t("Заповніть поле") as string,
          },
        ]}
        name="userTypeUUID"
        label={t("Тип користувача")}
      >
        <Select listHeight={1000} loading={getUserTypesLoading} allowClear>
          {filteredUserTypes.map((type) => (
            <Option key={type.userTypeUUID} value={type.userTypeUUID}>
              {type.name || " "}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        rules={[
          {
            required: true,
            message: t("Заповніть поле") as string,
          },
        ]}
        name="disableAccess"
        initialValue={false}
        label={t("Заборонити доступ")}
      >
        <Select>
          <Option key="1" value={true}>
            {t("Так")}
          </Option>
          <Option key="2" value={false}>
            {t("Ні")}
          </Option>
        </Select>
      </Form.Item>

      {!!error && <Alert message={error} type="error" />}
    </Form>
  );
};

export default UserForm;
