import { ChatMessage } from "@/root/models/chat";
import { t } from "i18next";
import { v4 as uuidv4 } from "uuid";

const useChatMessagesWithSeparator = (
  chatMessages: ChatMessage[] | undefined,
  unreadMessages: ChatMessage[] | undefined
) => {
  // Group messages by date
  const groupedMessages = chatMessages?.reduce((grouped: any, message) => {
    const formattedDate = new Date(message.dt).toLocaleDateString("en-GB", {
      day: "numeric",
      month: "long",
    });

    const key =
      formattedDate ===
      new Date().toLocaleDateString("en-GB", {
        day: "numeric",
        month: "long",
      })
        ? "Today"
        : formattedDate;

    grouped[key] = grouped[key] || [];
    grouped[key].push(message);
    return grouped;
  }, {});

  // Convert grouped messages into an array with date separators
  const messagesWithSeparators = !!groupedMessages
    ? Object?.entries?.(groupedMessages)?.reduce(
        (result: any[], [date, messages]: any[]) => {
          result.push({
            date: date !== "Today" ? date : "Today",
            isSeparator: true,
          });
          result.push(
            ...messages.map((message: any) => ({
              ...message,
              isSeparator: false,
            }))
          );
          return result;
        },
        []
      )
    : undefined;

  // Unread messages separator

  // Find unread message UUIDs
  const unreadUUIDs = unreadMessages?.map((message) => message.messageUUID);

  // Find the first unread message in the main chat array
  const firstUnreadMessageIndex = messagesWithSeparators?.findIndex((message) =>
    unreadUUIDs?.includes(message.messageUUID)
  );

  // Add a separator if there are unread messages
  if (!!firstUnreadMessageIndex && firstUnreadMessageIndex !== -1) {
    // Create a separator message
    const separatorMessage = {
      key: uuidv4(),
      isUndreadMessagesSeparator: true,
      message: t("Hепрочитані повідомлення"),
    };

    // Insert separator message at the beginning of the chat array
    messagesWithSeparators?.splice(
      firstUnreadMessageIndex,
      0,
      separatorMessage
    );
  }

  return { messagesWithSeparators };
};

export default useChatMessagesWithSeparator;
