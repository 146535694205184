import { useState } from "react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { Form, Modal, Select } from "antd";
import { useForm } from "antd/es/form/Form";
import { CreatePricelistPayload, PricelistsApi } from "@/api/admin/pricelists";
import { Pricelist } from "@/root/models/pricelist";
import { PricelistForm } from "@/components";
import useAdminClients from "@/hooks/useAdminClients";

const { Option } = Select;

interface CreatePricelistModalProps {
  onCreate: (pricelist: Pricelist) => void;
  open: boolean;
  onCancel: () => void;
}

const CreatePricelistModal = ({
  onCreate,
  open,
  onCancel,
}: CreatePricelistModalProps) => {
  const { t } = useTranslation();
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { adminClients } = useAdminClients({});

  const handleCreate = async (values: CreatePricelistPayload) => {
    try {
      setLoading(true);
      setError("");
      const pricelist = await PricelistsApi.create({
        ...values,
        activationDate: dayjs(values.activationDate).format("YYYY.MM.DD"),
      });
      onCreate(pricelist);
      onCancel();
      form.resetFields();
    } catch (e: any) {
      setError(e.message);
    } finally {
      setLoading(false);
    }
  };

  const submit = () => {
    form.submit();
  };

  return (
    <Modal
      maskClosable={false}
      destroyOnClose
      okText={t("Створити")}
      title={
        <div style={{ marginBottom: 20 }}>{t("Створення прайс листа")}</div>
      }
      open={open}
      onCancel={onCancel}
      onOk={submit}
      okButtonProps={{ loading }}
    >
      <PricelistForm
        form={form}
        handleSubmit={handleCreate}
        error={error}
        render={(handleClientSelect) => (
          <Form.Item label={t("Контрагент")}>
            <Select onSelect={handleClientSelect}>
              {adminClients.map((client) => (
                <Option key={client.clientUUID} value={client.clientUUID}>
                  {client.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}
      />
    </Modal>
  );
};

export default CreatePricelistModal;
