import useWarehouses from "@/hooks/useWarehouses";
import { Alert, Space, Table } from "antd";
import { t } from "i18next";
import useModalOpen from "@/hooks/useModalOpen";
import { Warehouse } from "@/root/models/warehouse";
import { useIsLab } from "@/root/models/isLab";
import { ColumnsType } from "antd/es/table";
import CreateWarehouse from "./CreateWarehouse";
import WarehousesTableActions from "./WarehousesTableActions";

const WarehouseTab = () => {
  const { warehouses, getWarehousesLoading, getWarehousesError } =
    useWarehouses();

  const { modal, onHandleCancel, onEditSuccess, isModalOpen } = useModalOpen();

  const { isAdmin } = useIsLab();

  const warehousesColumns: ColumnsType<Warehouse> = [
    {
      title: t("Назва"),
      dataIndex: "name",
      key: "name",
    },
  ];

  if (isAdmin) {
    warehousesColumns.splice(1, 0, {
      title: "",
      key: "action",
      render: (warehouse: Warehouse) => (
        <WarehousesTableActions
          onEditSuccess={onEditSuccess}
          modal={modal}
          onCancel={onHandleCancel}
          onModalOpen={() => {
            isModalOpen(warehouse.warehouseUUID);
          }}
          warehouse={warehouse}
        />
      ),
    });
  }

  return (
    <Space direction="vertical">
      {isAdmin && <CreateWarehouse />}
      {getWarehousesError ? (
        <Alert type="error" message={getWarehousesError as string} />
      ) : (
        <Table
          style={{ userSelect: "none" }}
          columns={warehousesColumns}
          dataSource={warehouses.map((w) => ({ ...w, key: w.warehouseUUID }))}
          pagination={false}
          loading={getWarehousesLoading}
          onRow={(warehouse) => ({
            onDoubleClick: () => isModalOpen(warehouse.warehouseUUID),
          })}
        />
      )}
    </Space>
  );
};

export default WarehouseTab;
