import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useForm } from "antd/es/form/Form";
import { Button, DatePicker, Form, Select, Space } from "antd";

import styles from "./AdvancedTasksSearchForm.module.scss";

import useCaseStatuses from "@/hooks/useCaseStatuses";
import { useAppSelector } from "@/hooks/redux";
import { CaseStatusEnum, UserTypeEnum } from "@/root/types";
import { DATE_FORMAT } from "@/root/consts";
import AdminClients from "@/components/AdminClients";
import ClientEmployeesSelect from "./ClientEmployeesSelect";
import PatientsSelect from "./PatientsSelect";
import EmployeesSelect from "./EmployeesSelect";

export interface AdvancedSearchParameters {
  statuses: string[];
  adminClients: string[];
  clientEmployees?: string[];
  patients?: string[];
  employees?: string[];
  date: [string, string];
  dueDate: [string, string];
}

interface AdvancedCasesSearchFormProps {
  full: boolean;
  handleSubmit: (values: AdvancedSearchParameters) => void;
  setStatusesExclude: (statuses: string[]) => void;
}

const { RangePicker } = DatePicker;
const { Option } = Select;

const AdvancedCasesSearchForm = ({
  full,
  handleSubmit,
  setStatusesExclude,
}: AdvancedCasesSearchFormProps) => {
  const [form] = useForm();
  const { t } = useTranslation();
  const user = useAppSelector((state) => state.userReducer.user);
  const { statuses, loading: getStatusesLoading } = useCaseStatuses();
  const [chosenAdminClients, setChosenAdminClients] = useState<string[]>([]);

  useEffect(() => {
    if (!chosenAdminClients.length) {
      form.setFieldValue("clientEmployees", []);
      form.setFieldValue("patients", []);
    }
  }, [chosenAdminClients]);

  return (
    <Form
      className={styles.form}
      requiredMark={false}
      form={form}
      onFinish={handleSubmit}
    >
      {full && (
        <>
          <Space style={{ marginRight: 20, gap: 20 }}>
            <Form.Item
              name="statuses"
              label={t("Статус")}
              style={{ marginTop: 10, marginBottom: 15 }}
            >
              <Select
                mode="multiple"
                style={{ minWidth: 265, width: "fit-content" }}
                listHeight={1000}
                loading={getStatusesLoading}
                allowClear
              >
                {statuses.map((status) => (
                  <Option
                    key={status.caseStatusUUID}
                    value={status.caseStatusUUID}
                  >
                    {status.name || " "}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="statusesExclude"
              label={t("Виключити статус")}
              style={{ marginTop: 10, marginBottom: 15 }}
            >
              <Select
                mode="multiple"
                style={{ minWidth: 265, width: "fit-content" }}
                listHeight={1000}
                loading={getStatusesLoading}
                allowClear
                onChange={(value: string[]) => setStatusesExclude(value)}
                defaultValue={[CaseStatusEnum["Delivered"]]}
              >
                {statuses.map((status) => (
                  <Option
                    key={status.caseStatusUUID}
                    value={status.caseStatusUUID}
                  >
                    {status.name || " "}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Space>
          <Space
            size="large"
            style={{
              flexWrap: "wrap",
              gap: 10,
            }}
          >
            <Form.Item name="date" label={t("Дата замовлення")}>
              <RangePicker format={DATE_FORMAT} />
            </Form.Item>
            <Form.Item name="dueDate" label={t("Дата завершення")}>
              <RangePicker format={DATE_FORMAT} />
            </Form.Item>
            {user?.userType.userTypeUUID !== UserTypeEnum["Client's admin"] &&
              user?.userType.userTypeUUID !==
                UserTypeEnum["Client's employee"] && (
                <AdminClients
                  chosenAdminClients={chosenAdminClients}
                  setChosenAdminClients={setChosenAdminClients}
                />
              )}
            {(user?.userType.userTypeUUID === UserTypeEnum["Client's admin"] ||
              user?.userType.userTypeUUID ===
                UserTypeEnum["Client's employee"]) &&
            user?.client ? (
              <ClientEmployeesSelect clientUUIDs={chosenAdminClients} />
            ) : chosenAdminClients.length > 0 ? (
              <ClientEmployeesSelect clientUUIDs={chosenAdminClients} />
            ) : (
              <Form.Item label={t("Лікар")}>
                <Select
                  style={{ width: 200 }}
                  disabled
                  mode="multiple"
                  placeholder={t("Оберіть клієнта")}
                />
              </Form.Item>
            )}

            {(user?.userType.userTypeUUID === UserTypeEnum["Client's admin"] ||
              user?.userType.userTypeUUID ===
                UserTypeEnum["Client's employee"]) &&
            user?.client ? (
              <PatientsSelect clientUUIDs={[user.client.clientUUID]} />
            ) : chosenAdminClients.length > 0 ? (
              <PatientsSelect clientUUIDs={chosenAdminClients} />
            ) : (
              <Form.Item label={t("Пацієнт")}>
                <Select
                  style={{ width: 200 }}
                  disabled
                  mode="multiple"
                  placeholder={t("Оберіть клієнта")}
                />
              </Form.Item>
            )}

            {(user?.userType.userTypeUUID === UserTypeEnum["Employee"] ||
              user?.userType.userTypeUUID === UserTypeEnum["Lab's admin"]) && (
              <EmployeesSelect />
            )}
            <div>
              <Button htmlType="submit">{t("Шукати")}</Button>
            </div>
          </Space>
        </>
      )}
    </Form>
  );
};

export default AdvancedCasesSearchForm;
