import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Progress, Select, Space, Table, Typography } from "antd";
import { ColumnsType } from "antd/es/table";
import useTasks from "@/hooks/useTasks";
import { Task } from "@/root/models/task";
import { CreateTask, TasksTableActions } from "@/modules/Tasks/components";
import useCaseNumbers from "@/hooks/useCaseNumbers";
import { Block, Pagination } from "@/components/common";
import AdvancedSearch, {
  AdvancedTasksSearchValues,
} from "@/modules/Tasks/components/AdvancedSearch";
import useModalOpen from "../../hooks/useModalOpen";
import { FcFilledFilter } from "react-icons/fc";

import { GrDocument, GrDocumentVerified } from "react-icons/gr";

export interface TaskSort {
  order: "ascend" | "descend" | undefined;
  field: "completionDate" | "dueDate";
}

interface TasksProps {
  caseUUID?: string;
}

const { Option } = Select;

export interface TasksQueryProps {
  page?: number;
  pageLimit?: number;
  caseUUID?: string;
  taskStatusUUID?: string;
  courierUUID?: string;
  cases?: string;
  trackingNumber?: string;
  completionDate?: string;
  dueDate?: string;
  sort?: string;
}
const { Title } = Typography;

const Tasks = ({ caseUUID }: TasksProps) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [taskStatusUUID, setTaskStatusUUID] = useState<string>();
  const [courierUUID, setCourierUUID] = useState<string>();
  const [cases, setCases] = useState<string>();
  const [trackingNumber, setTrackingNumber] = useState<string>();
  const [completionDate, setCompletionDate] = useState<string>();
  const [dueDate, setDueDate] = useState<string>();
  const [sort, setSort] = useState<{
    field: "dueDate" | "completionDate";
    order: "desc" | "asc";
  }>({ field: "dueDate", order: "asc" });

  const tasksQueryProps = {
    caseUUID,
    page,
    taskStatusUUID,
    courierUUID,
    cases,
    trackingNumber,
    completionDate,
    dueDate,
    sort: sort ? `${sort.field},${sort.order}` : undefined,
    pageLimit: 25,
  };

  const { tasks, pages, getTasksLoading } = useTasks(tasksQueryProps);
  const { caseNumbers, getCaseNumbersLoading } = useCaseNumbers();
  const [filterCaseUUID, setFilterCaseUUID] = useState<string>();

  const { modal, onHandleCancel, onEditSuccess, isModalOpen } = useModalOpen();

  const [full, setFull] = useState(false);

  const tasksColumn: ColumnsType<Task> = [
    {
      width: 60,
      dataIndex: "applied",
      key: "applied",
      render: (applied: Task["applied"]) => (
        <div style={{ paddingLeft: 10, paddingTop: 5 }}>
          {applied ? (
            <GrDocumentVerified size={20} />
          ) : (
            <GrDocument size={20} />
          )}
        </div>
      ),
    },
    {
      width: 88,
      title: t("Прогрес"),
      dataIndex: "taskStatus",
      key: "taskStatus",
      render: (status: Task["taskStatus"]) => (
        <Progress size={50} type="circle" percent={status.progress} />
      ),
    },
    {
      title: t("Номер завдання"),
      dataIndex: "taskNumber",
      key: "taskNumber",
    },
    {
      title: t("Дата завершення"),
      dataIndex: "dueDate",
      key: "dueDate",
      render: (date: string) => <span>{date.slice(0, 16)}</span>,
      sorter: (a, b) =>
        new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime(),
      defaultSortOrder: "ascend",
    },
    {
      title: t("Дата виконання"),
      dataIndex: "completionDate",
      key: "completionDate",
      render: (date: string) => <span>{date.slice(0, 16)}</span>,
      sorter: (a, b) =>
        new Date(a.completionDate).getTime() -
        new Date(b.completionDate).getTime(),
    },
    {
      title: t("Кур'єр"),
      dataIndex: "courier",
      key: "courier",
      render: (courier: Task.Courier) => <span>{courier.name}</span>,
    },
    {
      title: t("Номер відстеження"),
      dataIndex: "trackingNumber",
      key: "trackingNumber",
    },
    {
      title: t("Статус завдання"),
      dataIndex: "taskStatus",
      key: "taskStatus",
      render: (taskStatus: Task.TaskStatus) => <span>{taskStatus.name}</span>,
    },
    {
      title: t("Опис"),
      dataIndex: "description",
      key: "description",
    },
    {
      title: t("Номер замовлення"),
      dataIndex: "cases",
      key: "caseNumbers",
      render: (cases: Task["cases"]) => (
        <span>{cases.map((c) => c.case.caseNumber).join(", ")}</span>
      ),
    },
    {
      title: t("Контрагент"),
      dataIndex: "client",
      key: "client",
      render: (client: Task["client"]) => <span>{client.name}</span>,
    },
    {
      title: t("Пацієнт"),
      dataIndex: "cases",
      key: "patients",
      render: (cases: Task["cases"]) => (
        <span>{cases.map((c) => c.case.patient).join(", ")}</span>
      ),
    },
    {
      title: "",
      key: "action",
      render: (_: any, task: Task) => (
        <TasksTableActions
          task={task}
          modal={modal}
          onModalOpen={() => isModalOpen(task.taskUUID)}
          onCancel={onHandleCancel}
          onEditSuccess={onEditSuccess}
          tasksQueryProps={tasksQueryProps}
        />
      ),
    },
  ];

  const filteredTasks = filterCaseUUID
    ? tasks.filter((task) =>
        task.cases.map((c) => c.case.caseUUID).includes(filterCaseUUID)
      )
    : tasks;

  const tableRef = useRef<HTMLDivElement>(null);

  const paginationRange = tableRef.current
    ? Math.floor(
        (tableRef.current.getBoundingClientRect().width - 8 - 30 - 39 * 4) / 39
      )
    : 1;

  const handleAdvancedSearch = (values: AdvancedTasksSearchValues) => {
    setTaskStatusUUID(values.taskStatusUUID);
    setCourierUUID(values.courierUUID);
    setCases(values.cases);
    setTrackingNumber(values.trackingNumber);
    setCompletionDate(values.completionDate);
    setDueDate(values.dueDate);
  };

  const handleSort = async ({ order, field }: TaskSort) => {
    if (!order) {
      return;
    }

    setPage(1);
    setSort({ field, order: order === "descend" ? "desc" : "asc" });
  };

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <Block>
        <Space
          direction="vertical"
          style={{ justifyContent: "center", width: "50%" }}
        >
          <Title level={3} style={{ textAlign: "center" }}>
            {t("Завдання")}
          </Title>
          <Space>
            <Button
              onClick={() => setFull(!full)}
              icon={<FcFilledFilter style={{ width: 20, height: 20 }} />}
            />
            <CreateTask tasksQueryProps={tasksQueryProps} />
            <div>
              {t("Замовлення")}:{" "}
              <Select
                style={{ minWidth: 140 }}
                showSearch
                optionFilterProp="children"
                allowClear
                value={filterCaseUUID}
                onSelect={setFilterCaseUUID}
                onClear={() => setFilterCaseUUID(undefined)}
                loading={getCaseNumbersLoading}
              >
                {caseNumbers.map((medCase) => (
                  <Option key={medCase.caseUUID} value={medCase.caseUUID}>
                    {medCase.caseNumber}
                  </Option>
                ))}
              </Select>
            </div>
          </Space>

          <AdvancedSearch onSearch={handleAdvancedSearch} full={full} />
        </Space>
      </Block>

      <Table
        ref={tableRef}
        style={{ userSelect: "none", overflow: "auto" }}
        columns={tasksColumn}
        dataSource={filteredTasks.map((p) => ({ ...p, key: p.taskUUID }))}
        pagination={false}
        loading={getTasksLoading}
        onChange={(_, __, sorter, { action }) =>
          action === "sort" && handleSort(sorter as TaskSort)
        }
        onRow={(task) => ({
          onDoubleClick: () => isModalOpen(task.taskUUID),
        })}
      />
      <Block
        style={{
          overflow: "hidden",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Pagination
          currentPage={page}
          pages={pages || 1}
          paginationRange={paginationRange}
          setCurrentPage={setPage}
        />
      </Block>
    </Space>
  );
};

export default Tasks;
