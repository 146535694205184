import {
  PaymentCreatePayloadProps,
  PaymentEditPayloadProps,
  PaymentsApi,
} from "@/api/payments";
import { ResponseDataWithPagination } from "@/api/types";
import { Payment } from "@/root/models/payment";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

interface UsePaymentsProps {
  pageLimit?: number;
  page?: number;
  date?: string;
  onEditSuccess?: () => void;
  onDeleteError?: (message: string) => void;
  onCreateSuccess?: () => void;
  clientUUID?: string;
  onDeleteSuccess?: () => void;
}

export const usePayments = ({
  page,
  clientUUID,
  date,
  onDeleteError,
  onEditSuccess,
  onCreateSuccess,
  onDeleteSuccess,
}: UsePaymentsProps) => {
  const queryClient = useQueryClient();

  const {
    data: payments,
    isFetching: getPaymentsLoading,
    error: getPaymentsError,
    refetch,
  } = useQuery({
    queryKey: ["payments", clientUUID, date],
    queryFn: () => PaymentsApi.getPayments(page, clientUUID, date),
  });

  const createPaymentMutation = useMutation({
    mutationFn: PaymentsApi.create,
    onSuccess: (payment) => {
      queryClient.setQueryData(
        ["payments", clientUUID, date],
        (
          prev: ResponseDataWithPagination<Payment[]> | Payment[] | undefined
        ) => {
          if (!prev) {
            return [payment];
          }

          if ("data" in prev) {
            return {
              data: [...prev.data, payment],
              page: prev.page,
              pages: prev.pages,
            };
          }
          return [...prev, payment];
        }
      );
      onCreateSuccess?.();
    },
  });

  const editPaymentMutation = useMutation({
    mutationFn: PaymentsApi.edit,
    onSuccess: (payment: Payment) => {
      queryClient.setQueryData(
        ["payments", clientUUID, date],
        (
          prev: ResponseDataWithPagination<Payment[]> | Payment[] | undefined
        ) => {
          onEditSuccess?.();

          if (!prev) {
            return [];
          }

          if ("data" in prev) {
            return {
              data: (prev.data || []).map((prevPayment) => {
                if (prevPayment.paymentUUID === payment.paymentUUID) {
                  return payment;
                }
                return prevPayment;
              }),
              page: prev.page,
              pages: prev.pages,
            };
          }

          return (prev || []).map((prevPayment) => {
            if (prevPayment.paymentUUID === payment.paymentUUID) {
              return payment;
            }
            return prevPayment;
          });
        }
      );
    },
  });

  const deletePaymentMutation = useMutation({
    mutationFn: PaymentsApi.delete,
    onError: (error) => onDeleteError?.((error as Error).message),
    onSuccess: (_, paymentUUID) => {
      queryClient.setQueryData(
        ["payments", clientUUID, date],
        (
          prev: ResponseDataWithPagination<Payment[]> | Payment[] | undefined
        ) => {
          if (!prev) {
            return [];
          }

          if ("data" in prev) {
            return {
              data: (prev?.data || []).filter(
                (p) => p.paymentUUID !== paymentUUID
              ),
              page: prev.page,
              pages: prev.pages,
            };
          }

          return (prev || []).filter((p) => p.paymentUUID !== paymentUUID);
        }
      );
      onDeleteSuccess?.();
    },
  });

  const deletePayment = async (id: string) => {
    await deletePaymentMutation.mutateAsync(id);
  };

  const createPayment = (payload: PaymentCreatePayloadProps) => {
    createPaymentMutation.mutate({ payload });
  };

  const editPayment = (id: string, payload: PaymentEditPayloadProps) => {
    editPaymentMutation.mutate({ id, payload });
  };

  const createError = (createPaymentMutation.error as Error)?.message as string;

  const editError = (editPaymentMutation.error as Error)?.message as string;

  return {
    payments: payments?.data || [],
    paymentsPages: payments?.pages,
    getPaymentsLoading,
    getPaymentsError,
    createPayment,
    editPayment,
    deletePayment,
    createError,
    editError,
    createLoading: createPaymentMutation.isLoading,
    editLoading: editPaymentMutation.isLoading,
    deleteLoading: deletePaymentMutation.isLoading,
    refetch,
  };
};
