import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { QRCodeSVG } from "qrcode.react";

import styles from "./LaboratoryCase.module.scss";

import { Attachment, Job } from "@/root/models/case";
import { TeethWidget } from "@/components";
import { JobItem } from "@/components/LaboratoryCase/components";
import useCombinedJobTeeth from "@/hooks/useCombinedJobTeeth";
import { TeethFormulaTypeEnum } from "@/root/types";
import PrintHeader from "../LaboratoryCaseForm/components/PrintMenu/components/PrintHeader";
import { InfoProps } from "@/hooks/useInfo";

export interface PrintCase {
  jobs: Job[];
  arrival?: string;
  dueDate?: string;
  caseNumber?: string;
  client?: { name: string };
  clientsEmployee?: { name: string } | null;
  patient?: { name: string };
  teethFormulaType?: {
    teethFormulaTypeUUID: string;
  };
  description?: string;
  attachments?: Attachment[];
}

interface LaboratoryCaseProps {
  medCase: PrintCase;
  teethFormulaNumberingUUID: string;
  info: InfoProps | undefined;
}

const LaboratoryCase = ({
  medCase,
  teethFormulaNumberingUUID,
  info,
}: LaboratoryCaseProps) => {
  const { t } = useTranslation();
  const allTeeth = useCombinedJobTeeth(medCase.jobs || []);

  if (!medCase) {
    return null;
  }

  return (
    <>
      <PrintHeader info={info} />
      <div style={{ margin: 10 }}>
        <h4 className={styles.title}>{t("Деталі замовлення")}:</h4>
        <div className={styles.container}>
          <div className={styles.details}>
            <div>
              <div className={styles.row}>
                <div className={styles.column}>
                  {t("Дата створення")}: {medCase.arrival?.slice(0, 10)}
                </div>
                <div className={styles.column}>
                  {t("Дата завершення")}: {medCase.dueDate?.slice(0, 10)}
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.column}>
                  {t("Номер замовлення")}: {medCase.caseNumber}
                </div>
                <div className={styles.column}>
                  {t("Назва клінікі")}: {medCase.client?.name}
                </div>
              </div>
              <div className={styles.row}>
                {t("Лікар")}: {medCase.clientsEmployee?.name}
              </div>
              <div className={styles.row}>
                {t("Пацієнт")}: {medCase.patient?.name}
              </div>
            </div>
            {medCase.caseNumber && (
              <div style={{}}>
                <div>
                  <QRCodeSVG height={110} value={medCase.caseNumber} />
                </div>
                <div style={{ fontSize: 16, textAlign: "center" }}>
                  {medCase.caseNumber}
                </div>
              </div>
            )}
          </div>
          <div className={styles.row}>
            <div className={styles.column}>
              <TeethWidget
                formulaNumbering={teethFormulaNumberingUUID || ""}
                formulaType={
                  medCase.teethFormulaType?.teethFormulaTypeUUID ===
                  TeethFormulaTypeEnum.child
                    ? "child"
                    : "adult"
                }
                chosenTeeth={allTeeth}
              />
            </div>
            <div className={classNames(styles.column, styles.withRows)}>
              {medCase.jobs.map((job) => (
                <JobItem key={job.jobUUID} job={job} />
              ))}
            </div>
          </div>
        </div>

        <div className={styles.container}>
          <strong>Description: {medCase.description}</strong>
        </div>

        {!!medCase.attachments?.length && (
          <div>
            <strong>
              Case has files, watch it on online case. (Якщо містить файли)
            </strong>
          </div>
        )}
      </div>
    </>
  );
};

export default LaboratoryCase;
