import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Table } from "antd";
import { ColumnsType } from "antd/es/table";

import styles from "../../LaboratoryCaseForm.module.scss";

import {
  CaseFormState,
  useCaseFormContext,
} from "@/components/CaseForm/CaseFormContext";
import ChooseProduct from "../ChooseProduct";
import Teeth from "../Teeth";
import Jobs from "../Jobs";
import Parameters from "../Parameters";
import Sum from "@/components/LaboratoryCaseForm/components/Sum";
import { calculateProductPrice } from "@/utils/calculateCasePrice";
import useTableScrollHeight from "@/hooks/TableHooks/useTableScrollHeight";

interface ProductProps {
  isAddProduct?: boolean;
}

const Products = ({ isAddProduct }: ProductProps) => {
  const { t } = useTranslation();
  const { state } = useCaseFormContext();

  const { tableScrollHeight } = useTableScrollHeight(205);

  const [activeProduct, setActiveProduct] = useState();

  const columns: ColumnsType<any> = [
    {
      key: "product",
      dataIndex: "product",
      title: t("Вироби"),
      render: (
        _,
        {
          fullJob,
          fullProduct,
          isLastProduct,
        }: {
          fullProduct: CaseFormState.Job.Product | null;
          fullJob: CaseFormState.Job;
          isLastProduct: boolean;
        }
      ) => (
        <ChooseProduct
          activeJob={fullJob}
          activeProduct={fullProduct || undefined}
          isLast={isLastProduct}
        />
      ),
      onCell: (row) => {
        if (row.product === undefined) {
          return { rowSpan: 0 };
        }
        return { rowSpan: row.rowSpan };
      },
      width: "30%",
    },
    {
      key: "teeth",
      dataIndex: "teeth",
      title: t("Зуби"),
      render: (
        _,
        {
          fullJob,
          fullProduct,
          teeth,
          isLastTeeth,
        }: {
          fullProduct: CaseFormState.Job.Product | null;
          fullJob: CaseFormState.Job;
          teeth: string[];
          isLastTeeth: boolean;
        }
      ) =>
        fullProduct ? (
          <Teeth
            activeJobID={fullJob.jobID}
            activeProductID={fullProduct.jobProductUUID}
            teeth={teeth}
            productTeeth={fullProduct.teeth}
            isLastTeeth={isLastTeeth}
          />
        ) : null,
      width: "10%",
    },
    {
      key: "parameter",
      dataIndex: "parameter",
      title: t("Параметри"),
      render: (
        _,
        {
          fullJob,
          fullProduct,
          teeth,
        }: {
          fullProduct: CaseFormState.Job.Product | null;
          fullJob: CaseFormState.Job;
          teeth: string[];
        }
      ) =>
        fullProduct ? (
          <Parameters
            activeJobID={fullJob.jobID}
            product={fullProduct}
            activeTooth={fullProduct.differentTeethParameters ? teeth[0] : null}
          />
        ) : null,
      width: "25%",
    },
  ];

  const sum = state.jobs.reduce(
    (sum, job) =>
      sum +
      (job.products?.reduce((sum, product) => {
        const price = calculateProductPrice({
          product,
          productsCount: job.products.length,
          jobsCount: state.jobs.length,
          teethFormulaTypeUUID: state.teethFormulaTypeUUID as string,
        });
        return sum + price;
      }, 0) || 0),
    0
  );

  // todo: refactoring
  const productsData = state.jobs.flatMap((job) => {
    if (!job.products?.length) {
      return [
        {
          fullJob: job,
          key: job.jobID,
          jobName: job.jobName,
          product: true,
          isLastProduct: true,
        },
      ];
    }
    return job.products.reduce((sum, p, productIndex) => {
      const rows = p.differentTeethParameters
        ? p.teeth?.length
          ? p.teeth.map((tooth, toothIndex) => {
              if (productIndex === 0 && toothIndex === 0) {
                return {
                  fullJob: job,
                  fullProduct: p,
                  key: p.jobProductUUID + tooth,
                  jobName: job.jobName,
                  product: p.jobProductUUID,
                  teeth: [tooth],
                  parameter: p.product?.name,
                  price: p.price,
                  rowSpan: p.teeth?.length,
                  isLastTeeth: p.teeth?.length === toothIndex + 1,
                  isLastProduct: job.products.length === productIndex + 1,
                  jobRowSpan: job.products.reduce((sum, p) => {
                    sum += p.differentTeethParameters
                      ? p.teeth?.length || 1
                      : 1;
                    return sum;
                  }, 0),
                };
              }
              if (toothIndex === 0) {
                return {
                  fullJob: job,
                  fullProduct: p,
                  key: p.jobProductUUID + tooth,
                  product: p.jobProductUUID,
                  teeth: [tooth],
                  parameter: p.product?.name,
                  price: p.price,
                  rowSpan: p.teeth?.length,
                  isLastTeeth: p.teeth?.length === toothIndex + 1,
                  isLastProduct: job.products.length === productIndex + 1,
                  jobRowSpan: job.products.reduce((sum, p) => {
                    sum += p.differentTeethParameters
                      ? p.teeth?.length || 1
                      : 1;
                    return sum;
                  }, 0),
                };
              }
              return {
                fullJob: job,
                fullProduct: p,
                key: p.jobProductUUID + tooth,
                teeth: [tooth],
                isLastTeeth: p.teeth?.length === toothIndex + 1,
                isLastProduct: job.products.length === productIndex + 1,
                parameter: p.product?.name,
              };
            })
          : [
              {
                fullJob: job,
                fullProduct: p,
                key: p.jobProductUUID,
                jobName: job.jobName,
                product: p.jobProductUUID,
                parameter: p.product?.name,
                teeth: [],
                price: p.price,
                rowSpan: 1,
                isLastTeeth: true,
                isLastProduct: job.products.length === productIndex + 1,
                jobRowSpan: job.products.reduce((sum, p) => {
                  sum += p.differentTeethParameters ? p.teeth?.length || 1 : 1;
                  return sum;
                }, 0),
              },
            ]
        : [
            productIndex === 0
              ? {
                  fullJob: job,
                  fullProduct: p,
                  key: p.jobProductUUID,
                  jobName: job.jobName,
                  product: p.jobProductUUID,
                  teeth: p.teeth,
                  isLastTeeth: true,
                  isLastProduct: job.products.length === productIndex + 1,
                  parameter: p.product?.name,
                  price: p.price,
                  jobRowSpan: job.products.reduce((sum, p) => {
                    sum += p.differentTeethParameters
                      ? p.teeth?.length || 1
                      : 1;
                    return sum;
                  }, 0),
                }
              : {
                  fullJob: job,
                  fullProduct: p,
                  key: p.jobProductUUID,
                  product: p.jobProductUUID,
                  teeth: p.teeth,
                  isLastTeeth: true,
                  isLastProduct: job.products.length === productIndex + 1,
                  parameter: p.product?.name,
                  price: p.price,
                  jobRowSpan: job.products.reduce((sum, p) => {
                    sum += p.differentTeethParameters
                      ? p.teeth?.length || 1
                      : 1;
                    return sum;
                  }, 0),
                },
          ];
      // @ts-ignore
      sum.push(...rows);
      return sum;
    }, []);
  });

  return (
    <div className={styles.container}>
      {productsData.length ? (
        <Table
          bordered
          pagination={false}
          columns={columns}
          dataSource={isAddProduct ? [] : productsData}
          scroll={{
            y: tableScrollHeight,
          }}
          onRow={(product) => ({
            onClick: () => {
              setActiveProduct(product);
            },
          })}
        />
      ) : (
        <Table
          bordered
          pagination={false}
          columns={[
            {
              key: "job",
              dataIndex: "jobName",
              title: t("Робота"),
              render: () => <Jobs />,
            },
          ]}
          dataSource={[{}]}
        />
      )}
      <Sum price={sum} />
    </div>
  );
};

export default Products;
