import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ChatsApi, CreateChatPayload } from "@/api/chats";
import { ChatTypes } from "@/root/models/chat";
import { setChat } from "@/store/reducers/chatSlice";
import { useAppDispatch, useAppSelector } from "./redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ChatObjectEnum, NotExistEnum, UserTypeEnum } from "@/root/types";
import { pages } from "@/root/consts";
import { Case } from "@/root/models/case";
import { useIsLab } from "@/root/models/isLab";
import { notification } from "antd";
import { t } from "i18next";

const useChats = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const userType = useAppSelector((state) => state.userReducer.user?.userType);
  const userUUID = useAppSelector((state) => state.userReducer.user?.userUUID);
  const { isLab } = useIsLab();

  const {
    data: chats,
    isLoading: chatsLoading,
    error: chatsError,
    refetch,
  } = useQuery({
    queryKey: ["chats"],
    queryFn: ChatsApi.getChats,
  });

  const createChatMutation = useMutation({
    mutationFn: ChatsApi.createChat,
    onSuccess: (chat) => {
      queryClient.setQueryData(["chats"], (prev: ChatTypes[] | undefined) => {
        if (!prev) {
          return [chat];
        }
        return [chat, ...prev];
      });

      notification.success({
        message: t("Чат був успішно створений!"),
      });
    },
  });

  const createChat = (payload: CreateChatPayload) => {
    createChatMutation.mutate(payload);
  };

  const createError = (createChatMutation.error as Error)?.message as string;

  const deleteChatMutation = useMutation({
    mutationFn: ChatsApi.deleteChat,
    onError: (error) =>
      notification.error({
        message: error as string,
      }),
    onSuccess: (_, chatUUID) => {
      queryClient.setQueryData(["chats"], (prev: ChatTypes[] | undefined) =>
        (prev || []).filter((p) => p.chatUUID !== chatUUID)
      );
      notification.success({
        message: t("Чат був успішно видалений!"),
      });
    },
  });

  const deleteChat = async (chatUUID: string) => {
    await deleteChatMutation.mutateAsync(chatUUID);
  };

  const deleteError = (deleteChatMutation.error as Error)?.message as string;

  //Open chat tab if on dashboard page or go to dashboard and open chat tab
  const locationChangeAndChatOpen = async (chatUUID: string) => {
    await dispatch(setChat(""));

    if (location.pathname.includes("dashboard")) {
      dispatch(setChat(chatUUID));
    } else {
      if (userType) {
        switch (userType.userTypeUUID) {
          case UserTypeEnum["Client's employee"]:
          case UserTypeEnum["Client's admin"]:
            navigate(pages.client.dashboard.path);
            dispatch(setChat(chatUUID));
            break;
          case UserTypeEnum["Employee"]:
            navigate(pages.lab.dashboard.path);
            dispatch(setChat(chatUUID));
            break;
          case UserTypeEnum["Lab's admin"]:
            navigate(pages.admin.dashboard.path);
            dispatch(setChat(chatUUID));
            break;
          case UserTypeEnum["Courier"]:
            navigate(pages.courier.dashboard.path);
            dispatch(setChat(chatUUID));
            break;
        }
      }
    }
  };

  const onCaseChatHandle = (medCase: Case) => {
    locationChangeAndChatOpen(medCase.chatUUID);
    if (medCase && medCase.chatUUID === NotExistEnum["NullUUID"]) {
      createChat({
        chatObjectUUID: ChatObjectEnum["Case"],
        objectUUID: medCase.caseUUID,
        users: isLab
          ? [userUUID, medCase.clientsEmployee?.clientsEmployeeUUID]
          : [userUUID],
      });
      setTimeout(() => {
        locationChangeAndChatOpen(medCase.chatUUID);
      }, 0);
    } else if (medCase && medCase.chatUUID !== NotExistEnum["NullUUID"]) {
      locationChangeAndChatOpen(medCase.chatUUID);
    }
  };

  return {
    chats: chats || [],
    chatsLoading,
    chatsError,
    createChat,
    createError,
    createLoading: createChatMutation.isLoading,
    refetch,
    locationChangeAndChatOpen,
    onCaseChatHandle,
    deleteChat,
    deleteError,
  };
};

export default useChats;
