import { t } from "i18next";
import { Form, Select } from "antd";

import styles from "./SearchEmployee.module.scss";

import useEmployees from "@/hooks/useEmployees";
import {
  ActionCreatorTypes,
  useCaseFormContext,
} from "@/components/CaseForm/CaseFormContext";

const { Option } = Select;

const SearchEmployee = () => {
  const { employees, getEmployeesLoading } = useEmployees();
  const { state, dispatch } = useCaseFormContext();

  const handleSearch = (employeeUUID: string, value: string) => {
    const employee = employees.find((p) => p.employeeUUID === employeeUUID);
    if (employee) {
      if (employee.name.toLowerCase().includes(value.toLowerCase())) {
        return true;
      }
    }
    return false;
  };

  const handleSelect = (value: string) => {
    const employee = employees.find((e) => e.employeeUUID === value);
    if (!employee) {
      return;
    }
    dispatch({
      type: ActionCreatorTypes.SetEmployee,
      payload: employee,
    });
  };

  return (
    <div className={styles.container}>
      <Form.Item
        className={styles.formItem}
        labelCol={{ span: 10 }}
        label={t("Співробітник")}
        rules={[{ required: true, message: t("Заповніть поле") as string }]}
      >
        <Select
          listHeight={1000}
          showSearch
          loading={getEmployeesLoading}
          optionFilterProp="children"
          filterOption={(input, option) =>
            option?.value ? handleSearch(option.value as string, input) : false
          }
          value={state.activeHandler?.activeHandlerUUID}
          onSelect={handleSelect}
        >
          {employees.map((employee) => (
            <Option key={employee.employeeUUID} value={employee.employeeUUID}>
              {employee.name}
            </Option>
          ))}
          {!employees.find(
            (e) => e.employeeUUID === state.activeHandler?.activeHandlerUUID
          ) &&
            state.activeHandler && (
              <Option
                key={state.activeHandler.activeHandlerUUID}
                value={state.activeHandler.activeHandlerUUID}
              >
                {state.activeHandler.activeHandlerName}
              </Option>
            )}
        </Select>
      </Form.Item>
    </div>
  );
};

export default SearchEmployee;
