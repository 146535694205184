import { getParameterValue } from "@/utils/getParameterValue";
import { ProductParameter } from "@/root/models/productParameter";
import useFilters from "@/hooks/useFilters";

interface ProductsParametersGroupProps {
  group: {
    productsParametersGroup: {
      productsParametersGroupUUID: string;
      name: string;
    };
    productsParameters: {
      productsParameter: ProductParameter;
      value: string;
      tooth: string;
    }[];
  };
  tooth: string | null;
}

const ProductsParametersGroup = ({
  group,
  tooth,
}: ProductsParametersGroupProps) => {
  const { findFilterByID } = useFilters();

  return (
    <div style={{ marginLeft: 10, marginBottom: 10 }}>
      <div>{group.productsParametersGroup.name}:</div>
      <div style={{ marginLeft: 20 }}>
        {group.productsParameters
          .filter((p) => !p.tooth || p.tooth === tooth)
          .map((parameter) => (
            <div key={parameter.productsParameter.productsParameterUUID}>
              {parameter.productsParameter.name}:{" "}
              <span>
                {getParameterValue(
                  parameter.productsParameter.type,
                  parameter.value,
                  findFilterByID
                )}
              </span>
            </div>
          ))}
      </div>
    </div>
  );
};

export default ProductsParametersGroup;
