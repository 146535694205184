import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Modal, notification, Space } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Task } from "@/root/models/task";
import { CreateTaskPayload } from "@/api/tasks";
import useTasks from "@/hooks/useTasks";
import { TasksQueryProps } from "@/modules/Tasks";
import TaskPhotos, { TaskPhoto } from "@/modules/Tasks/components/TaskPhotos";

import styles from "./TableAction.module.scss";

import { useForm } from "antd/lib/form/Form";
import dayjs from "dayjs";
import TaskForm from "../TaskForm";

const { confirm } = Modal;

interface TasksTableActionsProps {
  task: Task;
  tasksQueryProps: TasksQueryProps;
  direction?: "horizontal" | "vertical";
  modal: string | null;
  onCancel: () => void;
  onEditSuccess: () => void;
  onModalOpen: () => void;
}

const TasksTableActions = ({
  task,
  tasksQueryProps,
  direction,
  modal,
  onCancel,
  onEditSuccess,
  onModalOpen,
}: TasksTableActionsProps) => {
  const { t } = useTranslation();
  const [photos, setPhotos] = useState<TaskPhoto[]>([]);
  const [form] = useForm();

  useEffect(() => {
    setPhotos(task.attachments);
  }, [task]);

  const onDeleteError = (message: string) => {
    notification.error({
      message,
    });
  };

  const { deleteTask, deleteLoading, editTask, editLoading, editError } =
    useTasks({
      ...tasksQueryProps,
      onDeleteError,
      onEditSuccess,
    });

  const handleDelete = async () => {
    await deleteTask(task.taskUUID);
  };

  const handleEdit = async (values: CreateTaskPayload) => {
    editTask(task.taskUUID, {
      ...values,
      attachments: photos.map(({ fileUUID, filename, description }) => ({
        filename,
        description,
        fileUUID,
      })),
    });
  };

  const submit = () => {
    form.submit();
  };

  const handleDeleteButtonClick = () => {
    confirm({
      title: t("Підтвердити видалення"),
      okText: t("Видалити"),
      okType: "danger",
      cancelText: t("Скасувати"),
      onOk: handleDelete,
    });
  };

  const onHandleOpen = () => {
    onModalOpen();
  };

  useEffect(() => {
    if (modal) {
      form.setFieldsValue({
        ...task,
        taskStatusUUID: task.taskStatus.taskStatusUUID,
        courierUUID: task.courier.courierUUID,
        dueDate: task.dueDate && dayjs(new Date(task.dueDate)),
        dueDateTime: task.dueDate.slice(11),
        completionDate:
          task.completionDate && dayjs(new Date(task.completionDate)),
        completionDateTime:
          task.completionDate && task.completionDate.slice(11),
      });
    }
  }, [modal]);

  return (
    <>
      <Space
        className="table-actions"
        direction={direction}
        size="middle"
        align="center"
      >
        <Button size="small" icon={<EditOutlined />} onClick={onHandleOpen} />
        <Button
          size="small"
          icon={<DeleteOutlined />}
          loading={deleteLoading}
          danger
          onClick={handleDeleteButtonClick}
        />
      </Space>
      <Modal
        maskClosable={false}
        okText={t("Зберегти")}
        title={
          <div style={{ marginBottom: 20 }}>{t("Редагування завдання")}</div>
        }
        open={modal === task.taskUUID}
        onCancel={onCancel}
        onOk={submit}
        centered
        okButtonProps={{ loading: editLoading }}
        className={styles.editTaskModal_content}
      >
        <TaskForm
          type="edit"
          cases={task.cases}
          form={form}
          handleSubmit={handleEdit}
          error={editError}
        />
        <TaskPhotos
          taskUUID={task.taskUUID}
          photos={photos}
          setPhotos={setPhotos}
        />
      </Modal>
    </>
  );
};

export default TasksTableActions;
