import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

import { Button, Modal, notification, Space } from "antd";
import { useForm } from "antd/es/form/Form";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

import { Pricelist } from "@/root/models/pricelist";
import { CreatePricelistPayload, PricelistsApi } from "@/api/admin/pricelists";
import { PricelistForm } from "@/components";

const { confirm } = Modal;

interface PricelistsTableActionsProps {
  pricelist: Pricelist;
  onDelete: (id: string) => void;
  onEdit: (id: string, pricelist: Pricelist) => void;
  modal: string | null;
  onCancel: () => void;
  onModalOpen: () => void;
  onEditSuccess: () => void;
}

const PricelistsTableActions = ({
  pricelist,
  onDelete,
  onEdit,
  modal,
  onCancel,
  onEditSuccess,
  onModalOpen,
}: PricelistsTableActionsProps) => {
  const { t } = useTranslation();
  const [form] = useForm();
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [editError, setEditError] = useState("");

  const handleDelete = async () => {
    try {
      setDeleteLoading(true);
      await PricelistsApi.delete(pricelist.pricelistUUID);
      onDelete(pricelist.pricelistUUID);
    } catch (e: any) {
      notification.error({
        message: e.message,
      });
    } finally {
      setDeleteLoading(false);
    }
  };

  const handleEdit = async (values: CreatePricelistPayload) => {
    try {
      setEditLoading(true);
      setEditError("");
      const newPricelist = await PricelistsApi.edit(pricelist.pricelistUUID, {
        ...values,
        activationDate: dayjs(values.activationDate).format("YYYY.MM.DD"),
      });
      onEdit(pricelist.pricelistUUID, newPricelist);
      onEditSuccess();
    } catch (e: any) {
      setEditError(e.message);
    } finally {
      setEditLoading(false);
    }
  };

  const submit = () => {
    form.submit();
  };

  const onHandleOpen = () => {
    onModalOpen();
  };

  useEffect(() => {
    if (modal) {
      form.setFieldsValue({
        ...pricelist,
        priceListUUID: pricelist.pricelistUUID,
        pricelistTypeUUID: pricelist.pricelistType.pricelistTypeUUID,
        description: pricelist.description,
        activationDate: dayjs(pricelist.activationDate),
        list: pricelist.list.map(({ price, item }) => ({
          itemUUID: Pricelist.List.GetItemUUID(item),
          price,
        })),
      });
    }
  }, [modal]);

  const handleDeleteButtonClick = () => {
    confirm({
      title: t("Підтвердити видалення"),
      okText: t("Видалити"),
      okType: "danger",
      cancelText: t("Скасувати"),
      onOk: handleDelete,
    });
  };

  return (
    <Space className="table-actions" size="middle">
      <Button size="small" icon={<EditOutlined />} onClick={onHandleOpen} />
      <Button
        size="small"
        icon={<DeleteOutlined />}
        loading={deleteLoading}
        danger
        onClick={handleDeleteButtonClick}
      />
      <Modal
        maskClosable={false}
        okText={t("Зберегти")}
        title={
          <div style={{ marginBottom: 20 }}>{t("Редагування прайс листа")}</div>
        }
        open={modal === pricelist.pricelistUUID}
        onCancel={onCancel}
        onOk={submit}
        okButtonProps={{ loading: editLoading }}
      >
        <PricelistForm
          form={form}
          handleSubmit={handleEdit}
          error={editError}
        />
      </Modal>
    </Space>
  );
};

export default PricelistsTableActions;
