import ChatNotificationsApi from "@/api/chatNotifications";
import { useMutation, useQuery } from "@tanstack/react-query";

const useChatNotifications = () => {
  const {
    data: chatNotifications,
    isLoading: chatsLoading,
    error: chatsError,
    refetch,
  } = useQuery({
    queryKey: ["chatNotifications"],
    queryFn: ChatNotificationsApi.getChatNotifications,
  });

  const deleteChatNotificationsMutation = useMutation({
    mutationFn: ChatNotificationsApi.delete,
  });

  const deleteChatNotifications = async (
    chatUUID: string,
    messageUUID: string
  ) => {
    await deleteChatNotificationsMutation.mutateAsync({
      chatUUID,
      messageUUID,
    });
  };

  return {
    chatNotifications,
    chatsLoading,
    chatsError,
    refetchChatNotif: refetch,
    deleteChatNotifications,
  };
};

export default useChatNotifications;
