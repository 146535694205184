import { useEffect, useState } from "react";
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { Alert, DatePicker, Form, FormInstance, Input, Select } from "antd";
import MaskedInput from "antd-mask-input";
import TextArea from "antd/es/input/TextArea";
import { CreateTaskPayload } from "@/api/tasks";
import useTaskStatuses from "@/hooks/useTaskStatuses";
import {
  CaseStatusEnum,
  CouriersAvailabilityStatusEnum,
  TaskStatusEnum,
} from "@/root/types";
import { timeMask } from "@/root/consts";
import { formatDateTime } from "@/utils/formatDataTime";
import useCaseStatuses from "@/hooks/useCaseStatuses";
import { Task } from "@/root/models/task";
import styles from "./TaskForm.module.scss";
import { CouriersAvailability } from "@/root/models/couriersAvailability";
import makeRequest from "@/api/makeRequest";
import { apiBaseurl } from "@/api/baseurl";
import TaskCasesEdit from "../TaskCasesEdit";

dayjs.extend(weekday);
dayjs.extend(localeData);

interface TaskFormProps {
  form: FormInstance;
  handleSubmit?: (values: CreateTaskPayload) => void;
  error?: string;
  cases?: Task["cases"];
  type?: "edit" | "create" | "view";
  caseStatusInitial?: CaseStatusEnum;
  task?: Task;
}

const { Option } = Select;

const TaskForm = ({
  form,
  handleSubmit,
  error,
  cases,
  type = "create",
  caseStatusInitial,
  task,
}: TaskFormProps) => {
  const { t } = useTranslation();
  const { taskStatuses, getStatusesLoading: getTaskStatusesLoading } =
    useTaskStatuses();
  const { statuses, loading: getCaseStatusesLoading } = useCaseStatuses();
  const [couriers, setCouriers] = useState<CouriersAvailability[]>();
  const [noCasesError, setNoCasesError] = useState(false);

  const [editableCases, setEditableCases] = useState<Task["cases"]>(
    cases || []
  );

  const clinic = editableCases[0]?.case.client;

  useEffect(() => {
    if (type === "view" && task) {
      form.setFieldsValue({
        ...task,
        trackingNumber: task.trackingNumber,
        description: task.description,
        taskStatusUUID: task.taskStatus.taskStatusUUID,
        courierUUID: task.courier.courierUUID,
        dueDate: task.dueDate && dayjs(new Date(task.dueDate)),
        dueDateTime: task.dueDate.slice(11),
      });
    }
  }, [task, form]);

  useEffect(() => {
    (async () => {
      try {
        const data = await makeRequest<CouriersAvailability[]>({
          url: apiBaseurl + "/couriers-availability",
        });
        setCouriers(data);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  useEffect(() => {
    if (editableCases.length) {
      form.setFieldValue("clientName", editableCases[0].case.client.name);
      form.setFieldValue(
        "caseStatusUUID",
        editableCases[0].case.status?.caseStatusUUID
      );
    }
  }, [editableCases, form]);

  const submit = (values: any) => {
    if (cases) {
      setNoCasesError(false);
      if (!editableCases.length) {
        setNoCasesError(true);
        return;
      }
    }

    handleSubmit?.({
      ...values,
      clientUUID: clinic?.clientUUID,
      completionDate: values.completionDate
        ? formatDateTime(values.completionDate, values.completionDateTime)
        : null,
      description: values.description || "",
      trackingNumber: values.trackingNumber || "",
      dueDate: formatDateTime(values.dueDate, values.dueDateTime),
      cases: cases
        ? editableCases.map((c) => ({
            caseUUID: c.case.caseUUID,
            use: c.use,
          }))
        : undefined,
      attachments: [],
    });
  };

  useEffect(() => {
    if (caseStatusInitial) {
      form.setFieldsValue({ caseStatusUUID: caseStatusInitial });
    }
  }, [caseStatusInitial, form]);

  return (
    <>
      {type === "view" && (
        <div
          style={{ fontWeight: "600", fontSize: 18, marginBottom: 15 }}
        >{`${t("Завдання №")} ${task?.taskNumber}`}</div>
      )}
      <Form
        labelCol={{ span: 7 }}
        requiredMark={false}
        form={form}
        onFinish={submit}
        initialValues={{
          caseStatusUUID: CaseStatusEnum["New"] || caseStatusInitial,
          taskStatusUUID: TaskStatusEnum["New"],
        }}
        disabled={type === "view"}
      >
        {cases && (
          <div style={{ width: "100%", marginBottom: 20 }}>
            <div style={{ fontWeight: "500", marginBottom: 10 }}>
              {t("Замовлення")}:
            </div>
            <TaskCasesEdit
              allCases={cases}
              editableCases={editableCases}
              setEditableCases={setEditableCases}
            />
          </div>
        )}

        <Form.Item name="clientName" label={t("Контрагент")}>
          <Input disabled />
        </Form.Item>

        <Form.Item
          name="courierUUID"
          label={t("Кур'єр")}
          rules={[
            {
              required: true,
              message: t("Заповніть поле") as string,
            },
          ]}
        >
          <Select>
            {couriers?.map((courier) => (
              <Option
                key={courier.courierUUID.courierUUID}
                value={courier.courierUUID.courierUUID}
              >
                <div className={styles.courierInput}>
                  {courier.courierAvailabilityStatus
                    .courierAvailabilityStatusUUID ===
                  CouriersAvailabilityStatusEnum.Available ? (
                    <div
                      className={styles.status}
                      style={{ backgroundColor: "green" }}
                    ></div>
                  ) : (
                    <div
                      className={styles.status}
                      style={{ backgroundColor: "red" }}
                    ></div>
                  )}
                  {courier.courierUUID.name || " "}
                </div>
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="caseStatusUUID"
          label={t("Статус замовлення")}
          rules={[{ required: true, message: t("Заповніть поле") as string }]}
        >
          <Select loading={getCaseStatusesLoading}>
            {statuses.map((status) => (
              <Option key={status.caseStatusUUID} value={status.caseStatusUUID}>
                {status.name || " "}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="taskStatusUUID"
          label={t("Статус завдання")}
          rules={[{ required: true, message: t("Заповніть поле") as string }]}
        >
          <Select loading={getTaskStatusesLoading}>
            {taskStatuses.map((status) => (
              <Option key={status.taskStatusUUID} value={status.taskStatusUUID}>
                {status.name || " "}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item name="description" label={t("Опис")}>
          <TextArea placeholder={t("Введіть значення") as string} />
        </Form.Item>

        <Form.Item name="trackingNumber" label={t("Номер відстеження")}>
          <Input placeholder={t("Введіть значення") as string} />
        </Form.Item>

        <Form.Item label={t("Дата завершення")} style={{ marginBottom: 0 }}>
          <Form.Item
            name="dueDate"
            rules={[
              {
                required: true,
                message: t("Введіть дату") as string,
              },
            ]}
            style={{ display: "inline-block", width: "calc(50% - 8px)" }}
          >
            <DatePicker
              format="YYYY.MM.DD"
              placeholder={t("Виберіть дату") as string}
            />
          </Form.Item>
          <Form.Item
            label={t("Час")}
            name="dueDateTime"
            style={{
              display: "inline-block",
              width: 130,
              margin: "0 8px",
            }}
          >
            <MaskedInput mask={timeMask} disabled={type === "view"} />
          </Form.Item>
        </Form.Item>

        {type === "edit" && (
          <Form.Item label={t("Дата виконання")} style={{ marginBottom: 0 }}>
            <Form.Item
              name="completionDate"
              style={{ display: "inline-block", width: "calc(50% - 8px)" }}
            >
              <DatePicker
                format="YYYY.MM.DD"
                placeholder={t("Виберіть дату") as string}
              />
            </Form.Item>
            <Form.Item
              label={t("Час")}
              name="completionDateTime"
              style={{
                display: "inline-block",
                width: 130,
                margin: "0 8px",
              }}
            >
              <MaskedInput mask={timeMask} />
            </Form.Item>
          </Form.Item>
        )}

        {(!!error || noCasesError) && (
          <Alert
            style={{ marginBottom: 20 }}
            message={(noCasesError && t("Оберіть замовлення")) || error}
            type="error"
          />
        )}
      </Form>
    </>
  );
};

export default TaskForm;
