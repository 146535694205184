import { useTranslation } from "react-i18next";
import { Alert, Button, Checkbox, Divider, Select, Space, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { Task } from "@/root/models/task";
import TaskPhotos, { TaskPhoto } from "@/modules/Tasks/components/TaskPhotos";
import useCaseStatuses from "@/hooks/useCaseStatuses";
import TaskCasesEdit from "@/modules/Tasks/components/TaskCasesEdit";

interface CasesProps {
  task: Task;
  isEditing: boolean;
  editLoading: boolean;
  editError: string;
  editableCases: Task["cases"];
  setCaseStatus: (status: string) => void;
  setEditableCases: (cases: Task["cases"]) => void;
  photos: TaskPhoto[];
  setPhotos: (photos: TaskPhoto[]) => void;
  onCancel: () => void;
  onSave: () => void;
  setIsEditing: (v: boolean) => void;
}

const { Option } = Select;

const TaskAttachments = ({
  task,
  isEditing,
  editableCases,
  photos,
  editLoading,
  editError,
  setCaseStatus,
  setEditableCases,
  setIsEditing,
  setPhotos,
  onCancel,
  onSave,
}: CasesProps) => {
  const { t } = useTranslation();

  const { statuses, loading } = useCaseStatuses();

  const presentationColumns: ColumnsType<{ case: Task.Case; use: boolean }> = [
    {
      key: "number",
      dataIndex: "case",
      title: t("Номер"),
      render: (c: Task.Case) => c.caseNumber,
    },
    {
      key: "status",
      dataIndex: "case",
      title: t("Статус"),
      render: (c: Task.Case) => c.status?.name,
    },
    {
      key: "used",
      dataIndex: "use",
      title: t("Використано"),
      render: (used: boolean) => <Checkbox checked={used} disabled />,
    },
  ];

  const handleCancel = () => {
    setIsEditing(false);
    onCancel();
  };

  const handleSave = () => {
    setIsEditing(false);
    onSave();
  };

  return (
    <>
      {isEditing ? (
        <>
          <Space direction="vertical" style={{ width: "100%", marginTop: 8 }}>
            <Select
              style={{ width: "100%", padding: "0 10px" }}
              placeholder={t("Статус замовлень")}
              loading={loading}
              onSelect={setCaseStatus}
            >
              {statuses.map((status) => (
                <Option
                  key={status.caseStatusUUID}
                  value={status.caseStatusUUID}
                >
                  {status.name}
                </Option>
              ))}
            </Select>
            <TaskCasesEdit
              withScan
              allCases={task.cases}
              editableCases={editableCases}
              setEditableCases={setEditableCases}
            />
            <Divider style={{ margin: "10px 0" }} />
            <TaskPhotos
              taskUUID={task.taskUUID}
              photos={photos}
              setPhotos={setPhotos}
              takePhotoButton
              opened
            />
            <Divider style={{ margin: "10px 0" }} />
            {!!editError && (
              <Alert
                style={{ margin: "0 10px" }}
                message={editError}
                type="error"
              />
            )}
            <div
              style={{
                display: "flex",
                gap: 10,
                width: "100%",
                padding: "0 10px",
                marginBottom: 8,
              }}
            >
              <Button
                disabled={editLoading}
                type="primary"
                style={{ flexGrow: 1 }}
                onClick={handleCancel}
              >
                {t("Скасувати")}
              </Button>
              <Button
                loading={editLoading}
                type="primary"
                style={{ flexGrow: 1 }}
                onClick={handleSave}
              >
                {t("Зберегти")}
              </Button>
            </div>
          </Space>
        </>
      ) : (
        <>
          <Space direction="vertical" style={{ width: "100%" }}>
            <Table
              pagination={false}
              dataSource={task.cases.map((c) => ({
                ...c,
                key: c.case.caseUUID,
              }))}
              columns={presentationColumns}
            />
            <Divider style={{ margin: "10px 0" }} />
            <TaskPhotos
              taskUUID={task.taskUUID}
              photos={photos}
              setPhotos={setPhotos}
              takePhotoButton
              presentation={!isEditing}
              opened
            />
            <Divider style={{ margin: "10px 0" }} />
            <div style={{ width: "100%", padding: "0 10px" }}>
              <Button
                style={{ width: "100%" }}
                type="primary"
                onClick={() => setIsEditing(true)}
              >
                {t("Редагувати завдання")}
              </Button>
            </div>
          </Space>
          <Divider />
        </>
      )}
    </>
  );
};

export default TaskAttachments;
