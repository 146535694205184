import { Route, Routes } from "react-router-dom";
import MakeInvoice from "./pages/MakeInvoice";
import EditInvoice from "./pages/EditInvoice";
import Invoices from "./pages/Invoices";

const InvoicesPanel = () => {
  return (
    <Routes>
      <Route path="create" element={<MakeInvoice />} />
      <Route path="edit" element={<EditInvoice />} />
      <Route path="*" element={<Invoices />} />
    </Routes>
  );
};

export default InvoicesPanel;
