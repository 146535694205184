import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Button, Collapse, Dropdown, Select, Space } from "antd";
import {
  CaretRightOutlined,
  LogoutOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";

import styles from "./CourierTasks.module.scss";

import useTasks from "@/hooks/useTasks";
import { Block, Loader, Pagination } from "@/components/common";
import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import { logout } from "@/store/reducers/user/asyncActionCreators";
import { pages } from "@/root/consts";
import { CouriersAvailabilityStatusEnum, TaskStatusEnum } from "@/root/types";
import { AvailabilityStatus, Task } from "@/modules/CourierTasks/components";
import useCouriersAvailability from "@/hooks/useCouriersAvailability";

const { Panel } = Collapse;
const { Option } = Select;

const CourierTasks = () => {
  const dispatch = useAppDispatch();
  const courierUUID = useAppSelector(
    (state) => state.userReducer.user?.userUUID
  );
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const {
    tasks,
    pages: pageCount,
    getTasksLoading,
  } = useTasks({ page, pageLimit: 25 });
  const [dateSort, setDateSort] = useState("asc");
  const [status, setStatus] = useState<string[]>([
    TaskStatusEnum["New"],
    TaskStatusEnum["In progress"],
  ]);
  const {
    couriersAvailabilities,
    editCouriersAvailability,
    getCouriersAvailabilitiesLoading,
    editLoading,
  } = useCouriersAvailability({ courierUUID });
  const available =
    couriersAvailabilities?.[0]?.courierAvailabilityStatus
      .courierAvailabilityStatusUUID ===
    CouriersAvailabilityStatusEnum.Available;

  const filteredByStatus = tasks.filter((task) => {
    if (!status.length) {
      return true;
    }

    if (!task.taskStatus) {
      return false;
    }

    return status.includes(task.taskStatus.taskStatusUUID);
  });

  const sortedByDate = filteredByStatus
    .slice()
    .sort(
      (a, b) => new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime()
    );

  const handleLogout = () => {
    dispatch(logout());
    navigate(pages.login.path);
  };

  const redirectToInfo = () => {
    navigate(pages.courier.info.path);
  };

  const handleSetAvailability = () => {
    if (!courierUUID) {
      return;
    }

    const courierAvailabilityStatusUUID = available
      ? CouriersAvailabilityStatusEnum.Unavailable
      : CouriersAvailabilityStatusEnum.Available;
    editCouriersAvailability({ courierUUID, courierAvailabilityStatusUUID });
  };

  const menuItems = [
    {
      key: "getInfo",
      label: <Button type="link">{t("Отримати інформацію...")}</Button>,
      onClick: redirectToInfo,
    },
    {
      key: "logout",
      label: (
        <Button type="link" icon={<LogoutOutlined />}>
          {t("Вийти")}
        </Button>
      ),
      onClick: handleLogout,
    },
  ];

  available &&
    menuItems.unshift({
      key: "setStatus",
      label: (
        <Button type="link">{t('Встановити статус "Недоступний"')}</Button>
      ),
      onClick: handleSetAvailability,
    });

  const paginationRange = Math.floor(
    (window.innerWidth - 8 - 30 - 39 * 4) / 39
  );

  return (
    <>
      {getTasksLoading ? (
        <div style={{ height: "100vh" }}>
          <Loader center />
        </div>
      ) : (
        <div className={styles.container}>
          {!getCouriersAvailabilitiesLoading && !editLoading && !available && (
            <AvailabilityStatus
              available={available}
              setAvailable={handleSetAvailability}
            />
          )}
          <div className={styles.nav}>
            <Dropdown
              trigger={["click"]}
              menu={{
                items: menuItems,
              }}
            >
              <MenuUnfoldOutlined style={{ fontSize: 26 }} />
            </Dropdown>
            <Space size="small">
              {t("Сортувати за") + ":"}
              <Select
                style={{ minWidth: 100 }}
                value={dateSort}
                onSelect={setDateSort}
              >
                <Option value="desc">{t("За спаданням")}</Option>
                <Option value="asc">{t("За зростанням")}</Option>
              </Select>
            </Space>
            <Space size="small">
              {t("Статус") + ":"}
              <Select
                mode="multiple"
                style={{ minWidth: 110 }}
                value={status}
                onChange={setStatus}
              >
                <Option
                  style={{
                    background: "orange",
                    color: "rgba(255,255,255, .9)",
                  }}
                  value={TaskStatusEnum["New"]}
                >
                  {t("Нові")}
                </Option>
                <Option
                  style={{ background: "blue", color: "rgba(255,255,255, .9)" }}
                  value={TaskStatusEnum["In progress"]}
                >
                  {t("В процесі")}
                </Option>
                <Option
                  style={{
                    background: "green",
                    color: "rgba(255,255,255, .9)",
                  }}
                  value={TaskStatusEnum["Completed"]}
                >
                  {t("Завершені")}
                </Option>
              </Select>
            </Space>
          </div>
          <Collapse
            className={styles.collapse}
            bordered={false}
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 90 : 0} />
            )}
          >
            {(dateSort === "asc"
              ? sortedByDate
              : sortedByDate.slice().reverse()
            ).map((task) => (
              <Fragment key={task.taskNumber}>
                <Panel
                  key={task.taskNumber}
                  header={
                    <Space style={{ width: "100%" }} direction="vertical">
                      <div>
                        {task.dueDate && task.dueDate.slice(0, 16) + ", "}
                        {task.client.name && task.client.name + ", "}
                        {task.client.address && task.client.address}
                      </div>
                    </Space>
                  }
                >
                  <Task task={task} page={page} />
                </Panel>
              </Fragment>
            ))}
          </Collapse>
          <Block style={{ margin: "0 8px" }}>
            <Pagination
              currentPage={page}
              pages={pageCount || 1}
              paginationRange={paginationRange}
              setCurrentPage={setPage}
            />
          </Block>
        </div>
      )}
    </>
  );
};

export default CourierTasks;
