import { useTranslation } from "react-i18next";
import { Input, Select } from "antd";

const { Option } = Select;

interface CatalogSearchProps {
  onSearch: (value: string) => void;
}

const CatalogSearch = ({ onSearch }: CatalogSearchProps) => {
  const { t } = useTranslation();

  const selectAfter = (
    <Select defaultValue="catalog">
      <Option value="catalog">{t("Каталог")}</Option>
      <Option value="favorites">{t("Обране")}</Option>
    </Select>
  );

  return (
    <div>
      <Input
        //addonAfter={selectAfter}
        autoFocus
        onChange={(e) => onSearch(e.target.value)}
      />
    </div>
  );
};

export default CatalogSearch;
