import { Button } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import ReactPaginate from "react-paginate";

import styles from "./Pagination.module.scss";

interface PaginationProps {
  currentPage: number;
  pages: number;
  paginationRange: number;
  setCurrentPage: (page: number) => void;
}

const Pagination = ({
  currentPage,
  pages,
  paginationRange,
  setCurrentPage,
}: PaginationProps) => {
  return (
    <ReactPaginate
      className={styles.pagination}
      pageClassName={styles.paginationPage}
      activeLinkClassName={styles.paginationActive}
      breakLabel={
        <Button className={styles.paginationButton} type="text">
          ...
        </Button>
      }
      pageLabelBuilder={(page) => (
        <Button className={styles.paginationButton} type="text">
          {page}
        </Button>
      )}
      previousLabel={
        <Button
          className={styles.paginationButton}
          type="text"
          icon={<LeftOutlined style={{ color: "rgba(0, 0, 0, 0.25)" }} />}
        />
      }
      nextLabel={
        <Button
          className={styles.paginationButton}
          type="text"
          icon={<RightOutlined style={{ color: "rgba(0, 0, 0, 0.25)" }} />}
        />
      }
      forcePage={currentPage - 1}
      onPageChange={({ selected }) => setCurrentPage(selected + 1)}
      pageRangeDisplayed={paginationRange}
      marginPagesDisplayed={1}
      pageCount={pages}
    />
  );
};

export default Pagination;
