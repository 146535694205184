import { useTranslation } from "react-i18next";

import styles from "./PrintPricelist.module.scss";

import { ClientPricelist, Pricelist } from "@/root/models/pricelist";
import {
  ExtraPricelist,
  ProductPricelist,
} from "@/components/PricelistForm/componenets";
import { Print } from "@/components/common";
import { PricelistTypeEnum } from "@/components/PricelistForm";

interface PrintPricelistProps {
  pricelist: ClientPricelist | Pricelist;
}

const PrintPricelist = ({ pricelist }: PrintPricelistProps) => {
  const { t } = useTranslation();
  const listItems = (pricelist as ClientPricelist).list.map((item) => ({
    ...item,
    itemUUID:
      "productUUID" in item.item ? item.item.productUUID : item.item.extraUUID,
  }));

  const isClientEmployee = "clientsPricelistUUID" in pricelist;

  return (
    <Print>
      <div className={styles.container}>
        <div>
          <span>{t("Номер")}</span>: {pricelist.number}
        </div>
        {isClientEmployee && (
          <div>
            <span>{t("Контрагент")}</span>: {pricelist.client.name}
          </div>
        )}
        <div>
          <span>{t("Дата активації")}</span>: {pricelist.date}
        </div>
        <div>
          <span>{t("Тип")}</span>: {pricelist.pricelistType.name}
        </div>
        <div>
          <span>{t("Опис")}</span>: {pricelist.description}
        </div>
        <div style={{ marginTop: 20 }}>
          {pricelist.pricelistType.pricelistTypeUUID ===
            PricelistTypeEnum.ProductsCatalog && (
            <ProductPricelist
              productPrices={listItems}
              setProductPrices={() => {}}
              presentation={true}
            />
          )}

          {pricelist.pricelistType.pricelistTypeUUID ===
            PricelistTypeEnum.Extras && (
            <ExtraPricelist
              extraPrices={listItems}
              setExtraPrices={() => {}}
              presentation={true}
            />
          )}
        </div>
      </div>
    </Print>
  );
};

export default PrintPricelist;
