import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Modal } from "antd";
import { useForm } from "antd/es/form/Form";
import {
  CreateProductParameterPayload,
  ProductsParametersApi,
} from "@/api/admin/productsParameters";
import { ProductParameter } from "@/root/models/productParameter";
import ProductParameterForm from "./ProductParameterForm";

interface CreateParameterProps {
  onCreate: (parameter: ProductParameter) => void;
}

const CreateParameter = ({ onCreate }: CreateParameterProps) => {
  const { t } = useTranslation();
  const [form] = useForm();
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleCreate = async (values: CreateProductParameterPayload) => {
    try {
      setError("");
      setLoading(true);
      const data = {
        ...values,
        defaultValue: values.defaultValue || "",
        list: values.list
          ? values.list.map((i) => ({
              value: i.value,
              filtersValues: i.filtersValues || [],
            }))
          : [],
        filtersValues: values.filtersValues || [],
      };
      const parameter = await ProductsParametersApi.create(data);
      onCreate(parameter);
      setModal(false);
      form.resetFields();
    } catch (e: any) {
      setError(e.message);
    } finally {
      setLoading(false);
    }
  };

  const submit = () => {
    form.submit();
  };

  return (
    <div>
      <Button onClick={() => setModal(true)}>{t("Створити параметр")}</Button>
      <Modal
        maskClosable={false}
        okText={t("Створити")}
        title={
          <div style={{ marginBottom: 20 }}>{t("Створення параметра")}</div>
        }
        open={modal}
        onCancel={() => setModal(false)}
        onOk={submit}
        okButtonProps={{ loading }}
      >
        <ProductParameterForm
          form={form}
          handleSubmit={handleCreate}
          error={error}
        />
      </Modal>
    </div>
  );
};

export default CreateParameter;
