import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Modal } from "antd";
import { useForm } from "antd/es/form/Form";

import {
  CreateProductsParametersGroupPayload,
  ProductsParametersGroupApi,
} from "@/api/productsParametersGroup";
import { ParametersGroup } from "@/root/models/parameterGroup";
import ProductsParametersGroupForm from "./ProductsParametersGroupForm";

interface CreateGroupProps {
  onCreate: (group: ParametersGroup) => void;
}

const CreateGroup = ({ onCreate }: CreateGroupProps) => {
  const { t } = useTranslation();
  const [form] = useForm();
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleCreate = async (values: CreateProductsParametersGroupPayload) => {
    try {
      setLoading(true);
      const client = await ProductsParametersGroupApi.create(values);
      onCreate(client);
      setModal(false);
      form.resetFields();
    } catch (e: any) {
      setError(e.message);
    } finally {
      setLoading(false);
    }
  };

  const submit = () => {
    form.submit();
  };

  return (
    <div>
      <Button onClick={() => setModal(true)}>{t("Створити групу")}</Button>
      <Modal
        maskClosable={false}
        okText={t("Створити")}
        title={<div style={{ marginBottom: 20 }}>{t("Створення групи")}</div>}
        open={modal}
        onCancel={() => setModal(false)}
        onOk={submit}
        okButtonProps={{ loading }}
      >
        <ProductsParametersGroupForm
          form={form}
          error={error}
          onSubmit={handleCreate}
        />
      </Modal>
    </div>
  );
};

export default CreateGroup;
