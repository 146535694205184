import { apiBaseurl } from "@/api/baseurl";
import { InfoProps } from "@/hooks/useInfo";

interface PrintHeaderProps {
  info: InfoProps | undefined;
}

const PrintHeader = ({ info }: PrintHeaderProps) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginTop: 10,
      }}
    >
      <div style={{ width: 200, height: 100 }}>
        <img
          src={apiBaseurl + "/logo"}
          onError={(e) => {
            // @ts-ignore
            e.target.style.display = "none";
          }}
          style={{
            width: "100%",
            height: "100%",
            objectPosition: "left",
            objectFit: "contain",
          }}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          textAlign: "right",
          gap: 10,
        }}
      >
        <div style={{ fontSize: 16 }}>{info?.welcomeText}</div>
        <div style={{ fontSize: 16 }}>{info?.phones}</div>
      </div>
    </div>
  );
};

export default PrintHeader;
