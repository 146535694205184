import { UnpaidCasesApi } from "@/api/unpaidCases";
import { GroupedInvoiceCase, Invoice } from "@/root/models/invoice";
import { useEffect, useState } from "react";

const useInvoiсesTable = (
  invoiceCases: Invoice.InvoiceCase[],
  chosenClient: string
) => {
  //First table
  const [invoiceProducts, setInvoiceProducts] = useState<GroupedInvoiceCase[]>(
    []
  );

  //Second table
  const [unpaidInvoiceCases, setUnpaidInvoiceCases] =
    useState<GroupedInvoiceCase[]>();

  const [loading, setLoading] = useState(false);

  //If we already have created invoice we are getting in Invoice View another data structure so we have to use this function to modify cases
  //Group invoiceCases by same caseNumber
  const groupObjectsByCaseNumber = (
    array: Invoice.InvoiceCase[],
    setValue: (cases: any) => void
  ) => {
    const grouped = new Map<string, any>();

    for (const item of array) {
      const key = item.case["caseNumber"];

      if (!grouped.has(key)) {
        grouped.set(key, { case: item.case, children: [] });
      }

      const group = grouped.get(key);
      group.children.push({
        caseUUID: item.case.caseUUID,
        invoices: item.invoices,
        item: item.item,
        itemType: item.itemType,
        rowUUID: item.rowUUID,
        sum: item.sum,
      });
    }

    setValue(Array.from(grouped.values()));
  };

  //If Invoice is already created (In Invoice View)
  useEffect(() => {
    if (!!invoiceCases.length) {
      groupObjectsByCaseNumber(invoiceCases, setInvoiceProducts);
    }
  }, [invoiceCases]);

  //Find unpaid cases for certain clinic if Dental Clinic field is filled (In Invoice Create)
  useEffect(() => {
    (async () => {
      try {
        if (chosenClient) {
          setLoading(true);
          const unpaidCases = await UnpaidCasesApi.getUnpaidCases(chosenClient);

          if (!!unpaidCases.length && !invoiceCases.length) {
            groupObjectsByCaseNumber(unpaidCases, setUnpaidInvoiceCases);
          } else {
            setUnpaidInvoiceCases([]);
          }
          //If case is already added to invoice, remove it from unpaid cases list
          if (
            !!invoiceCases.length &&
            unpaidCases?.some(
              (elem) =>
                !invoiceCases.some((item) => item.rowUUID === elem.rowUUID)
            )
          ) {
            const casesUnpaid = unpaidCases?.filter(
              (elem) =>
                !invoiceCases.some((item) => item.rowUUID === elem.rowUUID)
            );
            groupObjectsByCaseNumber(casesUnpaid, setUnpaidInvoiceCases);
          }
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [chosenClient]);

  return {
    //First table
    invoiceProducts,
    setInvoiceProducts,

    //Second table
    unpaidInvoiceCases,

    loading,
  };
};

export default useInvoiсesTable;
