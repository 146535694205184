import styles from "./Detailes.module.scss";

import { Block } from "@/components/common";
import {
  CaseDate,
  DueDate,
  SearchClientEmployee,
  SearchPatient,
} from "@/components/CaseForm/components";
import {
  ActionCreatorTypes,
  useCaseFormContext,
} from "@/components/CaseForm/CaseFormContext";

const Details = () => {
  const { state, dispatch } = useCaseFormContext();

  const handleClick = () => {
    if (!state.onlyDetailsEdit) {
      dispatch({
        type: ActionCreatorTypes.SetOnlyDetailsEdit,
        payload: true,
      });
    }
  };

  return (
    <div className={styles.container}>
      <Block className={styles.details} onClick={handleClick}>
        {state.onlyDetailsEdit ? (
          <>
            <div style={{ display: "flex", gap: 10 }}>
              <SearchPatient />
              <CaseDate />
            </div>
            <div style={{ display: "flex", gap: 10 }}>
              <SearchClientEmployee />
              <DueDate />
            </div>

            {/* <div
              style={{
                display: "flex",
                width: "100%",
                flexDirection: "column",
                gap: 10,
              }}
            >
              <SelectTeethFormulaType />
              <SelectTeethFormulaNumbering />
            </div> */}
          </>
        ) : (
          <SearchPatient />
        )}
      </Block>
    </div>
  );
};

export default Details;
