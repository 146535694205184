import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import CreateClientEmployeeModal from "./CreateClientEmployeeModal";

interface CreateClientEmployeeProps {
  clientUUID: string;
}

const CreateClientEmployee = ({ clientUUID }: CreateClientEmployeeProps) => {
  const { t } = useTranslation();
  const [modal, setModal] = useState(false);

  return (
    <div>
      <Button onClick={() => setModal(true)}>
        {t("Створити співробітника")}
      </Button>
      <CreateClientEmployeeModal
        open={modal}
        onCancel={() => setModal(false)}
        clientUUID={clientUUID}
      />
    </div>
  );
};

export default CreateClientEmployee;
