import { DATE_FORMAT } from "@/root/consts";
import { GroupedInvoiceCase } from "@/root/models/invoice";
import { Checkbox, DatePicker, Form, Input } from "antd";
import { useForm } from "antd/es/form/Form";
import dayjs from "dayjs";
import { t } from "i18next";
import { useEffect, useState } from "react";

const { RangePicker } = DatePicker;

interface UnpaidCasesFilterFormProps {
  onFilter: (filteredArray: GroupedInvoiceCase[]) => void;
  unpaidInvoiceCases: GroupedInvoiceCase[] | undefined;
}

const UnpaidCasesFilterForm = ({
  onFilter,
  unpaidInvoiceCases,
}: UnpaidCasesFilterFormProps) => {
  const [form] = useForm();

  const [caseNumber, setCaseNumber] = useState("");
  const [date, setDate] = useState<string>();
  const [isItemInInvoice, setIsItemInInvoice] = useState(false);

  const separateDate = date?.split(",");

  useEffect(() => {
    const handleFilterSubmit = () => {
      // Filter the data based on the input
      if (!!unpaidInvoiceCases?.length) {
        const filteredData = unpaidInvoiceCases
          ?.map((elem) =>
            isItemInInvoice
              ? {
                  ...elem,
                  children: elem.children.filter(
                    (child) => !child.invoices.length
                  ),
                }
              : elem
          )
          .filter(
            (item) =>
              (separateDate
                ? item.case.arrival >= separateDate[0] &&
                  item.case.arrival <= separateDate[1]
                : true) &&
              (caseNumber ? item.case.caseNumber.includes(caseNumber) : true)
          );

        filteredData && onFilter(filteredData);
      } else {
        onFilter([]);
      }
    };

    handleFilterSubmit();
  }, [unpaidInvoiceCases, caseNumber, isItemInInvoice, date]);

  return (
    <Form
      requiredMark={false}
      form={form}
      style={{ display: "flex", gap: 20, marginTop: 35 }}
    >
      <Form.Item name="date" label={t("Дата_one")}>
        <RangePicker
          format={DATE_FORMAT}
          //@ts-ignore
          onChange={(value: RangeValue<dayjs.Dayjs>) =>
            setDate(
              value
                ?.map((d: string) => dayjs(new Date(d)).format(DATE_FORMAT))
                ?.join(",") || undefined
            )
          }
        />
      </Form.Item>
      <Form.Item
        name="invoicePaid"
        label={t("Сховати вже включені до рахунку-фактури")}
      >
        <Checkbox onChange={(e) => setIsItemInInvoice(e.target.checked)} />
      </Form.Item>
      <Form.Item name="caseNumber" label={t("Номер замовлення")}>
        <Input onChange={(e) => setCaseNumber(e.target.value)} />
      </Form.Item>
    </Form>
  );
};

export default UnpaidCasesFilterForm;
