import { useState } from "react";
import { useTranslation } from "react-i18next";
import { QrReader } from "react-qr-reader";

import { Input, Modal, Select } from "antd";
import { CameraOutlined } from "@ant-design/icons";

import styles from "./Search.module.scss";

export type SearchType = "cases" | "tasks";

interface SearchProps {
  onSearch: (values: { value: string; type: SearchType }) => void;
}

const { Search: SearchInput } = Input;
const { Option } = Select;

const Search = ({ onSearch }: SearchProps) => {
  const [value, setValue] = useState("");
  const [type, setType] = useState<SearchType>("tasks");
  const [qrReader, setQrReader] = useState(false);

  const handleCameraClick = () => {
    setQrReader(true);
  };

  const handleSearch = () => {
    onSearch({ value, type });
  };

  const handleQrRead = (value: string) => {
    setQrReader(false);
    setValue(value);
    onSearch({ value, type });
  };

  return (
    <>
      <SearchInput
        className={styles.search}
        suffix={
          <SearchSuffix
            type={type}
            setType={setType}
            onCameraClick={handleCameraClick}
          />
        }
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onSearch={handleSearch}
      />
      <Modal
        destroyOnClose
        open={qrReader}
        onCancel={() => setQrReader(false)}
        okButtonProps={{ hidden: true }}
      >
        <QrReader
          constraints={{ facingMode: "environment" }}
          onResult={(result) =>
            result?.getText() && handleQrRead(result.getText())
          }
        />
      </Modal>
    </>
  );
};

interface SearchSuffixProps {
  type: SearchType;
  setType: (type: SearchType) => void;
  onCameraClick: () => void;
}

const SearchSuffix = ({ type, setType, onCameraClick }: SearchSuffixProps) => {
  const { t } = useTranslation();

  return (
    <>
      <CameraOutlined
        style={{
          fontSize: 20,
          color: "#1677ff",
        }}
        onClick={(e) => {
          e.stopPropagation();
          onCameraClick();
        }}
      />
      <Select
        value={type}
        onSelect={setType}
        style={{ fontSize: 16 }}
        onClick={(e) => e.stopPropagation()}
      >
        <Option value="tasks">{t("Завдання")}</Option>
        <Option value="cases">{t("Інвойс")}</Option>
      </Select>
    </>
  );
};

export default Search;
