import { useReducer, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Button, Form, Space } from "antd";
import { useForm } from "antd/es/form/Form";

import styles from "./CaseForm.module.scss";

import {
  ActionCreatorTypes,
  CaseFormContext,
  caseFormInitialState,
  caseFormReducer,
  CaseFormState,
} from "./CaseFormContext";
import { LeftPanel, MiddlePanel, Results } from "./components";
import { CreateCasePayload } from "@/api/client/cases";
import useFilters from "@/hooks/useFilters";
import formJobToJob from "./formJobToJob";
import { Print } from "@/components/common";
import { LaboratoryCase } from "@/components";
import { PrintCase } from "@/components/LaboratoryCase";
import { formJobsToCaseJobs } from "@/utils/formJobsToCaseJobs";
import useInfo from "@/hooks/useInfo";
import { generateFileName } from "@/utils/generateFileName";

interface CaseFormProps {
  initialState?: CaseFormState;
  loading: boolean;
  onFinish: (
    data: CreateCasePayload,
    close: boolean,
    type: "client" | "lab"
  ) => void;
  onCancel: () => void;
}

const CaseForm = ({
  onFinish,
  onCancel,
  initialState,
  loading,
}: CaseFormProps) => {
  const { t } = useTranslation();
  const { filters } = useFilters();
  const [state, dispatch] = useReducer(
    caseFormReducer,
    initialState || caseFormInitialState
  );

  const { info } = useInfo();

  const [form] = useForm();

  const checkError = () => {
    for (let job of state.jobs as CaseFormState.Job[]) {
      for (let product of job.products) {
        if (!product.productsParametersGroups) {
          return {
            jobID: job.jobID,
            product: product.jobProductUUID,
            message: t("Оберіть виріб"),
          };
        } else if (!product.teeth?.length) {
          return {
            jobID: job.jobID,
            product: product.jobProductUUID,
            message: t("Оберіть зуби"),
          };
        } else {
          for (let group of product.productsParametersGroups) {
            for (let parameter of group.productsParameters) {
              if (parameter.required && !parameter.value) {
                const toothText = parameter.tooth ? ` ${parameter.tooth}` : "";
                return {
                  jobID: job.jobID,
                  message: t("Заповніть обов'язкові параметри") + toothText,
                };
              }
            }
          }
        }
      }
    }
  };

  const handleFinish = async (close: boolean = true) => {
    dispatch({ type: ActionCreatorTypes.ClearErrors });

    await form.validateFields();

    const error = checkError();
    if (error) {
      dispatch({ type: ActionCreatorTypes.SetError, payload: error });
      return;
    }
    const data = {
      clientUUID: state.client?.clientUUID,
      clientsEmployeeUUID: state.clientsEmployee?.clientsEmployeeUUID || null,
      activeHandlerUUID: state.activeHandler?.activeHandlerUUID,
      patientUUID: state.patient?.patientUUID,
      description: state.description,
      accounting: state.accounting,
      teethFormulaNumberingUUID: state.teethFormulaNumberingUUID,
      teethFormulaTypeUUID: state.teethFormulaTypeUUID,
      arrival: state.arrival,
      dueDate: state.dueDate,
      caseStatusUUID: state.status?.caseStatusUUID,
      warrantyActivationDate: state.warrantyActivationDate,
      warrantyActivated: state.warrantyActivated,
      warrantyNumber: state.warrantyNumber,
      attachments: state.attachments.map((att) => ({
        ...att,
        description: state.description,
      })),
      jobs: state.jobs.map((job: CaseFormState.Job) =>
        formJobToJob(job, filters)
      ),
    } as CreateCasePayload;
    dispatch({ type: ActionCreatorTypes.SetSaveButtonActive, payload: false });
    onFinish(data, close, "client");
  };

  const printCase: PrintCase = {
    client: state.client || undefined,
    patient: state.patient || undefined,
    teethFormulaType: state.teethFormulaTypeUUID
      ? { teethFormulaTypeUUID: state.teethFormulaTypeUUID }
      : undefined,
    clientsEmployee: state.clientsEmployee || undefined,
    arrival: state.arrival,
    dueDate: state.dueDate,
    attachments: state.attachments?.length ? [] : undefined,
    caseNumber: state.caseNumber || undefined,
    description: state.description,
    jobs: formJobsToCaseJobs(state.jobs, filters),
  };

  return (
    <CaseFormContext.Provider value={{ state, dispatch }}>
      <Form
        form={form}
        className={styles.form}
        name="caseForm"
        requiredMark={false}
      >
        <div className={styles.actionButtons}>
          <div style={{ width: "35%" }}>
            <Button htmlType="button" onClick={onCancel}>
              {t("Назад")}
            </Button>
          </div>

          <Space>
            <Button
              htmlType="button"
              disabled={loading}
              onClick={() => {
                generateFileName(
                  "Case",
                  printCase.caseNumber as string,
                  printCase.arrival as string
                );

                window.print();
              }}
            >
              {t("Надрукувати замовлення")}
            </Button>
            <Button htmlType="button" disabled={loading} onClick={onCancel}>
              {t("Скасувати")}
            </Button>
            <Button
              disabled={!state.saveButtonActive}
              onClick={() => handleFinish(false)}
              loading={loading}
            >
              {t("Зберегти")}
            </Button>
            <Button onClick={() => handleFinish(true)} loading={loading}>
              {t("Зберегти і закрити")}
            </Button>
          </Space>
        </div>
        <div className={styles.container}>
          <LeftPanel />
          <MiddlePanel />
          <Results />
        </div>
      </Form>
      <Print>
        <LaboratoryCase
          medCase={printCase}
          teethFormulaNumberingUUID={state.teethFormulaNumberingUUID || ""}
          info={info}
        />
      </Print>
    </CaseFormContext.Provider>
  );
};

export default CaseForm;
