import { Case } from "@/root/models/case";
import { Patient } from "./../../root/models/patient";
import { createSlice } from "@reduxjs/toolkit";
import { CaseStatus } from "@/root/models/caseStatus";
import { ClientEmployee } from "@/root/models/clientEmployee";
import { Product } from "@/root/models/product";
import { AdminClient } from "@/root/models/adminClient";

interface InitialStateProps {
  patientItems: Patient[];
  caseItems: Case[];
  statusItems: CaseStatus[];
  doctorItems: ClientEmployee[];
  productItems: Product[];
  clientItems: AdminClient[];
}

const initialState: InitialStateProps = {
  patientItems: [],
  caseItems: [],
  statusItems: [],
  doctorItems: [],
  productItems: [],
  clientItems: [],
};

const chosenItemsForParamsSlice = createSlice({
  name: "chosenItems",
  initialState,
  reducers: {
    setPatientItems: (state, action) => {
      state.patientItems = action.payload;
    },
    setCaseItems: (state, action) => {
      state.caseItems = action.payload;
    },
    setStatusItems: (state, action) => {
      state.statusItems = action.payload;
    },
    setDoctorItems: (state, action) => {
      state.doctorItems = action.payload;
    },
    setProductItems: (state, action) => {
      state.productItems = action.payload;
    },
    setClientItems: (state, action) => {
      state.clientItems = action.payload;
    },
  },
});

export const {
  setPatientItems,
  setStatusItems,
  setDoctorItems,
  setProductItems,
  setCaseItems,
  setClientItems,
} = chosenItemsForParamsSlice.actions;

export default chosenItemsForParamsSlice.reducer;
