import { apiBaseurl } from "@/api/baseurl";
import makeRequest from "@/api/makeRequest";
import { useEffect, useState } from "react";

export interface InfoProps {
  welcomeText: string;
  phones: string;
}
const useInfo = () => {
  const [info, setInfo] = useState<InfoProps>();

  useEffect(() => {
    (async () => {
      try {
        const data = await makeRequest<InfoProps>({
          url: apiBaseurl + "/info",
        });

        setInfo(data);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  return { info };
};

export default useInfo;
