import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Tabs, TabsProps } from "antd";
import { SearchInput } from "@/components";
import ProductsPricelist from "./components/ProductsPricelist";
import ExtrasPricelist from "./components/ExtrasPricelist";

const PricelistClient = () => {
  const { t } = useTranslation();
  const [tabKey, setTabKey] = useState("products-pricelist");

  const items: TabsProps["items"] = [
    {
      key: "products-pricelist",
      label: t("Прайс лист виробів"),
      children: <ProductsPricelist />,
    },
    {
      key: "extras-pricelist",
      label: t("Прайс лист додаткових робіт і послуг"),
      children: <ExtrasPricelist />,
    },
  ];

  return (
    <div>
      <div style={{ marginBottom: 20 }}>
        <SearchInput />
      </div>
      <Tabs
        type="card"
        items={items}
        activeKey={tabKey}
        onTabClick={setTabKey}
      />
    </div>
  );
};

export default PricelistClient;
