import { useTranslation } from "react-i18next";
import { Button, Space } from "antd";
import { Loader } from "@/components/common";
import { ClientPricelist } from "@/root/models/pricelist";
import ActivePricelist from "@/modules/PricelistPanel/components/ClientPricelists/components/ActivePricelist";
import { PrintPricelist } from "@/modules/PricelistPanel/components";

interface PricelistProps {
  pricelist: ClientPricelist | {} | undefined;
  loading: boolean;
}

const ReportsPanelPricelist = ({ pricelist, loading }: PricelistProps) => {
  const { t } = useTranslation();

  if (!loading && (!pricelist || !Object.keys(pricelist).length)) {
    return <div>{t("Прайс лист відсутній")}</div>;
  }

  return (
    <Space direction="vertical">
      <Button onClick={() => window.print()}>{t("Друк")}</Button>
      {pricelist ? (
        <>
          <ActivePricelist
            pricelist={pricelist as ClientPricelist}
            presentation
          />
          <PrintPricelist pricelist={pricelist as ClientPricelist} />
        </>
      ) : (
        loading && <Loader />
      )}
    </Space>
  );
};

export default ReportsPanelPricelist;
