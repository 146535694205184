import { useMutation, useQueryClient } from "@tanstack/react-query";
import { CreatePanPayload, PansApi } from "@/api/admin/pans";
import { Pan } from "@/root/models/pan";

const useAddPan = (onSuccess: () => void) => {
  const queryClient = useQueryClient();
  const createPanMutation = useMutation({
    mutationFn: PansApi.create,
    onSuccess: (pan) => {
      queryClient.setQueryData(["pans"], (prev: Pan[] | undefined) => {
        if (!prev) {
          return [pan];
        }
        return [...prev, pan];
      });
      onSuccess();
    },
  });

  const addPan = (payload: CreatePanPayload) => {
    createPanMutation.mutate(payload);
  };

  const error = (createPanMutation.error as Error)?.message as string;

  return { addPan, loading: createPanMutation.isLoading, error };
};

export default useAddPan;
