import { InvoicesApi } from "@/api/invoices";
import {
  Invoice,
  ModifiedInvoiceWithItem,
  ModifiedInvoiceWithItems,
} from "@/root/models/invoice";
import { Payment } from "@/root/models/payment";
import { useEffect, useState } from "react";

const usePaymentTables = (
  paymentItems: Payment.Items[],
  chosenClient: string
) => {
  // First table
  const [paymentInvoices, setPaymentInvoices] = useState<
    ModifiedInvoiceWithItems[]
  >([]);

  //Second table
  const [unpaidInvoices, setUnpaidInvoices] =
    useState<ModifiedInvoiceWithItems[]>();

  const [loading, setLoading] = useState(false);

  //Modifying invoices in PaymentCreate
  const getCombinedInvoices = (
    array: Invoice[],
    setValue: (items: ModifiedInvoiceWithItems[]) => void
  ) => {
    const combinedInvoices = array?.map((invoice) => {
      const combinedCases: { [key: string]: any } = {};

      invoice.cases.map((caseItem) => {
        const { caseNumber, caseUUID, clientsEmployee, patient } =
          caseItem.case;
        const { sum } = caseItem;

        const modifiedInvoiceCases = invoice.cases
          .filter((elem) => elem.case.caseNumber === caseNumber)
          .map((item) => ({
            rowUUID: item.rowUUID,
            sum: item.sum,
            item: { name: item.item?.name, itemUUID: item.item?.itemUUID },
            itemType: item.itemType,
          }));

        if (combinedCases[caseNumber]) {
          combinedCases[caseNumber].sum += sum;
          combinedCases[caseNumber].items = modifiedInvoiceCases;
        } else {
          combinedCases[caseNumber] = {
            case: {
              caseNumber,
              caseUUID,
              clientsEmployee,
              patient: patient,
            },
            sum,
            items: modifiedInvoiceCases,
          };
        }

        return modifiedInvoiceCases;
      });

      const { cases, ...modifiedInvoice } = invoice;

      return {
        ...modifiedInvoice,
        children: Object.values(combinedCases),
      };
    });

    setValue(combinedInvoices);
  };

  //Find unpaid invoices dependes on chosen clinic
  useEffect(() => {
    (async () => {
      try {
        if (chosenClient) {
          setLoading(true);
          const invoices = await InvoicesApi.getInvoices({
            page: 1,
            clientUUID: chosenClient,
            paid: false,
          });

          getCombinedInvoices(invoices.data, setUnpaidInvoices);
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    })();
  }, [chosenClient]);

  //If we already have created payment we are getting in Payment View another data structure so we have to use another function to modify invoices
  //Group invoiceCases by same invoiceNumber
  const groupObjectsByInvoiceNumber = (
    array: Payment.Items[],
    setValue: (items: any) => void
  ) => {
    const grouped = new Map<string, any>();

    for (const item of array) {
      const key = item.invoice["invoiceNumber"];

      if (!grouped.has(key)) {
        grouped.set(key, {
          invoiceUUID: item.invoice.invoiceUUID,
          invoiceNumber: item.invoice.invoiceNumber,
          description: "",
          amount: "",
          paid: item.invoice.paid,
          date: item.invoice.date,
          children: [],
        });
      }

      const group = grouped.get(key);

      group.children.push({
        case: {
          caseNumber: item.case.caseNumber,
          caseUUID: item.case.caseUUID,
          clientsEmployee: {
            clientsEmployeeUUID:
              item.case?.clientsEmployee?.clientsEmployeeUUID,
            name: item.case?.clientsEmployee?.name,
          },
          patient: {
            patientUUID: item.case?.patient?.patientUUID,
            name: item.case?.patient?.name,
          },
        },
        sum: item.sum,
        items: {
          rowUUID: item.rowUUID,
          sum: item.sum,
          item: { name: item.item?.name, itemUUID: item.item?.itemUUID },
          itemType: item.itemType,
        },
      });
    }
    //As soon as we have grouped by invoiceNumber we have to groupe items inside invoices by same caseNumber
    const combinedByInvoiceNumberArray = Array.from(
      grouped.values()
    ) as ModifiedInvoiceWithItem[];

    const combinedItems: any = combinedByInvoiceNumberArray?.map((elem) => {
      const combinedCases: { [key: string]: any } = {};

      elem.children.map((caseItem) => {
        const { caseNumber, caseUUID, clientsEmployee, patient } =
          caseItem.case;
        const { sum } = caseItem;

        const modifiedInvoiceCases = elem.children
          ?.filter((elem) => elem.case.caseNumber === caseNumber)
          .map((child) => ({
            rowUUID: child.items?.rowUUID,
            sum: child?.sum,
            item: {
              name: child.items?.item.name,
              itemUUID: child.items?.item.itemUUID,
            },
            itemType: child.items?.itemType,
          }));

        if (combinedCases[caseNumber]) {
          combinedCases[caseNumber].sum += sum;
          combinedCases[caseNumber].items = modifiedInvoiceCases;
        } else {
          combinedCases[caseNumber] = {
            case: {
              caseNumber,
              caseUUID,
              clientsEmployee,
              patient: patient,
            },
            sum,
            items: modifiedInvoiceCases,
          };
        }

        return modifiedInvoiceCases;
      });

      return {
        ...elem,
        children: Object.values(combinedCases),
      };
    });

    setValue(combinedItems);
  };

  useEffect(() => {
    if (!!paymentItems.length) {
      groupObjectsByInvoiceNumber(paymentItems, setPaymentInvoices);
    }
  }, [paymentItems]);

  return {
    //First table
    paymentInvoices,
    setPaymentInvoices,

    //Second table
    unpaidInvoices,

    loading,
  };
};

export default usePaymentTables;
