import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button, Modal, notification, Space } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useForm } from "antd/es/form/Form";
import { UnitsOfMeasure } from "@/root/models/unitsOfMeasure";
import useUnitsOfMeasure from "@/hooks/useUnitsOfMeasure";
import { CreateUnitsOfMeasurePayload } from "@/api/unitsOfMeasure";
import ActionUnitOfMeasureModal from "./ActionUnitOfMeasureModal";

const { confirm } = Modal;

interface UnitsOfMeasureTableActionsProps {
  unitOfMeasure: UnitsOfMeasure;
  modal: string | null;
  onCancel: () => void;
  onModalOpen: () => void;
  onEditSuccess: () => void;
}

const UnitsOfMeasureTableActions = ({
  unitOfMeasure,
  modal,
  onCancel,
  onEditSuccess,
  onModalOpen,
}: UnitsOfMeasureTableActionsProps) => {
  const { t } = useTranslation();
  const [form] = useForm();

  const onDeleteError = (message: string) => {
    notification.error({
      message: message,
    });
  };

  const onSuccessEdit = () => {
    onEditSuccess();
    notification.success({
      message: t("Одиниця виміру була успішно змінена"),
    });
  };

  const {
    editUnitsOfMeasure,
    editError,
    editLoading,
    deleteLoading,
    deleteUnitsOfMeasure,
  } = useUnitsOfMeasure(onSuccessEdit, onDeleteError);

  const handleDeleteButtonClick = () => {
    confirm({
      title: t("Підтвердити видалення"),
      okText: t("Видалити"),
      okType: "danger",
      cancelText: t("Скасувати"),
      onOk: () => {
        deleteUnitsOfMeasure(unitOfMeasure.unitOfMeasureUUID);
        notification.success({
          message: t("Одиниця виміру була успішно видалена"),
        });
      },
    });
  };

  const handleEdit = (payload: CreateUnitsOfMeasurePayload) => {
    editUnitsOfMeasure(unitOfMeasure.unitOfMeasureUUID, payload);
  };

  const onHandleOpen = () => {
    onModalOpen();
  };

  useEffect(() => {
    modal && form.setFieldsValue(unitOfMeasure);
  }, [modal]);

  return (
    <Space className="table-actions" size="middle">
      <Button size="small" icon={<EditOutlined />} onClick={onHandleOpen} />
      <Button
        size="small"
        icon={<DeleteOutlined />}
        loading={deleteLoading}
        danger
        onClick={handleDeleteButtonClick}
      />
      <ActionUnitOfMeasureModal
        modal={modal}
        onCancel={onCancel}
        type="edit"
        form={form}
        handleSubmit={handleEdit}
        error={editError}
        loading={editLoading}
        unitOfMeasureUUID={unitOfMeasure.unitOfMeasureUUID}
      />
    </Space>
  );
};

export default UnitsOfMeasureTableActions;
