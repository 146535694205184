import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Space, Tree, TreeProps } from "antd";

import styles from "./FiltersCatalog.module.scss";

import useFilters from "@/hooks/useFilters";
import { Filter } from "@/root/models/filter";
import {
  AddFilterModal,
  FilterItem,
} from "@/modules/ProductCatalog/components/FiltersCatalog/components";
import { Loader } from "@/components/common";

const FiltersCatalog = () => {
  const { t } = useTranslation();
  const { filters, getFiltersLoading } = useFilters();

  const [addModal, setAddModal] = useState(false);

  const parseFilters = (filters: Filter[]): TreeProps["treeData"] => {
    return filters.map((filter) => ({
      key: filter.filterUUID,
      title: <FilterItem filter={filter} />,
      children: filter.children ? parseFilters(filter.children) : [],
    }));
  };

  const handleAddFilter = () => {
    setAddModal(false);
  };

  return (
    <Space direction="vertical">
      <Button size="small" onClick={() => setAddModal(true)}>
        {t("Додати")}
      </Button>
      {getFiltersLoading ? (
        <Loader />
      ) : (
        <Tree
          className={styles.tree}
          selectable={false}
          defaultExpandAll
          draggable={{
            icon: false,
          }}
          blockNode
          treeData={parseFilters(filters)}
        />
      )}
      {addModal && (
        <AddFilterModal
          open={addModal}
          onCancel={() => setAddModal(false)}
          onAdd={handleAddFilter}
        />
      )}
    </Space>
  );
};

export default FiltersCatalog;
