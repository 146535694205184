import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Space, Tree, TreeProps } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { DataNode } from "antd/es/tree";

import styles from "./Extras.module.scss";

import useExtras from "@/hooks/useExtras";
import { Extra } from "@/root/models/extra";
import { Loader } from "@/components/common";
import ExtraItem from "./components/ExtraItem";
import AddExtraModal from "./components/AddExtraModal";

const Extras = () => {
  const { t } = useTranslation();
  const {
    extras,
    addExtra,
    deleteExtra,
    editExtra,
    editExtraGroup,
    getExtrasLoading,
  } = useExtras();
  const [addModal, setAddModal] = useState(false);
  const [emptyElementDraggable, setEmptyElementDraggable] = useState(false);

  const parseExtras = (extras: Extra[]): TreeProps["treeData"] => {
    return extras.map((extra) => ({
      key: extra.extraUUID,
      title: (
        <ExtraItem
          extra={extra}
          onDelete={deleteExtra}
          onEdit={editExtra}
          onAdd={addExtra}
        />
      ),
      children: extra.children ? parseExtras(extra.children) : [],
    }));
  };

  const handleAddExtra = (extra: Extra) => {
    addExtra(extra);
    setAddModal(false);
  };

  const findExtraByUUID = (
    extras: Extra[],
    uuid: string
  ): Extra | undefined => {
    let foundExtra;
    for (const extra of extras) {
      if (extra.extraUUID === uuid) {
        foundExtra = extra;
        break;
      } else {
        if (extra.children) {
          const candidate = findExtraByUUID(extra.children, uuid);
          if (candidate) {
            foundExtra = candidate;
            break;
          }
        }
      }
    }
    return foundExtra;
  };

  const handleDrop: TreeProps["onDrop"] = async ({ node, dragNode }) => {
    const dragItemUUID = dragNode.key as string;

    const extra: Extra | undefined = findExtraByUUID(extras, dragItemUUID);

    if (extra) {
      setEmptyElementDraggable(false);
      if ((node.key as string).includes("empty")) {
        await editExtraGroup(extra, "");
        return;
      }
      const parentExtraUUID = node.key as string;
      await editExtraGroup(extra, parentExtraUUID);
    }
  };

  const allowDrop: TreeProps["allowDrop"] = ({ dropNode }) => {
    // @ts-ignore
    const extra: Extra = dropNode?.title?.props?.extra;
    if (extra) {
      return extra.isGroup && extra.level < 5;
    }

    return true;
  };

  const treeData: DataNode[] = [
    {
      key: "empty-1",
      title: "",
      switcherIcon: <PlusOutlined />,
      style: emptyElementDraggable ? undefined : { pointerEvents: "none" },
    },
    ...(parseExtras(extras) as DataNode[]),
    {
      key: "empty-2",
      title: "",
      switcherIcon: <PlusOutlined />,
      style: emptyElementDraggable ? undefined : { pointerEvents: "none" },
    },
  ];

  return (
    <Space direction="vertical">
      <Button size="small" onClick={() => setAddModal(true)}>
        {t("Додати")}
      </Button>
      {getExtrasLoading ? (
        <Loader />
      ) : (
        <Tree
          className={styles.tree}
          selectable={false}
          defaultExpandAll
          allowDrop={allowDrop}
          draggable={{
            icon: false,
          }}
          blockNode
          treeData={treeData}
          onDrop={handleDrop}
          onDragStart={() => setEmptyElementDraggable(true)}
          onDragEnd={() => setEmptyElementDraggable(false)}
        />
      )}
      {addModal && (
        <AddExtraModal
          open={addModal}
          onCancel={() => setAddModal(false)}
          onAdd={handleAddExtra}
        />
      )}
    </Space>
  );
};

export default Extras;
