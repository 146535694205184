import { Tree, TreeProps } from "antd";
import ListItemComponent from "../ListItem";

export interface PricelistItem {
  itemUUID: string;
  children: PricelistItem[] | undefined;
  isGroup: boolean;
  name: string;
}

interface PricelistListProps {
  items: PricelistItem[];
  getItemsLoading: boolean;
  itemPrices: { itemUUID: string; price: number }[];
  setItemPrices: (productPrices: any[]) => void;
  presentation?: boolean;
}

const PricelistList = ({
  itemPrices,
  items,
  getItemsLoading,
  setItemPrices,
  presentation = false,
}: PricelistListProps) => {
  const selectedProductUUIDs = itemPrices.map((p) => p.itemUUID);

  const handlePriceChange =
    (productUUID: string) => (price: number | undefined) => {
      if (price === undefined) {
        setItemPrices(itemPrices.filter((p) => p.itemUUID !== productUUID));
        return;
      }
      setItemPrices(
        itemPrices.map((p) => {
          if (p.itemUUID === productUUID) {
            return { itemUUID: p.itemUUID, price };
          } else {
            return p;
          }
        })
      );
    };

  const parseItems = (products: PricelistItem[]): TreeProps["treeData"] => {
    if (presentation) {
      const includesItem = (products: PricelistItem[]): boolean => {
        return !!products.filter((p) => {
          return (
            selectedProductUUIDs.includes(p.itemUUID) ||
            (!!p.children && includesItem(p.children))
          );
        }).length;
      };

      return products
        .filter(
          (p) =>
            selectedProductUUIDs.includes(p.itemUUID) ||
            (!!p.children && includesItem(p.children))
        )
        .map((item) => ({
          key: item.itemUUID,
          selectable: !item.isGroup,
          title: (
            <ListItemComponent
              item={item}
              price={
                itemPrices.find((p) => p.itemUUID === item.itemUUID)?.price
              }
              onPrice={handlePriceChange(item.itemUUID)}
              selected={selectedProductUUIDs.includes(item.itemUUID)}
            />
          ),
          children: item.children ? parseItems(item.children) : [],
        }));
    }

    return products.map((item) => ({
      key: item.itemUUID,
      selectable: !item.isGroup,
      title: (
        <ListItemComponent
          item={item}
          price={itemPrices.find((p) => p.itemUUID === item.itemUUID)?.price}
          onPrice={handlePriceChange(item.itemUUID)}
          selected={selectedProductUUIDs.includes(item.itemUUID)}
        />
      ),
      children: item.children ? parseItems(item.children) : [],
    }));
  };

  const handleSelect = (keys: string[]) => {
    setItemPrices(
      keys
        .filter((key) => {
          const productPrice = itemPrices.find((p) => p.itemUUID === key);
          if (!productPrice) {
            return true;
          }
          return !!productPrice?.price;
        })
        .map((key) => {
          const productPrice = itemPrices.find((p) => p.itemUUID === key);
          if (productPrice) {
            return { itemUUID: key, price: productPrice.price };
          } else {
            return { itemUUID: key, price: undefined };
          }
        })
    );
  };

  const treeData = parseItems(items);

  const getItemUUIDs = (items: PricelistItem[]): string[] => {
    return items.flatMap((p) =>
      p.children ? [...getItemUUIDs(p.children), p.itemUUID] : p.itemUUID
    );
  };

  return (
    <>
      {!getItemsLoading && (
        <Tree
          multiple
          expandedKeys={getItemUUIDs(items)}
          blockNode
          treeData={treeData}
          selectedKeys={selectedProductUUIDs}
          onSelect={(keys) => handleSelect(keys as string[])}
        />
      )}
    </>
  );
};

export default PricelistList;
