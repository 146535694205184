import { useTranslation } from "react-i18next";
import { Modal } from "antd";
import { useForm } from "antd/es/form/Form";
import useUsers from "@/hooks/useUsers";
import { CreateUserPayload } from "@/api/admin/users";
import UserForm from "./UserForm";

interface CreateUserModalProps {
  open: boolean;
  onCancel: () => void;
}

const CreateUserModal = ({ open, onCancel }: CreateUserModalProps) => {
  const { t } = useTranslation();
  const [form] = useForm();

  const onSuccess = () => {
    onCancel();
    form.resetFields();
  };

  const { addUser, createUserLoading, createUserError } = useUsers({
    onSuccess,
  });

  const createUser = (values: CreateUserPayload) => {
    addUser({ ...values });
  };

  const submit = () => {
    form.submit();
  };

  return (
    <Modal
      maskClosable={false}
      okText={t("Створити")}
      title={
        <div style={{ marginBottom: 20 }}>{t("Створення співробітника")}</div>
      }
      open={open}
      onCancel={onCancel}
      onOk={submit}
      okButtonProps={{ loading: createUserLoading }}
    >
      <UserForm form={form} handleSubmit={createUser} error={createUserError} />
    </Modal>
  );
};

export default CreateUserModal;
