import { CSSProperties, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, DatePicker, Select, Space, Spin, Table } from "antd";
import { ColumnsType } from "antd/es/table";

import styles from "@/modules/MainPanel/components/DashboardTable.module.scss";

import { v4 as uuidv4 } from "uuid";
import useEvents from "@/hooks/useEvents";
import { EventTypes } from "@/root/models/event";
import { Block, Pagination } from "@/components/common";
import useTablePaginationRange from "@/hooks/TableHooks/useTablePaginationRange";
import { EyeOutlined } from "@ant-design/icons";
import { Case } from "@/root/models/case";
import { ChosenCase } from "@/modules/CasesPanel/pages/Cases/components";
import useCases from "@/modules/CasesPanel/pages/Cases/hooks/useCases";
import useClickOutside from "@/hooks/useClickOutside";
import { Task } from "@/root/models/task";
import ChosenTask from "@/modules/Tasks/components/ChosenTask";
import useTasks from "@/hooks/useTasks";
import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import { setEventDate } from "@/store/reducers/dataFilterSlice";
import dayjs from "dayjs";
import { DATE_FORMAT } from "@/root/consts";

const { RangePicker } = DatePicker;
const { Option } = Select;

interface EventsProps {
  style?: CSSProperties;
}

const Events = ({ style }: EventsProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const eventDate = useAppSelector((state) => state.dataFilterSlice.eventDate);

  const [page, setPage] = useState(1);
  const [objectType, setObjectType] = useState<"case" | "task">();
  const [mainEntry, setMainEntry] = useState<boolean>();

  //Case select
  const [caseOpen, setCaseOpen] = useState<Case>();
  const [isCaseView, setIsCaseView] = useState(false);

  //Task select
  const [taskNumber, setTaskNumber] = useState("");
  const [taskOpen, setTaskOpen] = useState<Task>();
  const [isTaskView, setIsTaskView] = useState(false);

  const { events, pages, getEventsLoading } = useEvents({
    page,
    date: eventDate
      ? eventDate
      : `${new Date().toJSON().slice(0, 10)},${new Date()
          .toJSON()
          .slice(0, 10)}`,
    objectType,
    mainEntry,
  });

  const { cases, searchCases, getCasesLoading } = useCases({});

  const { tasks, getTasksLoading } = useTasks({ taskNumber });

  useEffect(() => {
    if (!!cases.length) {
      setCaseOpen(cases[0]);
    }
    if (!!tasks.length) {
      setTaskOpen(tasks[0]);
      setIsTaskView(true);
    }
  }, [cases, tasks]);

  const fullCaseRef = useRef<HTMLDivElement>(null);
  useClickOutside(fullCaseRef, () => {
    setCaseOpen(undefined);
    setIsCaseView(false);
    setTaskOpen(undefined);
    setIsTaskView(false);
    setTaskNumber("");
  });

  const columns: ColumnsType<EventTypes> = [
    {
      key: "dateTime",
      dataIndex: "dateTime",
      title: t("Дата_one"),
    },
    {
      key: "object",
      dataIndex: "object",
      title: t("Об'єкт"),
      render: (_, event) =>
        event.objectType === "Case" ? (
          <span>
            {t("Замовлення")}: {event.object.caseNumber}, {t("Контрагент")}:{" "}
            {event.object.client.name}
          </span>
        ) : event.objectType === "Task" ? (
          <span>
            {t("Завдання")}: {event.object.taskNumber}, {t("Кур'єр")}:{" "}
            {event.object.courier.name}
          </span>
        ) : null,
    },
    {
      key: "description",
      dataIndex: "description",
      title: t("Опис"),
    },
    {
      key: "action",
      dataIndex: "action",
      title: "",
      render: (_: any, item: EventTypes) => {
        if (getCasesLoading || getTasksLoading) {
          return <Spin />;
        } else
          return (
            <Button
              size="small"
              icon={<EyeOutlined />}
              onClick={async (e) => {
                e.stopPropagation();
                if (item.objectType === "Case") {
                  await searchCases({ caseNumber: item.object.caseNumber });
                  setIsCaseView(true);
                }
                if (item.objectType === "Task") {
                  setTaskNumber(item.object.taskNumber);
                }
              }}
            />
          );
      },
    },
  ];

  const { paginationRange, tableRef } =
    useTablePaginationRange(getEventsLoading);

  return (
    <Block style={style}>
      <div className={styles.container} style={{ position: "relative" }}>
        <Space direction="vertical">
          <Space>
            <span>{t("Дата_one")}:</span>
            <RangePicker
              onChange={(_, range) =>
                dispatch(setEventDate(range[0] ? range.join(",") : undefined))
              }
              allowClear
              value={
                eventDate
                  ? [
                      dayjs(eventDate.split(",")[0], DATE_FORMAT),
                      dayjs(eventDate.split(",")[1], DATE_FORMAT),
                    ]
                  : undefined
              }
            />
          </Space>
          <Space>
            <Space>
              <span>{t("Тип")}:</span>
              <Select
                style={{ width: 120 }}
                allowClear
                onSelect={setObjectType}
                onClear={() => setObjectType(undefined)}
              >
                <Option value="case">{t("Замовлення")}</Option>
                <Option value="task">{t("Завдання")}</Option>
              </Select>
            </Space>

            <Space>
              <span>{t("Основний запис")}:</span>
              <Select
                style={{ width: 120 }}
                allowClear
                onSelect={setMainEntry}
                onClear={() => setMainEntry(undefined)}
              >
                <Option value={false}>{t("Ні")}</Option>
                <Option value={true}>{t("Так")}</Option>
              </Select>
            </Space>
          </Space>
        </Space>
        <Table
          ref={tableRef}
          pagination={false}
          loading={getEventsLoading}
          columns={columns}
          style={{ overflow: "auto", userSelect: "none", height: "100%" }}
          dataSource={(events || []).map((e) => ({
            ...e,
            key: uuidv4(),
          }))}
          onRow={(item) => ({
            onDoubleClick: async () => {
              if (item.objectType === "Case") {
                await searchCases({ caseNumber: item.object.caseNumber });
              } else if (item.objectType === "Task") {
                setTaskNumber(item.object.taskNumber);
              }
            },
          })}
        />
        {!!pages && pages > 1 && (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Pagination
              currentPage={page}
              pages={pages}
              paginationRange={paginationRange}
              setCurrentPage={setPage}
            />
          </div>
        )}
        {!!caseOpen && isCaseView && (
          <div className={styles.fullCase} ref={fullCaseRef}>
            <ChosenCase medCase={caseOpen} style={{ margin: -15 }} />
          </div>
        )}
        {!!taskOpen && isTaskView && (
          <div
            className={styles.fullCase}
            ref={fullCaseRef}
            style={{ width: "40%" }}
          >
            <ChosenTask task={taskOpen} style={{ margin: -15 }} />
          </div>
        )}
      </div>
    </Block>
  );
};

export default Events;
