import styles from "./MiddlePanel.module.scss";

import { Jobs } from "@/components/CaseForm/components";

const MiddlePanel = () => {
  return (
    //@ts-ignore
    <div style={{ width: "35%" }}>
      <div className={styles.container}>
        <Jobs withParameters={false} withLock={true} />
      </div>
    </div>
  );
};

export default MiddlePanel;
