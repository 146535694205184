import { Block, Print } from "@/components/common";
import { Button, Space, Typography } from "antd";
import { t } from "i18next";
import SearchPayment from "../components/SearchPayment";
import { useState } from "react";
import { usePayments } from "@/hooks/usePayments";
import PaymentsTable from "../components/PaymentsTable";
import { FcFilledFilter } from "react-icons/fc";
import { useAppSelector } from "@/hooks/redux";
import { useNavigate } from "react-router-dom";
import { pages } from "@/root/consts";
import { UserTypeEnum } from "@/root/types";
import useInfo from "@/hooks/useInfo";
import PaymentPrint from "../components/PaymentPrint";
import { Payment } from "@/root/models/payment";
import { generateFileName } from "@/utils/generateFileName";
import { useIsLab } from "@/root/models/isLab";

const { Title } = Typography;

export interface PaymentSearchParamsGroup {
  adminClients: string | undefined;
  date: string;
}

const Payments = () => {
  const userType = useAppSelector((state) => state.userReducer.user?.userType);

  const userClientUUID = useAppSelector(
    (state) => state.userReducer.user?.client?.clientUUID
  );

  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useState({
    adminClients: "",
    date: "",
  });

  const { info } = useInfo();

  const { isLab } = useIsLab();

  const [page, setPage] = useState(1);

  const [full, setFull] = useState(false);

  const { payments, paymentsPages, getPaymentsLoading, refetch } = usePayments({
    page,
    clientUUID:
      isLab && searchParams.adminClients
        ? searchParams.adminClients
        : !isLab && userClientUUID
        ? userClientUUID
        : undefined,
    date: searchParams.date || undefined,
  });

  const [selectedPayment, setSelectedPayment] = useState<Payment>();

  const onHandleSearch = ({
    adminClients = "",
    date = "",
  }: PaymentSearchParamsGroup) => {
    setSearchParams({
      adminClients,
      date,
    });
  };

  return (
    <Block>
      <Space
        direction="vertical"
        style={{ justifyContent: "center", width: "50%" }}
      >
        <Title level={3} style={{ textAlign: "center" }}>
          {t("Оплати")}
        </Title>

        <Space style={{ marginBottom: 30 }}>
          <Button
            onClick={() => setFull(!full)}
            icon={<FcFilledFilter style={{ width: 20, height: 20 }} />}
          />
          {isLab && (
            <Button
              onClick={() => {
                if (userType?.userTypeUUID === UserTypeEnum["Lab's admin"]) {
                  navigate(pages.admin.payments.create.path);
                } else {
                  navigate(pages.lab.payments.create.path);
                }
              }}
            >
              {t("Стоврити оплату")}
            </Button>
          )}
          <Button
            htmlType="button"
            disabled={!selectedPayment}
            onClick={() => {
              if (selectedPayment) {
                generateFileName(
                  "Payment",
                  selectedPayment.paymentNumber,
                  selectedPayment.date
                );
                window.print();
              }
            }}
          >
            {t("Надрукувати оплату")}
          </Button>
          <Print>
            <PaymentPrint info={info} payment={selectedPayment} />
          </Print>
        </Space>
        {full && <SearchPayment onSearch={onHandleSearch} />}
      </Space>

      <PaymentsTable
        tabHeight={136}
        payments={payments}
        pages={paymentsPages}
        getPaymentsLoading={getPaymentsLoading}
        page={page}
        setPage={setPage}
        refetch={refetch}
        selectedPayment={selectedPayment}
        setSelectedPayment={setSelectedPayment}
      />
    </Block>
  );
};

export default Payments;
