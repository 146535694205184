import { useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Dropdown, MenuProps } from "antd";
import { ITooth } from "@/components/TeethWidget/components/Tooth";
import {
  ActionCreatorTypes,
  CaseFormState,
  useCaseFormContext,
} from "@/components/CaseForm/CaseFormContext";
import { Tooth } from "@/components/TeethWidget/components";
import ClickDropdown from "@/components/CaseForm/components/Teeth/ClickDropdown";
import ChooseProduct from "../../ChooseProduct";
import { Block } from "@/components/common";
import useClickOutside from "@/hooks/useClickOutside";
import { Product } from "@/root/models/product";
import { getToothIDByPosition } from "@/utils/getToothIDByPosition";
import { getToothPositionByID } from "@/utils/getToothPositionByID";
import { useAppSelector } from "@/hooks/redux";
import { useIsLab } from "@/root/models/isLab";

interface CaseFormToothProps {
  tooth: ITooth;
  allTeeth: ITooth[];
  disabled?: boolean;
}

const CaseFormTooth = ({ tooth, allTeeth, disabled }: CaseFormToothProps) => {
  const { t } = useTranslation();
  const { state, dispatch } = useCaseFormContext();

  const { isLab } = useIsLab();

  const productActiveId = useAppSelector(
    (state) => state.teethFormulaSelectSlice.activeProductId
  );

  const isProductLock = useAppSelector(
    (state) => state.productLockSlice.isLock
  );

  const [chooseProductModal, setChooseProductModal] = useState<
    "copy" | "move" | "change" | null
  >(null);

  const { job, jobProduct } = useMemo(() => {
    const job = state.jobs.find((job) => job.jobID === state.activeJobID);
    const jobProduct = job?.products.find(
      (product) => product.jobProductUUID === job.activeProductID
    );
    return { job, jobProduct };
  }, [state.jobs, state.activeJobID]);

  const toothProduct = useMemo(() => {
    return findProductByJobProductUUID(state.jobs, tooth.jobProductUUID);
  }, [state.jobs, tooth]);

  const handleClick = () => {
    if (disabled) {
      return;
    }

    const toothInJaw =
      jobProduct?.product &&
      (jobProduct.teeth?.includes(tooth.id) ||
        ((jobProduct.teeth?.includes("FDI_AUJ") ||
          jobProduct.teeth?.includes("FDI_CUJ")) &&
          tooth.jaw === "upper") ||
        ((jobProduct.teeth?.includes("FDI_ALJ") ||
          jobProduct.teeth?.includes("FDI_CLJ")) &&
          tooth.jaw === "lower"));

    if (toothInJaw) {
      dispatch({
        type: ActionCreatorTypes.SetJobSelectedTooth,
        payload: { tooth: tooth.id },
      });
      return;
    }

    // if upper or lower jaw is chosen, can't choose tooth from this jaw
    if (
      (tooth.jaw === "upper" && jobProduct?.teeth?.includes("FDI_AUJ")) ||
      jobProduct?.teeth?.includes("FDI_CUJ")
    ) {
      return;
    }

    if (
      (tooth.jaw === "lower" && jobProduct?.teeth?.includes("FDI_ALJ")) ||
      jobProduct?.teeth?.includes("FDI_CLJ")
    ) {
      return;
    }

    dispatch({
      type: ActionCreatorTypes.SetTooth,
      payload: {
        tooth: tooth.id,
        activeProductID: isLab
          ? productActiveId
          : (job?.activeProductID as string),
      },
    });
  };

  const prevToothID = getToothIDByPosition(tooth.position - 1);

  const handleChainClick = () => {
    if (!prevToothID) {
      return;
    }

    const isToothChosen = toothProduct?.teeth?.includes(tooth.id) || false;
    const isPrevToothChosen =
      (prevToothID &&
        allTeeth.find((t) => t.id === prevToothID)?.jobProductUUID) ||
      false;

    if (!isToothChosen && !isPrevToothChosen) {
      dispatch({
        type: ActionCreatorTypes.SetTooth,
        payload: { tooth: tooth.id },
      });
      dispatch({
        type: ActionCreatorTypes.SetTooth,
        payload: { tooth: prevToothID },
      });
      return;
    }

    if (isToothChosen && !isPrevToothChosen) {
      dispatch({
        type: ActionCreatorTypes.SetTooth,
        payload: { tooth: prevToothID },
      });
      return;
    }

    if (!isToothChosen && isPrevToothChosen) {
      dispatch({
        type: ActionCreatorTypes.SetTooth,
        payload: { tooth: tooth.id },
      });
      return;
    }

    if (
      toothProduct?.teeth?.length === 2 &&
      toothProduct.teeth.includes(prevToothID) &&
      toothProduct.teeth.includes(tooth.id)
    ) {
      dispatch({
        type: ActionCreatorTypes.SetCombinedProductChecked,
        payload: {
          checked: !toothProduct.combinedProduct,
          activeProductID: toothProduct.jobProductUUID,
        },
      });
      return;
    }

    if (toothProduct?.teeth && toothProduct.teeth.length > 2) {
      if (!toothProduct.combinedProduct) {
        const teeth = [prevToothID, tooth.id];

        teeth.forEach((tooth) => {
          dispatch({
            type: ActionCreatorTypes.SetTooth,
            payload: { tooth, activeProductID: toothProduct.jobProductUUID },
          });
        });

        const newProduct = {
          teeth,
          product: toothProduct.product,
          teethColor: toothProduct.teethColor,
          combinedProduct: true,
          differentTeethParameters: toothProduct.differentTeethParameters,
          productsParametersGroups: toothProduct.productsParametersGroups,
          quantity: toothProduct.quantity,
        };

        dispatch({
          type: ActionCreatorTypes.AddProduct,
          payload: newProduct,
        });
      }

      if (toothProduct.combinedProduct) {
        const teethWithPositions = toothProduct.teeth
          .map((t) => ({
            id: t,
            position: getToothPositionByID(t),
          }))
          .sort((a, b) =>
            !a.position || !b.position ? 0 : a.position > b.position ? 1 : -1
          );

        const toothIndex = teethWithPositions.findIndex(
          (t) => t.id === tooth.id
        );

        const jobProductTeeth1 = teethWithPositions.slice(0, toothIndex);
        const jobProductTeeth2 = teethWithPositions.slice(toothIndex);

        const newProduct = {
          teeth: [] as string[],
          product: toothProduct.product,
          teethColor: toothProduct.teethColor,
          combinedProduct: toothProduct.combinedProduct as boolean,
          differentTeethParameters: toothProduct.differentTeethParameters,
          productsParametersGroups: toothProduct.productsParametersGroups,
          quantity: toothProduct.quantity,
        };

        const newProductTeeth =
          jobProductTeeth1.length > jobProductTeeth2.length
            ? jobProductTeeth2
            : jobProductTeeth1;

        newProduct.teeth = newProductTeeth.map((t) => t.id);
        newProductTeeth.forEach((tooth) => {
          dispatch({
            type: ActionCreatorTypes.SetTooth,
            payload: {
              tooth: tooth.id,
              activeProductID: toothProduct.jobProductUUID,
            },
          });
        });
        if (newProductTeeth.length === 1) {
          setTimeout(() => {
            dispatch({
              type: ActionCreatorTypes.SetCombinedProductChecked,
              payload: { checked: false },
            });
          });
        }

        if (newProduct.teeth.length === 1) {
          const tooth = allTeeth.find((t) => t.id === newProduct.teeth[0]);
          const toothIndex = allTeeth.findIndex(
            (t) => t.id === newProduct.teeth[0]
          );
          const prev = allTeeth[toothIndex - 1];
          const next = allTeeth[toothIndex + 1];

          const prevProduct = findProductByJobProductUUID(
            state.jobs,
            prev?.jobProductUUID
          );

          const nextProduct = findProductByJobProductUUID(
            state.jobs,
            next?.jobProductUUID
          );

          const toothProductUUID = toothProduct.product?.productUUID;

          if (
            tooth &&
            prevProduct &&
            prev.jobProductUUID !== tooth.jobProductUUID &&
            prevProduct.product?.productUUID === toothProductUUID &&
            !prevProduct.combinedProduct
          ) {
            dispatch({
              type: ActionCreatorTypes.SetTooth,
              payload: {
                tooth: tooth.id,
                activeProductID: prevProduct.jobProductUUID,
              },
            });
            return;
          }

          if (
            tooth &&
            nextProduct &&
            next.jobProductUUID !== tooth.jobProductUUID &&
            nextProduct.product?.productUUID === toothProductUUID &&
            !nextProduct.combinedProduct
          ) {
            dispatch({
              type: ActionCreatorTypes.SetTooth,
              payload: {
                tooth: tooth.id,
                activeProductID: nextProduct.jobProductUUID,
              },
            });
            return;
          }

          newProduct.combinedProduct = false;
        }

        dispatch({
          type: ActionCreatorTypes.AddProduct,
          payload: newProduct,
        });
      }
    }
  };

  const items: MenuProps["items"] = [
    {
      label: t("Перемкнути властивість комбінованого продукту"),
      key: "1",
      onClick: () => {
        if (!jobProduct) {
          return;
        }

        dispatch({
          type: ActionCreatorTypes.SetCombinedProductChecked,
          payload: {
            checked: !jobProduct.combinedProduct,
          },
        });
      },
    },
    {
      label: t("Обрати або змінити виріб"),
      onClick: () => {
        setChooseProductModal("change");
      },
      key: "6",
    },
    {
      label: t("Додати новий виріб"),
      key: "2",
      onClick: () => {
        if (!job) {
          return;
        }

        dispatch({
          type: ActionCreatorTypes.AddProduct,
          payload: {
            activeJobID: job.jobID,
          },
        });
      },
    },
    {
      label: t("Видалити поточний виріб"),
      key: "4",
      onClick: () => {
        if (!job || !jobProduct) {
          return;
        }

        dispatch({
          type: ActionCreatorTypes.DeleteProduct,
          payload: {
            activeJobID: job.jobID,
            productUUID: jobProduct.jobProductUUID,
          },
        });
      },
    },
  ];

  job?.selectedTeeth?.includes(tooth.id) &&
    items.push(
      ...[
        {
          label: t("Видалити зуб з обраного виробу"),
          key: "7",
          onClick: () => {
            if (!job || !jobProduct) {
              return;
            }
            dispatch({
              type: ActionCreatorTypes.SetTooth,
              payload: {
                activeJobID: job.jobID,
                activeProductID: tooth.jobProductUUID,
                tooth: tooth.id,
              },
            });
          },
        },
        {
          label: t("Перенести зуби в новий виріб"),
          key: "8",
          onClick: () => {
            setChooseProductModal("move");
          },
        },
        {
          label: t("Скопіювати зуби в новий виріб"),
          key: "9",
          onClick: () => {
            setChooseProductModal("copy");
          },
        },
      ]
    );

  const handleContextMenuOpen = (open: boolean) => {
    if (open && job && jobProduct?.product && !tooth.selected) {
      dispatch({
        type: ActionCreatorTypes.SetJobSelectedTooth,
        payload: {
          activeJobID: job.jobID,
          tooth: tooth.id,
        },
      });
    }
  };

  const handleSelect = ({
    product,
    teethColor,
  }: {
    product: Product;
    teethColor: string | undefined;
  }) => {
    if (!job) {
      return;
    }

    if (chooseProductModal === "copy") {
      dispatch({
        type: ActionCreatorTypes.AddProductWithJobSelectedTeeth,
        payload: {
          activeJobID: job.jobID,
          product,
          teethColor,
          move: false,
        },
      });
    } else if (chooseProductModal === "move") {
      dispatch({
        type: ActionCreatorTypes.AddProductWithJobSelectedTeeth,
        payload: {
          activeJobID: job.jobID,
          product,
          teethColor,
          move: true,
        },
      });
    } else if (chooseProductModal === "change") {
      dispatch({
        type: ActionCreatorTypes.SetProduct,
        payload: { product, teethColor },
      });
    }

    setChooseProductModal(null);
  };

  const chooseProductRef = useRef<HTMLDivElement>(null);

  useClickOutside(chooseProductRef, () => setChooseProductModal(null));

  const isChainOpen =
    Boolean(
      toothProduct?.teeth?.length === 2 &&
        toothProduct.combinedProduct &&
        prevToothID &&
        toothProduct.teeth.includes(prevToothID)
    ) ||
    Boolean(
      toothProduct &&
        prevToothID &&
        toothProduct.teeth?.includes(prevToothID) &&
        toothProduct.combinedProduct
    );

  return (
    <Dropdown
      disabled={disabled}
      menu={{ items }}
      trigger={["contextMenu"]}
      destroyPopupOnHide
      onOpenChange={handleContextMenuOpen}
    >
      <g>
        <Dropdown
          dropdownRender={() =>
            !jobProduct?.product ? <ClickDropdown /> : <div />
          }
          trigger={["click"]}
          destroyPopupOnHide
        >
          <Dropdown
            open={!!chooseProductModal}
            dropdownRender={() => (
              <div ref={chooseProductRef}>
                <Block>
                  <ChooseProduct onSelect={handleSelect} />
                </Block>
              </div>
            )}
            destroyPopupOnHide
          >
            <Tooth
              formulaNumbering={state.teethFormulaNumberingUUID || ""}
              tooth={{ ...tooth, isChainOpen }}
              onClick={handleClick}
              onChainClick={handleChainClick}
              isProductLock={isProductLock}
            />
          </Dropdown>
        </Dropdown>
      </g>
    </Dropdown>
  );
};

export default CaseFormTooth;

const findProductByJobProductUUID = (
  jobs: CaseFormState.Job[],
  jobProductUUID: string | undefined
) => {
  return jobs
    .find((job) =>
      job.products.find((p) => p.jobProductUUID === jobProductUUID)
    )
    ?.products.find((p) => p.jobProductUUID === jobProductUUID);
};
