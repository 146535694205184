import { useState } from "react";
import { useTranslation } from "react-i18next";
import { QrReader } from "react-qr-reader";
import { Alert, Button, Modal } from "antd";

import styles from "./TaskActions.module.scss";

import { Task } from "@/root/models/task";
import { TaskStatusEnum } from "@/root/types";
import useTasks from "@/hooks/useTasks";

interface CourierTaskActionsProps {
  task: Task;
  page: number;
}

const CourierTaskActions = ({ task, page }: CourierTaskActionsProps) => {
  const { t } = useTranslation();
  const [qrModal, setQrModal] = useState(false);
  const [scanError, setScanError] = useState(false);

  const { editTask, editLoading } = useTasks({
    page,
    pageLimit: 25,
  });

  const handleQrRead = (value: string) => {
    if (!task.cases.some((c) => c.case.caseNumber === value)) {
      setScanError(true);
      return;
    }

    setQrModal(false);
    editTask(task.taskUUID, {
      ...task,
      cases: task.cases.map((c) => ({ caseUUID: c.case.caseUUID, use: c.use })),
      responsibleUUID: task.client.clientUUID,
      taskStatusUUID: TaskStatusEnum["In progress"],
    });
  };

  const handleAcceptClick = () => {
    editTask(task.taskUUID, {
      ...task,
      cases: task.cases.map((c) => ({ caseUUID: c.case.caseUUID, use: c.use })),
      responsibleUUID: task.client.clientUUID,
      taskStatusUUID: TaskStatusEnum["In progress"],
    });
  };

  const handleCancel = () => {
    setQrModal(false);
    setScanError(false);
  };

  return (
    <>
      <div className={styles.container}>
        {task.taskStatus.taskStatusUUID === TaskStatusEnum.New && (
          <>
            <Button
              loading={editLoading}
              onClick={(e) => {
                e.stopPropagation();
                handleAcceptClick();
              }}
            >
              {t("Натиснути щоб розпочати завдання")}
            </Button>

            <Button
              loading={editLoading}
              onClick={(e) => {
                e.stopPropagation();
                setQrModal(true);
              }}
            >
              {t("Сканувати QR, щоб розпочати завдання")}
            </Button>
          </>
        )}
      </div>
      <Modal
        title={
          scanError ? (
            <Alert
              style={{ marginTop: 20 }}
              type="error"
              description={t(
                "Будь ласка відскануйте код відповідного замовлення"
              )}
            />
          ) : undefined
        }
        destroyOnClose
        open={qrModal}
        okButtonProps={{
          hidden: !scanError,
        }}
        onCancel={handleCancel}
        onOk={() => setScanError(false)}
        okText={scanError ? t("Повторити сканування") : undefined}
      >
        {!scanError && (
          <QrReader
            constraints={{ facingMode: "environment" }}
            onResult={(result) =>
              result?.getText() && handleQrRead(result.getText())
            }
          />
        )}
      </Modal>
    </>
  );
};

export default CourierTaskActions;
