import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { Button, Calendar, DatePicker } from "antd";
import { DoubleLeftOutlined, DoubleRightOutlined } from "@ant-design/icons";

import styles from "./MainPanel.module.scss";

import { Block } from "@/components/common";
import { DATE_FORMAT } from "@/root/consts";
import { getCalendarDates } from "@/utils/getCalendarDates";
import { CalendarCasesDangerDueDates } from "@/api/calendarCasesDangerDueDates";
import { useCollapsePanelContext } from "@/pages/DashboardPage";
import DashboardTables from "./components/DashboardTables";
import { UnreadChatMessages } from "./components";

const { RangePicker } = DatePicker;

const MainPanel = () => {
  const [widgetCollapsed, setWidgetCollapsed] = useState(false);
  const [date, setDate] = useState<string>("");
  const [casesDates, setCasesDated] = useState<string[]>([]);
  const { collapsePanel } = useCollapsePanelContext();

  const [isChatOpen, setIsChatOpen] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setCasesDated(
          await CalendarCasesDangerDueDates.getDates(
            ...(getCalendarInitialDates(date) as [string, string])
          )
        );
      } catch (e) {}
    })();
  }, [date]);

  const handleCalendarChange = (dayjs: dayjs.Dayjs) => {
    dayjs.format(DATE_FORMAT) === date
      ? setDate("")
      : setDate(dayjs.format(DATE_FORMAT));
  };

  const collapse = () => {
    collapsePanel();
    setWidgetCollapsed(true);
  };

  const dateRangeValue: [dayjs.Dayjs, dayjs.Dayjs] | undefined =
    (date &&
      (date.includes(",")
        ? [
            dayjs(new Date(date.split(",")[0])),
            dayjs(new Date(date.split(",")[1])),
          ]
        : [dayjs(new Date(date)), dayjs(new Date(date))])) ||
    undefined;
  const calendarValue =
    date && !date?.includes(",") ? dayjs(new Date(date)) : dayjs();

  return (
    <div className={styles.container}>
      {widgetCollapsed ? (
        <Block
          onClick={() => setWidgetCollapsed(false)}
          className={styles.expandWidgets}
        >
          <DoubleRightOutlined />
        </Block>
      ) : (
        <div className={styles.widgets}>
          <Block className={styles.calendarContainer}>
            <div className={styles.rangePicker}>
              <RangePicker
                value={dateRangeValue}
                onChange={(_, range) =>
                  setDate(range[0] ? range.join(",") : "")
                }
                allowClear
              />
            </div>
            <Calendar
              cellRender={(date) =>
                casesDates.includes(date.format(DATE_FORMAT)) ? (
                  <div className={styles.calendarDangerCell} />
                ) : undefined
              }
              className={styles.calendar}
              fullscreen={false}
              value={calendarValue}
              onSelect={handleCalendarChange}
            />
            <Button
              className={styles.collapseButton}
              icon={<DoubleLeftOutlined />}
              onClick={collapse}
            />
          </Block>
          <Block>
            <div>Current balance: 100</div>
          </Block>
          {/* Unread chat messages */}
          <UnreadChatMessages setIsChatOpen={setIsChatOpen} />
          <Block
            style={{
              display: "flex",
              gap: 10,
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div>New events: 3</div>
            <Button>Go to event</Button>
          </Block>
        </div>
      )}
      <div
        style={{
          width: widgetCollapsed ? "calc(100% - 30px)" : "calc(100% - 408px)",
        }}
        className={styles.contentContainer}
      >
        <DashboardTables
          date={date}
          widgetCollapsed={widgetCollapsed}
          setWidgetCollapsed={setWidgetCollapsed}
          isChatOpen={isChatOpen}
        />
      </div>
    </div>
  );
};

export default MainPanel;

const getCalendarInitialDates = (dates: string) => {
  let date = new Date();

  if (dates.includes(",")) {
    return dates.split(",").map((d) => dayjs(new Date(d)).format(DATE_FORMAT));
  } else if (dates) {
    date = new Date(dates);
  }

  const calendarDates = getCalendarDates(
    date.getFullYear(),
    date.getMonth() + 1
  );
  const fistAndLastDates = [calendarDates[0], calendarDates.at(-1)];
  return fistAndLastDates.map((d) => dayjs(d).format(DATE_FORMAT));
};
