import { Form, Select } from "antd";
import useEmployees from "@/hooks/useEmployees";
import { useTranslation } from "react-i18next";

const { Option } = Select;

const EmployeesSelect = () => {
  const { t } = useTranslation();
  const { employees, getEmployeesLoading } = useEmployees();

  return (
    <Form.Item name="employees" label={t("Співробітник")} shouldUpdate>
      <Select
        style={{ width: 200 }}
        mode="multiple"
        loading={getEmployeesLoading}
        listHeight={1000}
        allowClear
      >
        {employees?.map((employee) => (
          <Option key={employee.employeeUUID} value={employee.employeeUUID}>
            {employee.name || " "}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default EmployeesSelect;
