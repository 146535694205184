import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Tabs, TabsProps } from "antd";
import { SearchInput } from "@/components";
import Pricelists from "@/modules/PricelistPanel/components/Pricelists";
import ClientPricelists from "@/modules/PricelistPanel/components/ClientPricelists";

const PricelistPanel = () => {
  const { t } = useTranslation();
  const [tabKey, setTabKey] = useState("pricelist");

  const items: TabsProps["items"] = [
    {
      key: "pricelist",
      label: t("Прайс лист"),
      children: <Pricelists />,
    },
    {
      key: "client-pricelist",
      label: t("Прайс лист контрагента"),
      children: <ClientPricelists />,
    },
  ];

  return (
    <div>
      <div style={{ marginBottom: 20 }}>
        <SearchInput />
      </div>
      <Tabs
        type="card"
        items={items}
        activeKey={tabKey}
        onTabClick={setTabKey}
      />
    </div>
  );
};

export default PricelistPanel;
