import { Block } from "@/components/common";
import { CSSProperties, useEffect, useState } from "react";

import maintyles from "@/modules/MainPanel/components/DashboardTable.module.scss";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import styles from "./ChatPanel.module.scss";

import { MainContainer } from "@chatscope/chat-ui-kit-react";
import useChats from "@/hooks/useChats";
import ObjectsList from "./components/ObjectsList";
import { ChatTypes } from "@/root/models/chat";
import Chat from "./components/Chat";
import { t } from "i18next";
import { Button } from "antd";
import { useAppDispatch } from "@/hooks/redux";
import { setChat } from "@/store/reducers/chatSlice";
import useChatNotifications from "@/hooks/useChatNotifications";
import { NotExistEnum } from "@/root/types";

interface ChatPanelProps {
  style?: CSSProperties;
  openChatUUID: string | undefined;
}

const ChatPanel = ({ style, openChatUUID }: ChatPanelProps) => {
  const dispatch = useAppDispatch();

  const { chats, refetch, chatsLoading } = useChats();

  const { chatNotifications, refetchChatNotif, deleteChatNotifications } =
    useChatNotifications();

  const [selectedChat, setSelectedChat] = useState<ChatTypes>();

  //Is chat which should be open on Open chat button click eiher on Cases table or inside Case edit
  const openChat = chats.find((elem) => elem.chatUUID === openChatUUID);

  useEffect(() => {
    if (selectedChat) {
      dispatch(setChat(""));
    }
  }, [selectedChat]);

  useEffect(() => {
    return () => {
      dispatch(setChat(""));
    };
  }, []);

  return (
    <Block style={style}>
      <div className={maintyles.container}>
        <div
          style={{
            height: "100%",
            position: "relative",
          }}
        >
          <MainContainer responsive>
            {/* Left panel */}
            <ObjectsList
              chats={chats}
              selectedChat={selectedChat}
              setSelectedChat={setSelectedChat}
              openChatUUID={openChatUUID}
              chatNotifications={chatNotifications}
              chatsLoading={chatsLoading}
            />

            {/* Right panel */}
            {!chats.length ? (
              <div className={styles.no_chats_container}>
                <div style={{ fontSize: 18, fontWeight: "500" }}>
                  {t("Немає доступних чатів.")}
                </div>
                <div style={{ marginBottom: 10 }}>
                  {t("Створіть ваш перший чат.")}
                </div>
                <Button>{t("Створити чат")}</Button>
              </div>
            ) : (
              <Chat
                selectedChat={(() => {
                  if (openChat) {
                    return openChat;
                  }

                  if (
                    !selectedChat ||
                    openChatUUID === NotExistEnum["NullUUID"] ||
                    (selectedChat &&
                      !chats.find(
                        (elem) => elem.chatUUID === selectedChat.chatUUID
                      ))
                  ) {
                    return chats[0];
                  }

                  if (selectedChat) {
                    return selectedChat;
                  }
                })()}
                chats={chats}
                refetch={refetch}
                refetchChatNotif={refetchChatNotif}
                chatNotifications={chatNotifications}
                deleteChatNotifications={deleteChatNotifications}
              />
            )}
          </MainContainer>
        </div>
      </div>
    </Block>
  );
};

export default ChatPanel;
