import { useTranslation } from "react-i18next";
import { Modal } from "antd";
import { useForm } from "antd/es/form/Form";
import useAddPan from "./hooks/useAddPan";
import PanForm from "./PanForm";

interface CreatePanModalProps {
  open: boolean;
  onCancel: () => void;
}

const CreatePanModal = ({ open, onCancel }: CreatePanModalProps) => {
  const { t } = useTranslation();
  const [form] = useForm();

  const onSuccess = () => {
    onCancel();
    form.resetFields();
  };

  const { addPan, loading, error } = useAddPan(onSuccess);

  const submit = () => {
    form.submit();
  };

  return (
    <Modal
      maskClosable={false}
      okText={t("Створити")}
      title={<div style={{ marginBottom: 20 }}>{t("Створення лотка")}</div>}
      open={open}
      onCancel={onCancel}
      onOk={submit}
      okButtonProps={{ loading }}
    >
      <PanForm form={form} handleSubmit={addPan} error={error} />
    </Modal>
  );
};

export default CreatePanModal;
