import { useAppSelector } from "@/hooks/redux";
import useChats from "@/hooks/useChats";
import { UserTypeEnum } from "@/root/types";
import { Dropdown, MenuProps, Modal, notification } from "antd";
import { t } from "i18next";
import { ReactNode } from "react";

interface DropdownChatOptionsProps {
  children: ReactNode;
  chatUUID: string;
}

const { confirm } = Modal;

const DropdownChatOptions = ({
  children,
  chatUUID,
}: DropdownChatOptionsProps) => {
  const userType = useAppSelector((state) => state.userReducer.user?.userType);

  const { deleteChat } = useChats();

  const items: MenuProps["items"] = [
    {
      label: t("Видалити чат"),
      key: "delete",
      onClick: () => {
        confirm({
          title: t("Підтвердити видалення"),
          okText: t("Видалити"),
          okType: "danger",
          cancelText: t("Скасувати"),
          onOk: () => {
            if (userType?.userTypeUUID === UserTypeEnum[`Lab's admin`]) {
              deleteChat(chatUUID);
            } else {
              notification.error({
                message: t("Немає прав доступу!"),
              });
            }
          },
        });
      },
    },
  ];

  return (
    <Dropdown menu={{ items }} trigger={["contextMenu"]}>
      {children}
    </Dropdown>
  );
};

export default DropdownChatOptions;
