import { useTranslation } from "react-i18next";
import { Space, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { ParametersGroup } from "@/root/models/parameterGroup";
import { ProductParameter } from "@/root/models/productParameter";
import { t } from "i18next";
import useFilters from "@/hooks/useFilters";
import { Filter } from "@/root/models/filter";
import { getParameterValue } from "@/utils/getParameterValue";
import useGroups from "./hooks/useGroups";
import ProductsParametersTableActions from "./components/ProductsParametersTableActions";
import CreateGroup from "./components/CreateGroup";

interface ParametersGroupParameter {
  required: boolean;
  productsParameter: ProductParameter;
}

const SubTable =
  (findFilterByID: (id: string) => Filter | undefined) =>
  (parameter: ParametersGroup) => {
    const subTableColumns: ColumnsType<ParametersGroupParameter> = [
      {
        key: "productsParameter",
        dataIndex: "productsParameter",
        title: t("Назва"),
        render: (p: ProductParameter) => <span>{p.name}</span>,
      },
      {
        key: "productsParameter",
        dataIndex: "productsParameter",
        title: t("Тип значення параметру"),
        render: (p: ProductParameter) => <span>{p.type.name}</span>,
      },
      {
        key: "productsParameter",
        dataIndex: "productsParameter",
        title: t("Значення за замовчуванням"),
        render: (p: ProductParameter) =>
          getParameterValue(p.type, p.defaultValue as string, findFilterByID),
      },
      {
        key: "productsParameter",
        dataIndex: "productsParameter",
        title: t("Список значень"),
        render: (p: ProductParameter) => (
          <span>{p.list?.map((p) => p.value)?.join("; ")}</span>
        ),
      },
      {
        key: "required",
        dataIndex: "required",
        title: t("Обов'язковий"),
        render: (p: boolean) => <span>{p ? t("Так") : t("Ні")}</span>,
      },
    ];

    const data = parameter.productsParameters.map((p) => ({
      ...p,
      key: p.productsParameter.productsParameterUUID,
    }));

    return (
      <Table
        pagination={false}
        size="small"
        columns={subTableColumns}
        dataSource={data}
      />
    );
  };

const ParametersGroups = () => {
  const { t } = useTranslation();
  const { groups, getGroupsLoading, createGroup, deleteGroup, editGroup } =
    useGroups();
  const { findFilterByID } = useFilters();

  const groupsColumns = [
    {
      title: t("Група"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: "",
      key: "action",
      render: (_: any, group: ParametersGroup) => (
        <ProductsParametersTableActions
          group={group}
          onDelete={deleteGroup}
          onEdit={editGroup}
        />
      ),
    },
  ];

  const data = groups.map((g) => ({
    ...g,
    key: g.productsParametersGroupUUID,
  }));

  return (
    <Space direction="vertical">
      <CreateGroup onCreate={createGroup} />
      <Table
        columns={groupsColumns}
        pagination={false}
        loading={getGroupsLoading}
        dataSource={data}
        expandedRowRender={SubTable(findFilterByID)}
        expandRowByClick
      />
    </Space>
  );
};

export default ParametersGroups;
