import { ReportSettingItemEnum } from "@/root/types";
import { Modal } from "antd";
import { ReactNode, memo } from "react";

interface ChoseItemsModalProps {
  modal: string | null;
  isModalVisible?: boolean;
  handleCancel: () => void;
  onEditSuccess: () => void;
  children: ReactNode;
  itemUUID: string;
  title?: string;
}

const ChoseItemsModal = memo(
  ({
    itemUUID,
    children,
    modal,
    isModalVisible,
    handleCancel,
    onEditSuccess,
    title,
  }: ChoseItemsModalProps) => {
    return (
      <Modal
        title={title ? title : "Select Options"}
        open={isModalVisible ? isModalVisible : modal === itemUUID}
        onOk={onEditSuccess}
        onCancel={handleCancel}
        width={itemUUID === ReportSettingItemEnum["Cases"] ? 2000 : 1000}
        maskClosable={false}
        style={{ height: "50vh" }}
      >
        {children}
      </Modal>
    );
  }
);

export default ChoseItemsModal;
