import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Modal, notification, Space } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useForm } from "antd/es/form/Form";
import { ParametersGroup } from "@/root/models/parameterGroup";
import {
  CreateProductsParametersGroupPayload,
  ProductsParametersGroupApi,
} from "@/api/productsParametersGroup";
import ProductsParametersGroupForm from "./ProductsParametersGroupForm";

const { confirm } = Modal;

interface ProductsParametersTableActionsProps {
  group: ParametersGroup;
  onDelete: (id: string) => void;
  onEdit: (id: string, group: ParametersGroup) => void;
}

const ProductsParametersTableActions = ({
  group,
  onDelete,
  onEdit,
}: ProductsParametersTableActionsProps) => {
  const { t } = useTranslation();
  const [form] = useForm();
  const [modal, setModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [editError, setEditError] = useState("");

  const handleDelete = async () => {
    try {
      setDeleteLoading(true);
      await ProductsParametersGroupApi.delete(
        group.productsParametersGroupUUID
      );
      onDelete(group.productsParametersGroupUUID);
    } catch (e: any) {
      notification.error({
        message: e.message,
      });
    } finally {
      setDeleteLoading(false);
    }
  };

  const handleEdit = async (values: CreateProductsParametersGroupPayload) => {
    try {
      setEditLoading(true);
      setEditError("");
      const newClient = await ProductsParametersGroupApi.edit(
        group.productsParametersGroupUUID,
        values
      );
      onEdit(group.productsParametersGroupUUID, newClient);
      setModal(false);
    } catch (e: any) {
      setEditError(e.message);
    } finally {
      setEditLoading(false);
    }
  };

  const submit = () => {
    form.submit();
  };

  const openModal = () => {
    setModal(true);
  };

  const handleDeleteButtonClick = () => {
    confirm({
      title: t("Підтвердити видалення"),
      okText: t("Видалити"),
      okType: "danger",
      cancelText: t("Скасувати"),
      onOk: handleDelete,
    });
  };

  const formDefaultValue = {
    name: group.name,
    selectedProductsParameters: group.productsParameters.map((p) => ({
      productsParameterUUID: p.productsParameter.productsParameterUUID,
      name: p.productsParameter.name,
      required: p.required,
    })),
  };

  return (
    <Space className="table-actions" size="middle">
      <Button size="small" icon={<EditOutlined />} onClick={openModal} />
      <Button
        size="small"
        icon={<DeleteOutlined />}
        loading={deleteLoading}
        danger
        onClick={handleDeleteButtonClick}
      />
      <Modal
        maskClosable={false}
        okText={t("Зберегти")}
        title={<div style={{ marginBottom: 20 }}>{t("Редагування групи")}</div>}
        open={modal}
        onCancel={() => setModal(false)}
        onOk={submit}
        okButtonProps={{ loading: editLoading }}
      >
        <ProductsParametersGroupForm
          form={form}
          error={editError}
          onSubmit={handleEdit}
          defaultValue={formDefaultValue}
        />
      </Modal>
    </Space>
  );
};

export default ProductsParametersTableActions;
