import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Tabs, TabsProps } from "antd";
import { Pans, PriceFormulas } from "./components";

const AdminSettingsPanel = () => {
  const { t } = useTranslation();
  const [tabKey, setTabKey] = useState("pans");

  const items: TabsProps["items"] = [
    {
      key: "pans",
      label: t("Лотки"),
      children: <Pans />,
    },
    {
      key: "priceFormulas",
      label: t("Цінові формули"),
      children: <PriceFormulas />,
    },
  ];

  return (
    <Tabs type="card" items={items} activeKey={tabKey} onTabClick={setTabKey} />
  );
};

export default AdminSettingsPanel;
