import { useTranslation } from "react-i18next";
import {
  ActionCreatorTypes,
  useCaseFormContext,
} from "@/components/CaseForm/CaseFormContext";
import DateSelect from "../DateSelect";
import styles from "../CaseDate/OrderDate.module.scss";

const DueDate = () => {
  const { t } = useTranslation();
  const { state, dispatch } = useCaseFormContext();

  const handleSelect = (value: string) => {
    dispatch({
      type: ActionCreatorTypes.SetDueDate,
      payload: value,
    });
  };

  return (
    <DateSelect
      name="dueDate"
      className={styles.date}
      label={t("Дата завершення")}
      value={state.dueDate}
      onSelect={handleSelect}
      type="due"
    />
  );
};

export default DueDate;
