import { AccountingTotal } from "@/root/models/accounting";
import { Input, Modal, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { t } from "i18next";
import { Dispatch, SetStateAction, useState } from "react";

import styles from "@/modules/MainPanel/components/DashboardTable.module.scss";

import accountingStyles from "../Accounting.module.scss";
import Tooth from "@/components/CaseJobs/Product/Tooth";
import InvoiceColumn from "@/modules/InvoicesPanel/components/InvoiceColumn";
import { formatNumber } from "@/utils/formatNumber";

interface AccountingTableProps {
  accountingChangeArray: AccountingTotal[] | [];
  selectedItem: AccountingTotal | undefined;
  setSelectedItem: Dispatch<SetStateAction<AccountingTotal | undefined>>;
}

const { confirm } = Modal;

const AccountingTable = ({
  accountingChangeArray,
  selectedItem,
  setSelectedItem,
}: AccountingTableProps) => {
  const handleDeleteTooth = (tooth: string) => {
    confirm({
      title: t("Підтвердити видалення"),
      okText: t("Видалити"),
      okType: "danger",
      cancelText: t("Скасувати"),
      onOk: () => {
        if (selectedItem) {
          const updatedTeeth = selectedItem?.teeth!.filter(
            (elem) => elem !== tooth
          );

          const updateSelectedItem = { ...selectedItem, teeth: updatedTeeth };

          setSelectedItem(updateSelectedItem);
        }
      },
    });
  };

  //Cell editing
  const [editingKey, setEditingKey] = useState("");

  const isEditing = (record: string) => record === editingKey;

  const edit = (record: string) => {
    setEditingKey(record);
  };

  const save = () => {
    setEditingKey("");
  };

  const itemsColumns: ColumnsType<AccountingTotal> = [
    {
      title: t("Елемент"),
      dataIndex: "item",
      key: "name",
      render: (item: AccountingTotal["item"]) => <div>{item?.name}</div>,
    },
    {
      width: 160,
      title: t("Зуби"),
      dataIndex: "teeth",
      key: "tooth",
      render: (teeth: AccountingTotal["teeth"], item: AccountingTotal) => {
        return (
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(3, 1fr)",
              gap: 5,
            }}
          >
            {teeth?.map((tooth, index) => (
              <div
                style={{
                  pointerEvents:
                    item.rowUUID !== selectedItem?.rowUUID ? "none" : "auto",
                }}
              >
                <Tooth
                  isLast={teeth!.length - 1 === index}
                  tooth={tooth}
                  handleDeleteClick={() => handleDeleteTooth(tooth)}
                />
              </div>
            ))}
          </div>
        );
      },
    },
    {
      width: 40,
      title: t("Кільк"),
      dataIndex: "quantity",
      key: "quantity",
      render: (quantity: AccountingTotal["quantity"]) => (
        <div style={{ textAlign: "center" }}>{quantity}</div>
      ),
    },
    {
      width: 100,
      title: t("Ціна"),
      dataIndex: "price",
      key: "price",
      render: (price: AccountingTotal["price"], item: AccountingTotal) => {
        {
          const editable = isEditing(item.rowUUID + "price");

          if (!!item?.invoices?.length) {
            return (
              <div style={{ textAlign: "center" }}>
                {formatNumber(Number(price))}
              </div>
            );
          } else {
            return editable ? (
              <Input
                autoFocus
                type="number"
                value={Number(price)}
                onBlur={() => save()}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  if (selectedItem) {
                    setSelectedItem({
                      ...selectedItem,
                      price: Number(e.target.value),
                      sum: Number(e.target.value) * selectedItem.quantity,
                      discountSum:
                        (Number(e.target.value) *
                          selectedItem.quantity *
                          selectedItem.discountPercent) /
                        100,
                      total:
                        Number(e.target.value) -
                        (Number(e.target.value) *
                          selectedItem.quantity *
                          selectedItem.discountPercent) /
                          100,
                    });
                  }
                }}
              />
            ) : (
              <div
                className={accountingStyles.editableCellHover}
                style={{ textAlign: "center" }}
                onClick={() => edit(item.rowUUID + "price")}
              >
                {formatNumber(Number(price))}
              </div>
            );
          }
        }
      },
    },
    {
      width: 100,
      title: t("Сума"),
      dataIndex: "sum",
      key: "sum",
      render: (sum: AccountingTotal["sum"]) => <div>{formatNumber(sum)}</div>,
    },
    {
      width: 100,
      title: t("Знижка %"),
      dataIndex: "discountPercent",
      key: "discountPercent",
      render: (
        discountPercent: AccountingTotal["discountPercent"],
        item: AccountingTotal
      ) => {
        const editable = isEditing(item.rowUUID + "discPerc");

        if (!!item?.invoices?.length) {
          return <div style={{ textAlign: "center" }}>{discountPercent}</div>;
        } else {
          return editable ? (
            <Input
              autoFocus
              type="number"
              value={discountPercent}
              onBlur={() => save()}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (selectedItem) {
                  setSelectedItem({
                    ...selectedItem,
                    discountPercent: Number(e.target.value),
                    discountSum: Number(
                      (
                        (Number(e.target.value) / 100) *
                        selectedItem.sum
                      ).toFixed(1)
                    ),
                    total:
                      selectedItem.sum -
                      Number(
                        (
                          (Number(e.target.value) / 100) *
                          selectedItem.sum
                        ).toFixed(1)
                      ),
                  });
                }
              }}
            />
          ) : (
            <div
              className={accountingStyles.editableCellHover}
              style={{ textAlign: "center" }}
              onClick={() => edit(item.rowUUID + "discPerc")}
            >
              {discountPercent}
            </div>
          );
        }
      },
    },
    {
      width: 100,
      title: t("Знижка"),
      dataIndex: "discountSum",
      key: "discountSum",
      render: (
        discountSum: AccountingTotal["discountSum"],
        item: AccountingTotal
      ) => {
        const editable = isEditing(item.rowUUID + "discSum");

        if (!!item?.invoices?.length) {
          return (
            <div style={{ textAlign: "center" }}>
              {formatNumber(discountSum)}
            </div>
          );
        } else {
          return editable ? (
            <Input
              type="number"
              value={discountSum}
              autoFocus
              onBlur={() => save()}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (selectedItem) {
                  setSelectedItem({
                    ...selectedItem,
                    discountPercent:
                      (Number(e.target.value) * 100) / selectedItem.sum,
                    discountSum: Number(e.target.value),

                    total: selectedItem.sum - Number(e.target.value),
                  });
                }
              }}
            />
          ) : (
            <div
              className={accountingStyles.editableCellHover}
              style={{ textAlign: "center" }}
              onClick={() => edit(item.rowUUID + "discSum")}
            >
              {formatNumber(discountSum)}
            </div>
          );
        }
      },
    },
    {
      width: 100,
      title: t("Всього"),
      dataIndex: "total",
      key: "total",
      render: (total: AccountingTotal["total"]) => (
        <div>{formatNumber(total)}</div>
      ),
    },
    {
      width: 80,
      title: t("Рахунки-фактури"),
      dataIndex: "invoices",
      key: "invoice",
      render: (invoices: AccountingTotal["invoices"]) => {
        return invoices?.map((elem) => <InvoiceColumn invoiceInfo={elem} />);
      },
    },
  ];

  return (
    <Table
      className={accountingStyles.table}
      rowClassName={(item) =>
        selectedItem?.rowUUID === item.rowUUID ? styles.activeRow : undefined
      }
      columns={itemsColumns}
      dataSource={accountingChangeArray?.map((item) => item)}
      pagination={false}
      rowKey={(record) => record.rowUUID}
      onRow={(item) => ({
        onClick: () => {
          setSelectedItem(item);
        },
      })}
    />
  );
};

export default AccountingTable;
