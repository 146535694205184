import { Case } from "@/root/models/case";
import { t } from "i18next";
import { QRCodeSVG } from "qrcode.react";

import styles from "./ActOfCompletedWorksPrint.module.scss";
import tablePrintFormStyles from "../PrintForm/PrintForm.module.scss";

import { Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { AccountingTotal } from "@/root/models/accounting";
import Tooth from "../CaseJobs/Product/Tooth";
import PrintHeader from "../LaboratoryCaseForm/components/PrintMenu/components/PrintHeader";
import { InfoProps } from "@/hooks/useInfo";
import { formatNumber } from "@/utils/formatNumber";
import classNames from "classnames";

interface ActOfCompletedWorksPrintProps {
  medCase: Case;
  info: InfoProps | undefined;
}

const ActOfCompletedWorksPrint = ({
  medCase,
  info,
}: ActOfCompletedWorksPrintProps) => {
  const itemsColumns: ColumnsType<AccountingTotal> = [
    {
      width: 24,
      title: `№`,
      dataIndex: "amount",
      key: "amount",
      render: (_: any, __: any, index) => (
        <div style={{ textAlign: "center" }}>{index + 1}</div>
      ),
    },
    {
      title: t("Елемент"),
      dataIndex: "item",
      key: "name",
      render: (item: AccountingTotal["item"]) => <div>{item?.name}</div>,
    },
    {
      width: 120,
      title: t("Зуби"),
      dataIndex: "teeth",
      key: "tooth",
      render: (teeth: AccountingTotal["teeth"]) => {
        return (
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(3, 1fr)",
              gap: 5,
            }}
          >
            {teeth?.map((tooth, index) => (
              <Tooth tooth={tooth} isLast={teeth!.length - 1 === index} />
            ))}
          </div>
        );
      },
    },
    {
      width: 40,
      title: t("Кільк"),
      dataIndex: "quantity",
      key: "quantity",
      render: (quan: AccountingTotal["quantity"]) => (
        <div style={{ textAlign: "center" }}>{quan}</div>
      ),
    },
    {
      width: 80,
      title: t("Сума без знижки"),
      dataIndex: "sum",
      key: "sum",
      render: (sum: AccountingTotal["sum"]) => <div>{formatNumber(sum)}</div>,
    },
    {
      width: 80,
      title: t("Знижка"),
      dataIndex: "discountSum",
      key: "discountSum",
      render: (discountSum: AccountingTotal["discountSum"]) => (
        <div>{formatNumber(discountSum)}</div>
      ),
    },
    {
      width: 80,
      title: t("Всього"),
      dataIndex: "total",
      key: "total",
      render: (total: AccountingTotal["total"]) => (
        <div>{formatNumber(total)}</div>
      ),
    },
  ];
  return (
    <>
      <PrintHeader info={info} />
      <div style={{ marginTop: 30 }}>
        <div className={styles.wrapper}>
          <div className={styles.column}>
            <div>
              {t("Замовлення №")}: {medCase.caseNumber}
            </div>
            <div>
              {t("Замовник")}: {medCase.client?.name}
            </div>
            <div>
              {t("Лікар")}: {medCase.clientsEmployee?.name}
            </div>
            <div>
              {t("Пацієнт")}: {medCase.patient?.name}
            </div>
          </div>
          <div className={classNames([styles.column, styles.lastColumn])}>
            <div>
              {t("Дата створення")}: {medCase.arrival?.slice(0, 10)}
            </div>
            <div>
              {t("Фактична дата виконання")}:{" "}
              {medCase.completionDate?.slice(0, 10)}
            </div>
            <div>
              {t("Крайня дата виконання")}: {medCase.dueDate?.slice(0, 10)}
            </div>
            <div>
              {t("Відповідальний")}: {medCase.activeHandler?.activeHandlerName}
            </div>
          </div>
          <div>
            {medCase.caseNumber && (
              <div className={styles.qrCode}>
                <div>
                  <QRCodeSVG height={120} value={medCase.caseNumber} />
                </div>
                <div style={{ fontSize: 16 }}>{medCase.caseNumber}</div>
              </div>
            )}
          </div>
        </div>
        <Table
          className={tablePrintFormStyles.tablePrint}
          style={{ marginTop: 20 }}
          columns={itemsColumns}
          dataSource={medCase.accounting?.filter(
            (elem) => !!elem.invoices?.length
          )}
          pagination={false}
          rowKey={(record) => record.rowUUID}
        />
      </div>
    </>
  );
};

export default ActOfCompletedWorksPrint;
