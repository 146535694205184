import { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import "antd/dist/reset.css";
import "./index.scss";

import App from "./App";
import { ReduxProvide, LanguageProvider } from "@/components";

import "./i18n";

const queryClient = new QueryClient({
  defaultOptions: { queries: { staleTime: 1000 * 20 * 60 } },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <ReduxProvide>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Suspense fallback="loading">
          <LanguageProvider>
            <App />
          </LanguageProvider>
        </Suspense>
      </BrowserRouter>
    </QueryClientProvider>
  </ReduxProvide>
);
