import { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";

const AdminPage = lazy(() => import("@/pages/AdminPage"));
const ClientPage = lazy(() => import("@/pages/ClientPage"));
const ErrorPage = lazy(() => import("@/pages/ErrorPage"));
const LabPage = lazy(() => import("@/pages/LabPage"));
const LoginPage = lazy(() => import("@/pages/LoginPage"));
const CourierPage = lazy(() => import("@/pages/CourierPage"));
const PatientPortal = lazy(
  () => import("@/pages/PatientSidePages/PatientPortal")
);
const CaseWarranty = lazy(
  () => import("@/pages/PatientSidePages/CaseWarranty")
);
const PortalActivationPage = lazy(() => import("@/pages/PortalActivationPage"));

const Router = () => {
  return (
    <Routes>
      <Route
        path="login"
        element={
          <Suspense fallback={<div />}>
            <LoginPage />
          </Suspense>
        }
      />
      <Route
        path="client/*"
        element={
          <Suspense fallback={<div />}>
            <ClientPage />
          </Suspense>
        }
      />
      <Route
        path="admin/*"
        element={
          <Suspense fallback={<div />}>
            <AdminPage />
          </Suspense>
        }
      />
      <Route
        path="lab/*"
        element={
          <Suspense fallback={<div />}>
            <LabPage />
          </Suspense>
        }
      />
      <Route
        path="courier/*"
        element={
          <Suspense fallback={<div />}>
            <CourierPage />
          </Suspense>
        }
      />
      <Route
        path="api/patients-portal"
        element={
          <Suspense fallback={<div />}>
            <PortalActivationPage />
          </Suspense>
        }
      />
      <Route
        path="api/patients-portal/:patientCode"
        element={
          <Suspense fallback={<div />}>
            <PatientPortal />
          </Suspense>
        }
      />
      <Route
        path="api/case-warranty"
        element={
          <Suspense fallback={<div />}>
            <PortalActivationPage />
          </Suspense>
        }
      />
      <Route
        path="api/case-warranty/:warrantyNumber"
        element={
          <Suspense fallback={<div />}>
            <CaseWarranty />
          </Suspense>
        }
      />
      <Route
        path="*"
        element={
          <Suspense fallback={<div />}>
            <ErrorPage />
          </Suspense>
        }
      />
    </Routes>
  );
};

export default Router;
