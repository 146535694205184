import PrintHeader from "@/components/LaboratoryCaseForm/components/PrintMenu/components/PrintHeader";
import PrintForm from "@/components/PrintForm";
import { InfoProps } from "@/hooks/useInfo";
import { Payment } from "@/root/models/payment";
import { Table } from "antd";

import printFormStyles from "@/components/PrintForm/PrintForm.module.scss";

import { ColumnsType } from "antd/es/table";
import { t } from "i18next";
import { formatNumber } from "@/utils/formatNumber";

interface PaymentPrintProps {
  info: InfoProps | undefined;
  payment: Payment | undefined;
}

const PaymentPrint = ({ info, payment }: PaymentPrintProps) => {
  const itemsColumns: ColumnsType<Payment.Items> = [
    {
      width: 24,
      title: `№`,
      dataIndex: "amount",
      key: "amount",
      render: (_: any, __: any, index) => (
        <div style={{ textAlign: "center" }}>{index + 1}</div>
      ),
    },
    {
      width: 150,
      title: t("Дата створення"),
      dataIndex: "case",
      key: "arrival",
      render: (instance: Payment.Items["case"]) => (
        <span>{instance.arrival?.split(" ").slice(0, 1).join("")}</span>
      ),
    },
    {
      width: 200,
      title: t("Замовлення №"),
      dataIndex: "caseNumber",
      key: "caseNumber",
      render: (_: any, instance) => <span>{instance.case?.caseNumber}</span>,
    },
    {
      width: 350,
      title: t("Пацієнт"),
      dataIndex: "case",
      key: "patient",
      render: (instance: Payment.Items["case"]) => (
        <span>{instance?.patient?.name}</span>
      ),
    },
    {
      width: 350,
      title: t("Лікар"),
      dataIndex: "case",
      key: "clientsEmployee",
      render: (instance: Payment.Items["case"]) => (
        <span>{instance?.clientsEmployee?.name}</span>
      ),
    },
    {
      width: 130,
      title: t("Сума"),
      dataIndex: "sum",
      key: "sum",
      render: (_: any, instance) => (
        <span>{instance.sum && formatNumber(instance.sum)}</span>
      ),
    },
  ];
  return (
    <div>
      <PrintHeader info={info} />
      {payment && (
        <PrintForm
          type="payment"
          instanceNumber={payment.paymentNumber}
          date={payment.date}
          clientName={payment.client?.name}
          sum={payment.amount}
        />
      )}

      <Table
        className={printFormStyles.tablePrint}
        style={{ marginTop: 20 }}
        columns={itemsColumns}
        dataSource={payment?.items
          .filter((item) => !!item.invoice.paid)
          .sort(
            (a, b) =>
              Number(new Date(a.case.arrival)) -
              Number(new Date(b.case.arrival))
          )}
        pagination={false}
        rowKey={(record) => record.rowUUID}
      />
    </div>
  );
};

export default PaymentPrint;
