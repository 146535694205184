import { useTranslation } from "react-i18next";
import { Result } from "antd";
import useAuth from "@/hooks/useAuth";
import { Router } from "@/components";
import { Loader } from "@/components/common";
import useVersionControl from "@/hooks/versionControl";

const App = () => {
  const { t } = useTranslation();
  const { isReady } = useAuth();
  const { versionError } = useVersionControl();

  if (versionError) {
    return <Result status="warning" title={t("Версії не співпадають")} />;
  }

  if (isReady) {
    return <Router />;
  }

  return (
    <div style={{ height: "100vh" }}>
      <Loader center />
    </div>
  );
};

export default App;
