import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { Modal } from "antd";
import { useForm } from "antd/es/form/Form";
import { CreatePatientPayload } from "@/api/patients";
import { PatientForm } from "@/components";
import usePatients from "@/hooks/usePatients";

interface CreatePatientModalProps {
  open: boolean;
  onCancel: () => void;
}

const CreatePatientModal = ({ open, onCancel }: CreatePatientModalProps) => {
  const { t } = useTranslation();
  const [form] = useForm();

  const onCreateSuccess = () => {
    onCancel();
    form.resetFields();
  };

  const {
    createPatient,
    createLoading: loading,
    createError: error,
  } = usePatients({ onCreateSuccess });

  const handleCreate = async (values: CreatePatientPayload) => {
    createPatient({
      ...values,
      birthDate: dayjs(values.birthDate).format("DD.MM.YYYY"),
    });
  };

  const submit = () => {
    form.submit();
  };

  return (
    <Modal
      maskClosable={false}
      okText={t("Створити")}
      title={<div style={{ marginBottom: 20 }}>{t("Створення пацієнта")}</div>}
      open={open}
      onCancel={onCancel}
      onOk={submit}
      okButtonProps={{ loading }}
    >
      <PatientForm form={form} handleSubmit={handleCreate} error={error} />
    </Modal>
  );
};

export default CreatePatientModal;
