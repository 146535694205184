import { Invoice } from "@/root/models/invoice";
import { ColumnsType } from "antd/es/table";
import { t } from "i18next";
import { Table } from "antd";
import { Dispatch, SetStateAction } from "react";
import { Pagination } from "@/components/common";
import useTableScrollHeight from "@/hooks/TableHooks/useTableScrollHeight";
import useTablePaginationRange from "@/hooks/TableHooks/useTablePaginationRange";
import useModalOpen from "@/hooks/useModalOpen";
import { useIsLab } from "@/root/models/isLab";
import { formatNumber } from "@/utils/formatNumber";
import InvoicesTableActions from "./InvoicesTableActions";

import styles from "../InvoicePanel.module.scss";

interface InvoicesTableProps {
  tabHeight: number;
  setPage: Dispatch<SetStateAction<number>>;
  invoices: Invoice[];
  pages: number | undefined;
  getInvoicesLoading: boolean;
  page: number;
  selectedInvoice: Invoice | undefined;
  setSelectedInvoice?: (invoice: Invoice) => void;
}

const InvoicesTable = ({
  tabHeight,
  setPage,
  invoices,
  getInvoicesLoading,
  pages,
  page,
  selectedInvoice,
  setSelectedInvoice,
}: InvoicesTableProps) => {
  const { isLab } = useIsLab();

  const { tableScrollHeight, paginationRef } = useTableScrollHeight(tabHeight);

  const { paginationRange, tableRef } =
    useTablePaginationRange(getInvoicesLoading);

  const { modal, onHandleCancel, isModalOpen, setModalType } = useModalOpen();

  const casesColumns: ColumnsType<Invoice> = [
    {
      width: 150,
      title: "№",
      dataIndex: "invoiceNumber",
      key: "invoiceNumber",
    },
    {
      width: 300,
      title: t("Дата створення"),
      dataIndex: "date",
      key: "date",
      render: (date: Invoice["date"]) => (
        <span>{date.split(" ").slice(0, 1).join("")}</span>
      ),
    },
    {
      title: t("Опис"),
      dataIndex: "description",
      key: "description",
    },
    {
      width: 150,
      title: t("Повна сума"),
      dataIndex: "amount",
      key: "amount",
      render: (amount: Invoice["amount"]) => <div>{formatNumber(amount)}</div>,
    },
    {
      width: 60,
      title: "",
      key: "action",
      render: (_: any, invoice: Invoice) => (
        <InvoicesTableActions
          invoice={invoice}
          modal={modal}
          onModalViewOpen={() => {
            setModalType("view");
            isModalOpen(invoice.invoiceUUID);
          }}
          onCancel={onHandleCancel}
        />
      ),
    },
  ];

  if (isLab) {
    casesColumns.splice(1, 0, {
      width: 200,
      title: t("Контрагент"),
      dataIndex: "client",
      key: "client",
      render: (client: Invoice["client"]) => <span>{client?.name}</span>,
    });
  }

  return (
    <div>
      <Table
        rowClassName={(invoice) =>
          selectedInvoice?.invoiceUUID === invoice.invoiceUUID
            ? styles.activeRow
            : undefined
        }
        style={{ userSelect: "none" }}
        size={isLab ? "small" : "large"}
        scroll={{
          y: tableScrollHeight,
        }}
        ref={tableRef}
        columns={casesColumns}
        dataSource={invoices.map((i) => ({ ...i, key: i.invoiceUUID }))}
        pagination={false}
        loading={getInvoicesLoading}
        onRow={(invoice) => ({
          onClick: () => {
            setSelectedInvoice?.(invoice);
          },
          onDoubleClick: (e) => {
            e.stopPropagation();
            if (isLab && !modal) {
              setModalType("edit");
              isModalOpen(invoice.invoiceUUID);
            } else if (!isLab && !modal) {
              setModalType("view");
              isModalOpen(invoice.invoiceUUID);
            }
          },
        })}
      />
      <div
        ref={paginationRef}
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "15px",
        }}
      >
        <Pagination
          currentPage={page}
          pages={pages || 1}
          paginationRange={paginationRange}
          setCurrentPage={setPage}
        />
      </div>
    </div>
  );
};

export default InvoicesTable;
