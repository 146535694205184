import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { Checkbox, DatePicker, Form, InputNumber, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import {
  ActionCreatorTypes,
  useCaseFormContext,
} from "@/components/CaseForm/CaseFormContext";
import { DATE_FORMAT } from "@/root/consts";
import { CaseStatusEnum } from "@/root/types";
import { Product } from "@/root/models/product";
import { CheckboxChangeEvent } from "antd/es/checkbox";

const Warranty = () => {
  const { t } = useTranslation();
  const { state, dispatch } = useCaseFormContext();

  const handleSelect = (value: string) => {
    dispatch({
      type: ActionCreatorTypes.SetWarrantyActivationDate,
      payload: value,
    });
  };

  const handleWarrantyNumberChange = (value: number | null) => {
    dispatch({
      type: ActionCreatorTypes.SetWarrantyNumber,
      payload: value,
    });
  };

  const warrantyActivationDate = state.warrantyActivationDate;
  const warrantyActivated = state.warrantyActivated;
  const isDelivered = state.status?.caseStatusUUID === CaseStatusEnum.Delivered;
  const warrantyNumber = state.warrantyNumber;

  useEffect(() => {
    if (isDelivered && !warrantyActivationDate) {
      dispatch({
        type: ActionCreatorTypes.SetWarrantyActivationDate,
        payload: dayjs().format(DATE_FORMAT),
      });
    }
  }, [isDelivered, warrantyActivationDate]);

  const products = state.jobs
    .flatMap((job) => job.products.map((p) => p.product))
    .filter(Boolean) as Product[];

  const columns: ColumnsType = [
    {
      title: t("Виріб"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("Гарантійний строк, міс."),
      dataIndex: "warrantyPeriod",
      key: "warrantyPeriod",
    },
    {
      title: t("Строк закінчення гарантії"),
      dataIndex: "warrantyEndDate",
      key: "warrantyEndDate",
    },
  ];

  const tableData = products
    .filter((elem) => elem.warrantyPeriod > 0)
    .map((p) => ({
      ...p,
      warrantyEndDate:
        p.warrantyPeriod > 0
          ? dayjs(new Date(warrantyActivationDate))
              .add(p.warrantyPeriod, "month")
              .format(DATE_FORMAT)
          : "",
      key: p.productUUID,
    }));

  return (
    <>
      <div style={{ display: "flex", gap: 25 }}>
        <Form.Item name="warrantyActivated" label={t("Гарантія активована")}>
          <Checkbox
            checked={warrantyActivated}
            onChange={(e: CheckboxChangeEvent) =>
              dispatch({
                type: ActionCreatorTypes.SetWarrantyActivated,
                payload: e.target.checked,
              })
            }
          />
        </Form.Item>
        <Form.Item
          name="warrantyNumber"
          label={t("Номер гарантії")}
          initialValue={warrantyNumber ? warrantyNumber : 0}
        >
          <InputNumber onChange={handleWarrantyNumberChange} />
        </Form.Item>
        <Form.Item
          name="warrantyActivationDate"
          initialValue={
            warrantyActivationDate
              ? dayjs(new Date(warrantyActivationDate))
              : undefined
          }
          label={t("Дата активації гарантії")}
        >
          <DatePicker
            format={DATE_FORMAT}
            disabled={!isDelivered}
            onSelect={(v) => handleSelect(dayjs(v).format(DATE_FORMAT))}
          />
        </Form.Item>
      </div>
      {/*@ts-ignore*/}
      <Table pagination={false} columns={columns} dataSource={tableData} />
    </>
  );
};

export default Warranty;
