import { useMutation, useQueryClient } from "@tanstack/react-query";
import { CreatePanPayload, PansApi } from "@/api/admin/pans";
import { Pan } from "@/root/models/pan";

const useEditPan = (onSuccess: () => void) => {
  const queryClient = useQueryClient();
  const editPanMutation = useMutation({
    mutationFn: PansApi.edit,
    onSuccess: (pan: Pan) => {
      queryClient.setQueryData(["pans"], (prev: Pan[] | undefined) => {
        onSuccess();
        return (prev || []).map((prevPan) => {
          if (prevPan.panUUID === pan.panUUID) {
            return pan;
          }
          return prevPan;
        });
      });
    },
  });

  const editPan = (id: string, payload: CreatePanPayload) => {
    editPanMutation.mutate({ id, payload });
  };

  const error = (editPanMutation.error as Error)?.message as string;

  return { editPan, loading: editPanMutation.isLoading, error };
};

export default useEditPan;
