import { getNestedFilters } from "@/modules/ProductCatalog/components/ProductsParameters/components/ProductParameterForm";
import { ProductParameter } from "@/root/models/productParameter";
import { Filter } from "@/root/models/filter";

export const isParameterFiltered = (
  parameter: {
    required: boolean;
    productsParameter: ProductParameter;
  },
  filters: Filter[],
  filter: { isFilter: boolean; value: string | undefined }
) => {
  const isFilterValues = parameter.productsParameter.filtersValues.length;
  const isFilter = parameter.productsParameter.type?.name
    .toLowerCase()
    .includes("filter");

  return (
    isFilterValues &&
    !isFilter &&
    filter.isFilter &&
    !(
      filter.value &&
      getNestedFilters(
        filters,
        parameter.productsParameter.filtersValues.map((f) => f.filterUUID)
      )
        .map((f) => f.filterUUID)
        .includes(filter.value)
    )
  );
};
