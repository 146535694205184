import { DATE_FORMAT, DATE_FORMAT_2 } from "@/root/consts";
import dayjs from "dayjs";

export const generateFileName = (
  instanseName: string,
  instanseNumber: string,
  instanseDate: string
) => {
  const dateFormat =
    process.env.REACT_APP_LANG === "en" ? DATE_FORMAT : DATE_FORMAT_2;
  const date = dayjs(instanseDate).format(dateFormat);

  document.title = `${instanseName}_${instanseNumber}_${date}`;
  window.addEventListener("afterprint", () => {
    document.title = `Labax`;
  });
  return false;
};
