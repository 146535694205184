import { useTranslation } from "react-i18next";

import { Alert, Button, Input, Modal, Space } from "antd";
import { DeleteOutlined } from "@ant-design/icons";

import {
  ActionCreatorTypes,
  CaseFormState,
  useCaseFormContext,
} from "@/components/CaseForm/CaseFormContext";

const { confirm } = Modal;

interface JobsProps {
  job?: CaseFormState.Job;
}

const Jobs = ({ job }: JobsProps) => {
  const { t } = useTranslation();
  const { state, dispatch } = useCaseFormContext();

  const handleJobEdit = (jobID: string, value: string) => {
    dispatch({
      type: ActionCreatorTypes.EditJob,
      payload: { jobID, jobName: value },
    });
  };

  const handleDeleteJobClick = (jobID: string) => {
    confirm({
      title: t("Підтвердити видалення"),
      okText: t("Видалити"),
      okType: "danger",
      cancelText: t("Скасувати"),
      onOk: () => {
        dispatch({ type: ActionCreatorTypes.DeleteJob, payload: jobID });
      },
    });
  };
  const isLastJob = job
    ? state.jobs.length ===
      state.jobs.findIndex((j) => j.jobID === job.jobID) + 1
    : true;

  return (
    <Space direction="vertical">
      {job && (
        <>
          <Input
            defaultValue={job.jobName}
            onBlur={(e) => handleJobEdit(job.jobID, e.target.value)}
          />
          {!!job.error && <Alert message={job.error} type="error" />}
          <Button
            style={isLastJob ? { marginBottom: 30 } : undefined}
            icon={<DeleteOutlined />}
            onClick={() => handleDeleteJobClick(job.jobID)}
          >
            {t("Видалити роботу")}
          </Button>
        </>
      )}
    </Space>
  );
};

export default Jobs;
