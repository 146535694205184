import { Invoice } from "@/root/models/invoice";
import { Modal } from "antd";
import { t } from "i18next";
import styles from "../InvoicePanel.module.scss";
import InvoiceForm from "./InvoiceForm";
import { FormInstance } from "antd/lib/form/Form";
import classNames from "classnames";

interface InvoiceModalProps {
  modal: string | null;
  invoice: Invoice;
  form: FormInstance<any>;
  onCancel: () => void;
}

const InvoiceViewModal = ({
  modal,
  invoice,
  form,
  onCancel,
}: InvoiceModalProps) => {
  return (
    <>
      <Modal
        width={"calc(100vw - 100px)"}
        open={modal === invoice.invoiceUUID}
        maskClosable={false}
        destroyOnClose={true}
        title={
          <div style={{ marginBottom: 20 }}>
            {t("Рахунок-фактура")}&nbsp;
            {`№${invoice.invoiceNumber}`}
          </div>
        }
        style={{
          top: 10,
          left: 30,
          marginRight: 40,
          height: "calc(100% - 20px)",
          maxHeight: "100vh",
          overflow: "hidden",
        }}
        bodyStyle={{
          height: "calc(100vh - 115px)",
        }}
        onCancel={onCancel}
        className={classNames(styles.modal_footer_hidden)}
      >
        <InvoiceForm form={form} type="view" invoiceCases={invoice.cases} />
      </Modal>
    </>
  );
};

export default InvoiceViewModal;
