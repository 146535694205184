import { t } from "i18next";
import styles from "./PrintForm.module.scss";
import { QRCodeSVG } from "qrcode.react";
import { formatNumber } from "@/utils/formatNumber";

interface PrintFormProps {
  type: "invoice" | "payment";
  instanceNumber: string;
  date: string;
  clientName: string | undefined;
  sum: number;
}

const PrintForm = ({
  type,
  instanceNumber,
  date,
  clientName,
  sum,
}: PrintFormProps) => {
  return (
    <div style={{ display: "flex", marginTop: 13, gap: 50 }}>
      <div className={styles.form} style={{ flex: 2.5 }}>
        <div className={styles.formItem}>
          <div>
            {type === "invoice"
              ? `${t("Рахунок-фактура")}:`
              : `${t("Оплата")}:`}
          </div>
          <div>{`${instanceNumber} ${t("від")} ${date.split(" ")[0]}`}</div>
        </div>
        <div className={styles.formItem}>
          <div>{`${t("Замовник")}:`}</div>
          <div>{clientName}</div>
        </div>
        <div className={styles.formItem}>
          <div>
            {type === "invoice"
              ? `${t("Загальна вартість замовлень та виробів")}:`
              : `${t("Загалом сплачено")}:`}
          </div>
          <div>{`${formatNumber(sum)} ${t("Грн.")}`}</div>
        </div>
      </div>
      <div style={{ flex: 0.5, paddingTop: 10 }}>
        {instanceNumber && (
          <div>
            <QRCodeSVG height={90} value={instanceNumber} />
          </div>
        )}
      </div>
    </div>
  );
};

export default PrintForm;
