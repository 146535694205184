import DashboardTables from "@/modules/MainPanel/components/DashboardTables";

const Cases = () => {
  return (
    <DashboardTables
      withAdvancedSearch
      date={""}
      widgetCollapsed={true}
      setWidgetCollapsed={() => {}}
    />
  );
};

export default Cases;
