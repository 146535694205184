import { useEffect, useMemo, useState } from "react";
import ChooseProduct from "../../ChooseProduct";
import { Block } from "@/components/common";
import { Product } from "@/root/models/product";
import {
  ActionCreatorTypes,
  useCaseFormContext,
} from "@/components/CaseForm/CaseFormContext";

const ClickDropdown = () => {
  const { state, dispatch } = useCaseFormContext();
  const [open, setOpen] = useState(false);
  const { jobProduct } = useMemo(() => {
    const job = state.jobs.find((job) => job.jobID === state.activeJobID);
    const jobProduct = job?.products.find(
      (product) => product.jobProductUUID === job.activeProductID
    );
    return { job, jobProduct };
  }, [state.jobs, state.activeJobID]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (jobProduct?.teethStatus === "deleted") {
        clearTimeout(timeout);
        return;
      }
      setOpen(true);
    }, 3000);
    return () => clearTimeout(timeout);
  }, []);

  const handleSelect = ({
    product,
    teethColor,
  }: {
    product: Product;
    teethColor: string | undefined;
  }) => {
    dispatch({
      type: ActionCreatorTypes.SetProduct,
      payload: {
        product,
        teethColor,
      },
    });

    setOpen(false);
  };

  if (!open) {
    return null;
  }

  return (
    <Block>
      <ChooseProduct onSelect={handleSelect} />
    </Block>
  );
};

export default ClickDropdown;
