import { ChatTypes } from "@/root/models/chat";
import makeRequest from "./makeRequest";
import { apiBaseurl } from "./baseurl";

const ChatNotificationsApi = {
  getChatNotifications: async () => {
    const data = await makeRequest<ChatTypes[]>({
      url: `${apiBaseurl}/chat-notifications`,
    });
    return data;
  },

  delete: async ({
    chatUUID,
    messageUUID,
  }: {
    chatUUID: string;
    messageUUID: string;
  }): Promise<void> => {
    await makeRequest({
      url: `${apiBaseurl}/chat-notifications?chatUUID=${chatUUID}&messageUUID=${messageUUID}`,
      method: "delete",
    });
  },
};

export default ChatNotificationsApi;
