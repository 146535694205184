import { CSSProperties } from "react";
import { useTranslation } from "react-i18next";

import { Button, Table } from "antd";
import { ColumnsType } from "antd/es/table";

import { CouriersAvailability } from "@/root/models/couriersAvailability";
import useCouriersAvailability from "@/hooks/useCouriersAvailability";
import { Block } from "@/components/common";

import styles from "@/modules/MainPanel/components/DashboardTable.module.scss";

import { CouriersAvailabilityStatusEnum } from "@/root/types";

interface CouriersWidgetProps {
  style?: CSSProperties;
}

const CouriersWidget = ({ style }: CouriersWidgetProps) => {
  const { t } = useTranslation();
  const {
    couriersAvailabilities,
    getCouriersAvailabilitiesLoading,
    editCouriersAvailability,
  } = useCouriersAvailability({});

  const changeCourierStatusOnClick = (
    courierUUID: string,
    courierAvailabilityStatusUUID: string
  ) => {
    editCouriersAvailability({ courierUUID, courierAvailabilityStatusUUID });
  };

  const casesColumns: ColumnsType<CouriersAvailability> = [
    {
      dataIndex: "courierUUID",
      key: "courierUUID",
      title: t("Кур'єр"),
      render: (courier: CouriersAvailability["courierUUID"]) => (
        <span>{courier.name}</span>
      ),
    },
    {
      dataIndex: "startTime",
      key: "startTime",
      title: t("Час початку"),
    },
    {
      dataIndex: "endTime",
      key: "endTime",
      title: t("Час закінчення"),
    },
    {
      dataIndex: "courierAvailabilityStatus",
      key: "courierAvailabilityStatus",
      title: t("Статус"),
      render: (
        courierAvailabilityStatus: CouriersAvailability["courierAvailabilityStatus"]
      ) => <span>{courierAvailabilityStatus.name}</span>,
    },
    {
      dataIndex: "",
      key: "action",
      render: (couierInfo: CouriersAvailability) => {
        const courierAvailable =
          couierInfo?.courierAvailabilityStatus
            ?.courierAvailabilityStatusUUID ===
          CouriersAvailabilityStatusEnum["Available"];

        return (
          <Button
            onClick={() => {
              changeCourierStatusOnClick(
                couierInfo?.courierUUID.courierUUID,
                courierAvailable
                  ? CouriersAvailabilityStatusEnum["Unavailable"]
                  : CouriersAvailabilityStatusEnum["Available"]
              );
            }}
          >
            {courierAvailable
              ? t("Зробити недоступним")
              : t("Зробити доступним")}
          </Button>
        );
      },
    },
  ];

  return (
    <Block style={style}>
      <div className={styles.container}>
        <Table
          columns={casesColumns}
          dataSource={couriersAvailabilities?.map((a) => ({
            ...a,
            key: a.courierUUID.courierUUID,
          }))}
          loading={getCouriersAvailabilitiesLoading}
          pagination={false}
          style={{ overflow: "auto" }}
        />
      </div>
    </Block>
  );
};

export default CouriersWidget;
