import { createSlice } from "@reduxjs/toolkit";

const dataFilterSlice = createSlice({
  name: "dataFilter",
  initialState: {
    eventDate: "",
  },
  reducers: {
    setEventDate: (state, action) => {
      state.eventDate = action.payload;
    },
  },
});

export const { setEventDate } = dataFilterSlice.actions;

export default dataFilterSlice.reducer;
