import { FocusEvent, useEffect, useState } from "react";

import { InputNumber } from "antd";

import styles from "./ListItem.module.scss";

interface ListItemProps {
  item: { isGroup: boolean; name: string };
  selected: boolean;
  price?: number;
  onPrice: (price: number | undefined) => void;
}

const ListItem = ({ item, selected, price, onPrice }: ListItemProps) => {
  const [value, setValue] = useState<number>();

  useEffect(() => {
    if (price === undefined || price === value) {
      return;
    }
    setValue(price);
  }, [price]);

  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value) {
      onPrice(Number(e.target.value));
    } else {
      onPrice(undefined);
    }
  };

  return (
    <div className={styles.container}>
      <span>{item.name}</span>
      {!item.isGroup && selected && (
        <InputNumber
          min={0}
          className={styles.input}
          key={price}
          size="small"
          onClick={(e) => {
            e.stopPropagation();
          }}
          autoFocus
          value={value}
          onChange={(value) => setValue(value || undefined)}
          onBlur={handleBlur}
          type="number"
        />
      )}
    </div>
  );
};

export default ListItem;
