import { PaymentCreatePayloadProps } from "@/api/payments";
import { Block } from "@/components/common";
import { usePayments } from "@/hooks/usePayments";
import { Button, Space, notification } from "antd";
import { useForm } from "antd/es/form/Form";
import { t } from "i18next";
import { useEffect } from "react";
import styles from "@/components/LaboratoryCaseForm/LaboratoryCaseForm.module.scss";
import { useNavigate } from "react-router-dom";
import { PaymentForm } from "../components/PaymentForm";

const MakePayment = () => {
  const [form] = useForm();

  const navigate = useNavigate();

  const navigateToPayments = () => {
    // it is safe to use -1 (back), because access to /create page is through other pages
    navigate(-1);
  };

  const onCreateSuccess = () => {
    navigateToPayments();
    notification.success({
      message: t("Оплата була успішно створена"),
    });
  };

  const {
    createPayment,
    createError,
    createLoading: loading,
  } = usePayments({ onCreateSuccess });

  //Create error
  useEffect(() => {
    if (!!createError) {
      notification.error({
        message: createError,
      });
    }
  }, [createError]);

  const onPaymentCreate = () => {
    form.submit();
  };

  const paymentCreate = async (values: PaymentCreatePayloadProps) => {
    createPayment({ ...values });
  };

  return (
    <>
      <div className={styles.actionButtons}>
        <Button htmlType="button" onClick={() => navigateToPayments()}>
          {t("Назад")}
        </Button>
        <Space>
          <Button
            htmlType="button"
            disabled={loading}
            onClick={() => navigateToPayments()}
          >
            {t("Скасувати")}
          </Button>
          <Button
            onClick={() => {
              onPaymentCreate();
            }}
            loading={loading}
          >
            {t("Зберегти")}
          </Button>
        </Space>
      </div>
      <Block style={{ height: "calc(100vh - 62px)" }}>
        <PaymentForm
          handlePaymentCreate={paymentCreate}
          form={form}
          paymentItems={[]}
          type="create"
        />
      </Block>
    </>
  );
};

export default MakePayment;
