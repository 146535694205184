import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import CreateAdminClientModal from "./CreateAdminClientModal";

const CreateAdminClient = () => {
  const { t } = useTranslation();
  const [modal, setModal] = useState(false);

  return (
    <div>
      <Button onClick={() => setModal(true)}>{t("Створити клієнта")}</Button>
      <CreateAdminClientModal open={modal} onCancel={() => setModal(false)} />
    </div>
  );
};

export default CreateAdminClient;
