import useTableScrollHeight from "@/hooks/TableHooks/useTableScrollHeight";
import { ModifiedInvoiceWithItems } from "@/root/models/invoice";
import { InvoiceWithChildren } from "@/root/models/invoiceWithChildren";
import Table, { ColumnsType } from "antd/es/table";
import { TableRowSelection } from "antd/es/table/interface";
import { t } from "i18next";
import { Key, useState } from "react";
import InvoiceItemsView from "./InvoiceItemsView";
import { Button, Modal } from "antd";
import useModalOpen from "@/hooks/useModalOpen";
import { DeleteOutlined, EyeOutlined } from "@ant-design/icons";

const { confirm } = Modal;

interface CustomCasesTableProps {
  tableInstancesArray: ModifiedInvoiceWithItems[] | undefined;
  setSelectedInstances?: (cases: ModifiedInvoiceWithItems[]) => void;
  type?: string;
  mainTable?: boolean;
  setSelectedRowKeys?: (key: Key[]) => void;
  selectedRowKeys?: Key[];
  alreadyAddedInstancesArray?: ModifiedInvoiceWithItems[];
  setAlreadyAddedInstances?: (prevArray: ModifiedInvoiceWithItems[]) => void;
}

const CustomInvoicesTable = ({
  tableInstancesArray = [] as ModifiedInvoiceWithItems[],
  setSelectedInstances,
  type = "",
  mainTable,
  setSelectedRowKeys,
  selectedRowKeys,
  alreadyAddedInstancesArray,
  setAlreadyAddedInstances,
}: CustomCasesTableProps) => {
  //Enables children select
  const [checkStrictly] = useState(false);

  const { modal, onHandleCancel, isModalOpen } = useModalOpen();

  const { tableScrollHeight } = useTableScrollHeight(
    type !== "create" ? 360 : 300
  );

  //On row select change
  const onSelectChange = (
    newSelectedRowKeys: Key[],
    selectedRows: InvoiceWithChildren[]
  ) => {
    //At first adds to each child parent in order to group invoice cases by same invoice number
    const selectedItemsArray = selectedRows.reduce(
      (result: InvoiceWithChildren[], current) => {
        if (!current.invoiceNumber) {
          const addParent = tableInstancesArray.find(
            (parent) => parent.invoiceNumber === current?.parent
          );

          if (addParent) {
            result.push({
              ...addParent,
              children: addParent.children.filter(
                (child) => child.case.caseUUID === current.case?.caseUUID
              ),
            });
          }
        }

        return result;
      },
      []
    );

    //Group parent by invoiceNumber and ...children
    const groupedArray = selectedItemsArray.reduce(
      (result: InvoiceWithChildren[], current) => {
        const existingItem = result.find(
          (item) => item.invoiceNumber === current.invoiceNumber
        );

        if (existingItem) {
          existingItem.children = [
            //@ts-ignore
            ...existingItem.children,
            //@ts-ignore
            ...current.children,
          ];
        } else {
          result.push(current);
        }

        return result;
      },
      []
    );

    setSelectedInstances?.(groupedArray as ModifiedInvoiceWithItems[]);

    setSelectedRowKeys?.(newSelectedRowKeys);
  };

  const invoicesColumns: ColumnsType<InvoiceWithChildren> = [
    {
      width: 200,
      title: t("Рахунок-фактура № / Замовлення №"),
      dataIndex: "invoiceNumber/caseNumber",
      key: "invoiceNumber/caseNumber",
      render: (_: any, instance) => (
        <span>{instance.invoiceNumber || instance.case?.caseNumber}</span>
      ),
    },
    {
      width: 150,
      title: t("Дата створення"),
      dataIndex: "date",
      key: "date",
      render: (_: any, instance) => (
        <span>{instance.date?.split(" ").slice(0, 1).join("")}</span>
      ),
    },
    {
      width: 200,
      title: t("Лікар"),
      dataIndex: "clientsEmployee",
      key: "name",
      render: (_: any, instance) => (
        <span>{instance.case?.clientsEmployee.name}</span>
      ),
    },
    {
      width: 200,
      title: t("Пацієнт"),
      dataIndex: "patient",
      key: "name",
      render: (_: any, instance) => <span>{instance.case?.patient.name}</span>,
    },
    {
      width: 130,
      title: t("Повна сума"),
      dataIndex: "amount",
      key: "amount",
      render: (_: any, instance) => (
        <div>{instance.amount || instance.sum}</div>
      ),
    },
    {
      width: 60,
      dataIndex: "action",
      key: "action",
      render: (_: any, instance) => {
        if (instance.case?.caseUUID) {
          return (
            <>
              <Button
                size="middle"
                icon={<EyeOutlined />}
                onClick={() => instance?.key && isModalOpen(instance?.key)}
              />
              <InvoiceItemsView
                modalOpen={modal}
                instance={instance}
                tableInstancesArray={tableInstancesArray}
                onHandleCancel={onHandleCancel}
                type={type}
              />
            </>
          );
        } else if (
          instance?.invoiceUUID &&
          type === "edit" &&
          !!alreadyAddedInstancesArray?.length
        ) {
          if (
            alreadyAddedInstancesArray.find(
              (elem) => elem.invoiceNumber === instance?.invoiceNumber
            )
          ) {
            return (
              <Button
                size="middle"
                icon={<DeleteOutlined />}
                danger
                // loading={deleteLoading}
                onClick={() =>
                  confirm({
                    title: t("Підтвердити видалення"),
                    okText: t("Видалити"),
                    okType: "danger",
                    cancelText: t("Скасувати"),
                    onOk: () =>
                      setAlreadyAddedInstances?.(
                        alreadyAddedInstancesArray.filter(
                          (elem) =>
                            elem.invoiceNumber !== instance?.invoiceNumber
                        )
                      ),
                  })
                }
              />
            );
          }
        }
      },
    },
  ];

  const rowSelection: TableRowSelection<any> = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  //To properly use expandable Atnd table as we need, we have to make such a big structure with children.
  const data: InvoiceWithChildren[] = tableInstancesArray?.map((elem) => ({
    key: elem.invoiceUUID,
    invoiceNumber: elem.invoiceNumber,
    invoiceUUID: elem.invoiceUUID,
    amount: elem.children.reduce((prev, cur) => prev + cur?.sum, 0),
    date: elem.date,
    children: elem.children?.map((child) => ({
      key: child.case.caseUUID + elem.invoiceNumber,
      parent: elem.invoiceNumber,
      sum: child?.sum,
      case: {
        caseNumber: child.case?.caseNumber,
        caseUUID: child.case?.caseUUID,
        clientsEmployee: {
          clientsEmployeeUUID: child.case?.clientsEmployee?.clientsEmployeeUUID,
          name: child.case?.clientsEmployee?.name,
        },
        patient: {
          patientUUID: child.case?.patient?.patientUUID,
          name: child.case?.patient?.name,
        },
      },
    })),
  }));

  return (
    <Table
      rowSelection={mainTable ? { ...rowSelection, checkStrictly } : undefined}
      columns={invoicesColumns}
      pagination={false}
      dataSource={data}
      scroll={{
        y: tableScrollHeight,
      }}
      onRow={(instance: InvoiceWithChildren) => ({
        onDoubleClick: () => {
          isModalOpen(instance.case?.caseUUID + instance.invoiceUUID);
        },
      })}
    />
  );
};

export default CustomInvoicesTable;
