import { Button, Space, Typography } from "antd";
import { t } from "i18next";
import { Block, Print } from "@/components/common";
import InvoicesTable from "../components/InvoicesTable";
import SearchInvoice from "../components/SearchInvoice";
import useInvoices from "@/hooks/useInvoices";
import { useEffect, useState } from "react";
import { useIsLab } from "@/root/models/isLab";
import { FcFilledFilter } from "react-icons/fc";
import { useNavigate } from "react-router-dom";
import { pages } from "@/root/consts";
import { UserTypeEnum } from "@/root/types";
import { useAppSelector } from "@/hooks/redux";
import InvoicePrint from "../components/InvoicePrint";
import useInfo from "@/hooks/useInfo";
import { Invoice } from "@/root/models/invoice";
import { generateFileName } from "@/utils/generateFileName";

const { Title } = Typography;

export interface InvoiceSearchParamsGroup {
  adminClients: string | undefined;
  date: string | undefined;
  paid: undefined | boolean;
}

const Invoices = () => {
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const userType = useAppSelector((state) => state.userReducer.user?.userType);

  const { isLab } = useIsLab();

  const [full, setFull] = useState(false);

  const { info } = useInfo();

  const [searchParams, setSearchParams] = useState<InvoiceSearchParamsGroup>({
    adminClients: "",
    date: "",
    paid: undefined,
  });

  const { invoices, invoicePages, getInvoicesLoading, refetch } = useInvoices({
    page,
    clientUUID: searchParams.adminClients || undefined,
    date: searchParams.date || undefined,
    paid: searchParams.paid,
  });

  const [selectedInvoice, setSelectedInvoice] = useState<Invoice>();

  useEffect(() => {
    refetch();
  }, []);

  const onHandleSearch = ({
    adminClients = "",
    date = "",
    paid = undefined,
  }: InvoiceSearchParamsGroup) => {
    setSearchParams({
      adminClients,
      date,
      paid,
    });
  };

  return (
    <Block style={{ height: "calc(100vh - 20px)", overflow: "auto" }}>
      <Space
        direction="vertical"
        style={{ justifyContent: "center", width: "50%" }}
      >
        <Title level={3} style={{ textAlign: "center" }}>
          {t("Рахунки-фактури")}
        </Title>
        <Space style={{ marginBottom: 30 }}>
          <Button
            onClick={() => setFull(!full)}
            icon={<FcFilledFilter style={{ width: 20, height: 20 }} />}
          />
          {isLab && (
            <Button
              onClick={() => {
                if (userType?.userTypeUUID === UserTypeEnum["Lab's admin"]) {
                  navigate(pages.admin.invoices.create.path);
                } else {
                  navigate(pages.lab.invoices.create.path);
                }
              }}
            >
              {t("Створити рахунок-фактуру")}
            </Button>
          )}
          <Button
            htmlType="button"
            onClick={() => {
              if (selectedInvoice) {
                generateFileName(
                  "Invoice",
                  selectedInvoice.invoiceNumber,
                  selectedInvoice.date
                );

                window.print();
              }
            }}
            disabled={!selectedInvoice}
          >
            {t("Надрукувати рахунок-фактуру")}
          </Button>
          <Print>
            <InvoicePrint info={info} invoice={selectedInvoice} />
          </Print>
        </Space>
        {full && <SearchInvoice onSearch={onHandleSearch} />}
      </Space>

      <InvoicesTable
        tabHeight={120}
        setPage={setPage}
        invoices={invoices}
        pages={invoicePages}
        getInvoicesLoading={getInvoicesLoading}
        page={page}
        selectedInvoice={selectedInvoice}
        setSelectedInvoice={setSelectedInvoice}
      />
    </Block>
  );
};

export default Invoices;
