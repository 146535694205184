import { Button, Modal, Space, notification } from "antd";
import { DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import { t } from "i18next";
import { useForm } from "antd/es/form/Form";
import { useAppSelector } from "@/hooks/redux";
import { UserTypeEnum } from "@/root/types";
import { useEffect } from "react";
import styles from "../../InvoicesPanel/InvoicePanel.module.scss";
import { Payment } from "@/root/models/payment";
import { usePayments } from "@/hooks/usePayments";
import { PaymentForm } from "./PaymentForm";
import { useNavigate } from "react-router-dom";
import { pages } from "@/root/consts";

const { confirm } = Modal;

interface PaymentsTableActionsProps {
  payment: Payment;
  modal: string | null;
  onCancel: () => void;
  onModalViewOpen: () => void;
  refetch: () => void;
}

const PaymentsTableActions = ({
  payment,
  modal,
  onCancel,
  onModalViewOpen,
}: PaymentsTableActionsProps) => {
  const userType = useAppSelector((state) => state.userReducer.user?.userType);

  const [form] = useForm();
  const navigate = useNavigate();

  const onDeleteError = (message: string) => {
    notification.error({
      message,
    });
  };

  const onDeleteSuccess = () => {
    notification.success({
      message: t("Оплата була успішно видалена"),
    });
  };

  const { deletePayment, deleteLoading } = usePayments({
    onDeleteError,
    onDeleteSuccess,
  });

  const handleDelete = async () => {
    await deletePayment(payment.paymentUUID); //--
  };

  const handleDeleteButtonClick = () => {
    confirm({
      title: t("Підтвердити видалення"),
      okText: t("Видалити"),
      okType: "danger",
      cancelText: t("Скасувати"),
      onOk: handleDelete,
    });
  };

  useEffect(() => {
    if (modal) {
      form.setFieldsValue({
        paymentNumber: payment.paymentNumber,
        client: payment.client.clientUUID,
        description: payment.description,
        amount: payment.amount,
      });
    }
  }, [modal]);

  return (
    <>
      {userType?.userTypeUUID === UserTypeEnum["Employee"] ||
      userType?.userTypeUUID === UserTypeEnum["Lab's admin"] ? (
        <Space className="table-actions" direction="vertical">
          <Button
            size="small"
            icon={<EyeOutlined />}
            onClick={onModalViewOpen}
          />
          <Button
            size="small"
            icon={<EditOutlined />}
            onClick={() => {
              if (userType?.userTypeUUID === UserTypeEnum["Lab's admin"]) {
                navigate(pages.admin.payments.edit.path, { state: payment });
              } else {
                navigate(pages.lab.payments.edit.path, { state: payment });
              }
            }}
          />
          <Button
            size="small"
            icon={<DeleteOutlined />}
            danger
            loading={deleteLoading}
            onClick={handleDeleteButtonClick}
          />
        </Space>
      ) : (
        <Button size="small" icon={<EyeOutlined />} onClick={onModalViewOpen} />
      )}
      <Modal
        destroyOnClose
        width={"calc(100vw - 100px)"}
        style={{
          top: 10,
          left: 30,
          marginRight: 40,
          height: "calc(100% - 20px)",
          maxHeight: "100vh",
          overflow: "hidden",
        }}
        bodyStyle={{
          height: "calc(100vh - 115px)",
        }}
        open={modal === payment.paymentUUID}
        maskClosable={false}
        title={
          <div style={{ marginBottom: 20 }}>
            {t("Оплата")}&nbsp;
            {`№${payment.paymentNumber}`}
          </div>
        }
        onCancel={onCancel}
        className={(styles.invoiceModal_content, styles.modal_footer_hidden)}
      >
        <PaymentForm form={form} paymentItems={payment.items} type="view" />
      </Modal>
    </>
  );
};

export default PaymentsTableActions;
