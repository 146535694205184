import { ChatsApi } from "@/api/chats";
import { useAppSelector } from "@/hooks/redux";
import { ChatTypes } from "@/root/models/chat";
import { UserTypeEnum } from "@/root/types";
import { Dropdown, MenuProps, notification } from "antd";
import { t } from "i18next";
import { ReactNode } from "react";

interface DropdownChatOptionsProps {
  children: ReactNode;
  messageUUID: string;
  selectedChat: ChatTypes | undefined;
  refetch: () => void;
}

const DropdownMessageOptions = ({
  children,
  messageUUID,
  selectedChat,
  refetch,
}: DropdownChatOptionsProps) => {
  const userType = useAppSelector((state) => state.userReducer.user?.userType);

  //Delete message
  const messageDelete = async () => {
    try {
      if (selectedChat) {
        await ChatsApi.messageAction(selectedChat?.chatUUID, {
          action: "delete",
          messageUUID: messageUUID,
          message: null,
        });
        await refetch();
        // await refetchChatNotif();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const items: MenuProps["items"] = [
    {
      label: t("Видалити повідомлення"),
      key: "delete",
      onClick: () => {
        if (userType?.userTypeUUID === UserTypeEnum[`Lab's admin`]) {
          messageDelete();
        } else {
          notification.error({
            message: t("Немає прав доступу!"),
          });
        }
      },
    },
  ];

  return (
    <Dropdown menu={{ items }} trigger={["contextMenu"]}>
      {children}
    </Dropdown>
  );
};

export default DropdownMessageOptions;
