import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Dropdown, MenuProps, Space } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { Case } from "@/root/models/case";
import { Print } from "@/components/common";
import { ActOfCompletedWorksPrint, LaboratoryCase } from "@/components";
import Warranty from "../../../Warranty";
import { Settings } from "@/root/models/settings";
import { SettingsApi } from "@/api/settings";
import useInfo from "@/hooks/useInfo";
import { useLocation } from "react-router-dom";
import { generateFileName } from "@/utils/generateFileName";

interface PrintMenuProps {
  medCase: Case;
  teethFormulaNumberingUUID: string;
}

enum PrintMenuButtons {
  Invoice = "Invoice",
  Case = "Case",
  Warranty = "Warranty",
}

const PrintMenu = ({ medCase, teethFormulaNumberingUUID }: PrintMenuProps) => {
  const { t } = useTranslation();

  const { info } = useInfo();
  const [printKey, setPrintKey] = useState<PrintMenuButtons>();

  const [settings, setSettings] = useState<Settings>();

  const location = useLocation();

  useEffect(() => {
    (async () => {
      try {
        const response = await SettingsApi.getSettings();
        setSettings(response);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const handleMenuClick: MenuProps["onClick"] = (e) => {
    const key = e.key;

    if (key === PrintMenuButtons.Case) {
      setPrintKey(PrintMenuButtons.Case);

      // print after setting print key

      setTimeout(() => {
        generateFileName("Case", medCase.caseNumber, medCase.arrival);
        window.print();
      }, 1000);
    }

    if (key === PrintMenuButtons.Warranty) {
      setPrintKey(PrintMenuButtons.Warranty);

      // print after setting print key
      setTimeout(() => {
        generateFileName(
          "Warranty",
          medCase.warrantyNumber.toString(),
          medCase.arrival
        );
        window.print();
      });
    }
    if (key === PrintMenuButtons.Invoice) {
      setPrintKey(PrintMenuButtons.Invoice);

      // print after setting print key
      setTimeout(() => {
        generateFileName("Case", medCase.caseNumber, medCase.arrival);
        window.print();
      });
    }
  };

  const items: MenuProps["items"] = [
    {
      label: t("Замовлення", { count: 1 }),
      key: PrintMenuButtons.Case,
    },
    {
      label: t("Рахунок-фактура"),
      key: PrintMenuButtons.Invoice,
    },
    {
      label: t("Гарантія"),
      key: PrintMenuButtons.Warranty,
    },
  ];

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  return (
    <div>
      <Dropdown menu={menuProps}>
        <Button>
          <Space>
            {t("Друк")}
            <DownOutlined />
          </Space>
        </Button>
      </Dropdown>
      {printKey === PrintMenuButtons.Case && (
        <Print>
          <LaboratoryCase
            medCase={medCase}
            teethFormulaNumberingUUID={teethFormulaNumberingUUID}
            info={info}
          />
        </Print>
      )}
      {printKey === PrintMenuButtons.Warranty && medCase.patient && (
        <Print>
          <Warranty
            warrantyNumber={medCase.warrantyNumber}
            secret={medCase.patient.secret}
            patientCode={medCase.patient.patientCode}
            settings={settings}
          />
        </Print>
      )}
      {printKey === PrintMenuButtons.Invoice && (
        <Print
          style={
            location.pathname === "/admin/cases/edit"
              ? { marginLeft: "auto", marginRight: "auto", width: "80%" }
              : undefined
          }
        >
          <ActOfCompletedWorksPrint medCase={medCase} info={info} />
        </Print>
      )}
    </div>
  );
};

export default PrintMenu;
