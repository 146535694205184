import { useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { notification } from "antd";
import { LaboratoryCaseForm, CaseForm } from "@/components";
import { CaseFormState } from "@/components/CaseForm/CaseFormContext";
import { CreateCasePayload, CasesApi } from "@/api/client/cases";
import { EmployeeCase, Case } from "@/root/models/case";
import useProducts from "@/hooks/useProducts";
import { Loader } from "@/components/common";
import { useAppSelector } from "@/hooks/redux";
import { pages } from "@/root/consts";
import { UserTypeEnum } from "@/root/types";
import { jobsToFormJobs } from "@/utils/JobsToFormJobs";

const EditCase = () => {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const userType = useAppSelector((state) => state.userReducer.user?.userType);
  const { findProductByID, getProductsLoading, getParameterGroup } =
    useProducts();
  const [medCase, setCase] = useState<Case>();

  useEffect(() => {
    (async () => {
      const caseUUID = location.state as string | undefined;
      if (!caseUUID) {
        return;
      }
      const fullCase = await CasesApi.getFullCase(caseUUID);
      setCase(fullCase);
    })();
  }, [location.state]);

  if (!location.state) {
    switch (userType?.userTypeUUID) {
      case UserTypeEnum["Client's employee"]:
      case UserTypeEnum["Client's admin"]:
        return <Navigate to={pages.client.cases.path} />;
      case UserTypeEnum["Employee"]:
        return <Navigate to={pages.lab.cases.path} />;
      case UserTypeEnum["Lab's admin"]:
        return <Navigate to={pages.admin.cases.path} />;
      default:
        return <Navigate to={pages.login.path} />;
    }
  }

  const navigateToCase = () => {
    // can safely use -1 (back), because access to /edit page is through other pages
    navigate(-1);
  };

  const handleEdit = async (values: CreateCasePayload, close: boolean) => {
    try {
      if (!medCase) {
        return;
      }

      setLoading(true);
      const newCase = await CasesApi.edit(medCase.caseUUID, values);
      if (close) {
        navigateToCase();
      }
      return newCase;
    } catch (e: any) {
      notification.error({
        message: e.message,
      });
    } finally {
      setLoading(false);
    }
  };

  if (getProductsLoading) {
    return null;
  }

  const jobs: CaseFormState.Job[] = medCase
    ? jobsToFormJobs(medCase.jobs, findProductByID, getParameterGroup)
    : [];

  const accountinWithItemName =
    medCase &&
    medCase.accounting?.map((elem, i) => ({
      ...elem,
      itemName: medCase.jobs[0]?.products[i]?.product?.name,
      itemUUID: medCase.jobs[0]?.products[i]?.product?.productUUID,
    }));

  const initialState: CaseFormState | undefined = medCase && {
    onlyDetailsEdit: !(
      medCase.patient &&
      medCase.clientsEmployee &&
      medCase.arrival &&
      medCase.dueDate &&
      medCase.teethFormulaType
    ),
    panUUID: null,
    status: medCase.status,
    caseNumber: medCase.caseNumber,
    saveButtonActive: true,
    caseUUID: medCase.caseUUID,
    clientsEmployee: medCase.clientsEmployee,
    patient: medCase.patient,
    activeHandler: medCase.activeHandler.activeHandlerUUID
      ? medCase.activeHandler
      : null,
    client: medCase.client,
    teethFormulaNumberingUUID:
      medCase.teethFormulaNumbering?.teethFormulaNumberingUUID,
    teethFormulaTypeUUID: medCase.teethFormulaType.teethFormulaTypeUUID,
    teethFormulaInputDisabled: true,
    description: medCase.description,
    arrival: medCase.arrival,
    dueDate: medCase.dueDate,
    completionDate: medCase.completionDate,
    warrantyActivationDate: medCase.warrantyActivationDate,
    warrantyActivated: medCase.warrantyActivated,
    warrantyNumber: medCase.warrantyNumber,
    activeJobID: jobs?.[0].jobID,
    attachments: medCase.attachments,
    jobs,
    accounting: accountinWithItemName || [],
  };

  if (getProductsLoading) {
    return null;
  }

  const Form = () => {
    if (!userType || !medCase) {
      return;
    }

    switch (userType.userTypeUUID) {
      case UserTypeEnum["Client's employee"]:
      case UserTypeEnum["Client's admin"]:
        return (
          <CaseForm
            initialState={initialState}
            loading={loading}
            onFinish={handleEdit}
            onCancel={navigateToCase}
          />
        );
      case UserTypeEnum["Employee"]:
      case UserTypeEnum["Lab's admin"]:
        const employeeCase = medCase as EmployeeCase;
        const panUUID =
          "panUUID" in employeeCase.pan ? employeeCase.pan.panUUID : null;
        return (
          <LaboratoryCaseForm
            medCase={employeeCase}
            initialState={
              initialState ? { ...initialState, panUUID } : undefined
            }
            loading={loading}
            onFinish={handleEdit}
            onCancel={navigateToCase}
            type="edit"
          />
        );
    }
  };

  return <>{!!medCase && userType ? Form() : <Loader />}</>;
};

export default EditCase;
