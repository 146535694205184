import { useTranslation } from "react-i18next";
import { Modal } from "antd";
import { useForm } from "antd/es/form/Form";
import { CreateClientEmployeePayload } from "@/api/client/employees";
import useClientEmployees from "@/hooks/useClientEmployees";
import ClientEmployeeForm from "@/modules/ClientEmployees/components/ClientEmployeeForm";

interface CreateClientEmployeeModalProps {
  open: boolean;
  onCancel: () => void;
  clientUUID?: string;
}

const CreateClientEmployeeModal = ({
  open,
  onCancel,
  clientUUID,
}: CreateClientEmployeeModalProps) => {
  const { t } = useTranslation();
  const [form] = useForm();

  const onCreateSuccess = () => {
    onCancel();
    form.resetFields();
  };

  const {
    createClientEmployee,
    createLoading: loading,
    createError: error,
  } = useClientEmployees({ clientUUID, onCreateSuccess });

  const handleCreate = async (values: CreateClientEmployeePayload) => {
    createClientEmployee({
      ...values,
      clientUUID,
      disableAccess: false,
    });
  };

  const submit = () => {
    form.submit();
  };

  return (
    <Modal
      maskClosable={false}
      okText={t("Створити")}
      title={
        <div style={{ marginBottom: 20 }}>{t("Створення співробітника")}</div>
      }
      open={open}
      onCancel={onCancel}
      onOk={submit}
      okButtonProps={{ loading }}
    >
      <ClientEmployeeForm
        form={form}
        handleSubmit={handleCreate}
        error={error}
      />
    </Modal>
  );
};

export default CreateClientEmployeeModal;
