import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button, Modal, notification, Space } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useForm } from "antd/es/form/Form";
import { AdminClient } from "@/root/models/adminClient";
import { CreateAdminClientPayload } from "@/api/admin/clients";
import useAdminClients from "@/hooks/useAdminClients";
import AdminClientForm from "./AdminClientForm";

const { confirm } = Modal;

interface TableActionsProps {
  client: AdminClient;
  modal: string | null;
  onCancel: () => void;
  onEditSuccess: () => void;
  onModalOpen: () => void;
}

const AdminClientTableActions = ({
  client,
  modal,
  onEditSuccess,
  onCancel,
  onModalOpen,
}: TableActionsProps) => {
  const { t } = useTranslation();
  const [form] = useForm();

  const onDeleteError = (message: string) => {
    notification.error({
      message,
    });
  };

  const {
    deleteAdminClient,
    deleteLoading,
    editAdminClient,
    editLoading,
    editError,
  } = useAdminClients({ onDeleteError, onEditSuccess });

  const handleDelete = async () => {
    await deleteAdminClient(client.clientUUID);
  };

  const handleEdit = (values: CreateAdminClientPayload) => {
    editAdminClient(client.clientUUID, values);
  };

  const submit = () => {
    form.submit();
  };

  const onHandleOpen = () => {
    onModalOpen();
  };

  useEffect(() => {
    if (modal) {
      form.setFieldsValue(client);
    }
  }, [modal]);

  const handleDeleteButtonClick = () => {
    confirm({
      title: t("Підтвердити видалення"),
      okText: t("Видалити"),
      okType: "danger",
      cancelText: t("Скасувати"),
      onOk: handleDelete,
    });
  };

  return (
    <Space className="table-actions" size="middle">
      <Button size="small" icon={<EditOutlined />} onClick={onHandleOpen} />
      <Button
        size="small"
        icon={<DeleteOutlined />}
        loading={deleteLoading}
        danger
        onClick={handleDeleteButtonClick}
      />
      <Modal
        maskClosable={false}
        okText={t("Зберегти")}
        title={
          <div style={{ marginBottom: 20 }}>
            {t("Редагування співробітника")}
          </div>
        }
        open={modal === client.clientUUID}
        onCancel={onCancel}
        onOk={submit}
        okButtonProps={{ loading: editLoading }}
      >
        <AdminClientForm
          form={form}
          handleSubmit={handleEdit}
          error={editError}
        />
      </Modal>
    </Space>
  );
};

export default AdminClientTableActions;
