import { GroupedInvoiceCase, Invoice } from "@/root/models/invoice";
import Table, { ColumnsType } from "antd/es/table";
import { t } from "i18next";
import styles from "./CustomCasesTable.module.scss";
import type { TableRowSelection } from "antd/es/table/interface";
import { Key, useState } from "react";
import InvoiceColumn from "@/modules/InvoicesPanel/components/InvoiceColumn";
import { CaseWithChildren } from "@/root/models/caseWithChildren";
import useTableScrollHeight from "@/hooks/TableHooks/useTableScrollHeight";
import { Button, Modal } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { formatNumber } from "@/utils/formatNumber";

const { confirm } = Modal;

interface CustomCasesTableProps {
  tableInstancesArray: GroupedInvoiceCase[] | undefined;
  type?: string;
  mainTable?: boolean;
  tableWithoutBorders?: boolean;
  setSelectedRowKeys?: (key: Key[]) => void;
  selectedRowKeys?: Key[];
  setAdditionalCases?: (prevArray: any) => any;
  alreadyAddedInstancesArray?: GroupedInvoiceCase[];
  setAlreadyAddedInstances?: (prevArray: GroupedInvoiceCase[]) => void;
}

const CustomCasesTable = ({
  tableInstancesArray = [] as GroupedInvoiceCase[],
  type,
  mainTable,
  tableWithoutBorders,
  selectedRowKeys,
  setSelectedRowKeys,
  setAdditionalCases,
  alreadyAddedInstancesArray,
  setAlreadyAddedInstances,
}: CustomCasesTableProps) => {
  //Enables children select
  const [checkStrictly] = useState(false);

  const { tableScrollHeight } = useTableScrollHeight(
    type === "view" ? 310 : type === "edit" ? 400 : 335
  );

  //On row select
  const onSelectChange = (
    newSelectedRowKeys: Key[],
    selectedRows: CaseWithChildren[]
  ) => {
    //At first adds to each child parent in order to group case items by same case number
    const selectedItemsArray = selectedRows.reduce(
      (result: CaseWithChildren[], current) => {
        if (!current.case?.caseNumber) {
          const addParent = tableInstancesArray.find(
            (parent) => parent.case?.caseNumber === current?.parent
          );

          if (addParent) {
            result.push({
              ...addParent,
              children: addParent.children.filter(
                (child) => child.rowUUID === current.key
              ),
            });
          }
        }

        return result;
      },
      []
    );

    //Group parent by caseNumber and ...children
    const groupedArray = selectedItemsArray.reduce(
      (result: CaseWithChildren[], current) => {
        const existingItem = result.find(
          (item) => item.case?.caseNumber === current.case?.caseNumber
        );

        if (existingItem) {
          existingItem.children = [
            //@ts-ignore
            ...existingItem.children,
            //@ts-ignore
            ...current.children,
          ];
        } else {
          result.push(current);
        }

        return result;
      },
      []
    );

    setAdditionalCases?.(groupedArray);
    setSelectedRowKeys?.(newSelectedRowKeys);
  };

  const casesColumns: ColumnsType<CaseWithChildren> = [
    {
      width: 200,
      title: t("Замовлення № / Елемент"),
      dataIndex: "caseNumber/itemName",
      key: "caseNumber/itemName",
      render: (_: any, instance) => {
        return {
          children: (
            <div
              style={{
                paddingLeft: instance.item?.name ? 40 : 0,
              }}
            >
              {instance.case?.caseNumber || instance.item?.name}
            </div>
          ),
          props: {
            colSpan: instance.item?.name ? 3 : 1,
          },
        };
      },
    },
    {
      width: 150,
      title: t("Дата створення"),
      dataIndex: "arrival",
      key: "arrival",
      render: (_: any, instance) => {
        return {
          children: (
            <span>
              {instance.case?.arrival?.split(" ").slice(0, 1).join("")}
            </span>
          ),
          props: {
            colSpan: instance.item?.name ? 0 : 1,
          },
        };
      },
    },
    {
      width: 400,
      title: t("Лікар"),
      dataIndex: "clientsEmployee/invoice",
      key: "name/invoice",
      render: (_: any, instance) => {
        return {
          children: <span>{instance.case?.clientsEmployee.name}</span>,
          props: {
            colSpan: instance.item?.name ? 0 : 1,
          },
        };
      },
    },
    {
      width: 400,
      title: t("Пацієнт"),
      dataIndex: "patient",
      key: "name",
      render: (_: any, instance) => <span>{instance.case?.patient.name}</span>,
    },
    {
      width: 130,
      title: t("Сума"),
      dataIndex: "sum",
      key: "sum",
      render: (_: any, instance) => (
        <span>{instance.sum && formatNumber(instance.sum)}</span>
      ),
    },
    {
      width: type === "create" ? 400 : 300,
      title: t("Рахунки-фактури"),
      dataIndex: "invoices",
      key: "invoice",
      render: (instance: Invoice.InvoiceCase["invoices"]) => {
        return (
          <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
            {instance?.map((elem) => (
              <div key={elem.invoiceUUID}>
                <InvoiceColumn invoiceInfo={elem} />
              </div>
            ))}
          </div>
        );
      },
    },
  ];

  if (type === "edit" && !!alreadyAddedInstancesArray?.length) {
    casesColumns.splice(6, 0, {
      width: 130,
      dataIndex: "deleteAction",
      key: "deleteAction",
      render: (_: any, instance) => {
        if (
          alreadyAddedInstancesArray.find(
            (elem) => elem.case?.caseNumber === instance.case?.caseNumber
          )
        ) {
          return (
            <Button
              size="middle"
              icon={<DeleteOutlined />}
              danger
              // loading={deleteLoading}
              onClick={() =>
                confirm({
                  title: t("Підтвердити видалення"),
                  okText: t("Видалити"),
                  okType: "danger",
                  cancelText: t("Скасувати"),
                  onOk: () =>
                    setAlreadyAddedInstances?.(
                      alreadyAddedInstancesArray.filter(
                        (elem) =>
                          elem.case?.caseNumber !== instance.case?.caseNumber
                      )
                    ),
                })
              }
            />
          );
        }
      },
    });
  }

  //To properly use expandable Atnd table as we need, we have to make such a big structure with children.
  const data: CaseWithChildren[] = tableInstancesArray?.map((elem) => ({
    key: elem.case?.caseUUID,
    case: {
      caseNumber: elem.case?.caseNumber,
      caseUUID: elem.case?.caseUUID,
      arrival: elem.case?.arrival,
      clientsEmployee: {
        clientsEmployeeUUID: elem.case?.clientsEmployee?.clientsEmployeeUUID,
        name: elem.case?.clientsEmployee?.name,
      },
      patient: {
        patientUUID: elem.case?.patient?.patientUUID,
        name: elem.case?.patient?.name,
      },
    },
    sum: elem.children?.reduce((prev, cur) => prev + cur?.sum, 0),
    children: elem.children?.map((child) => ({
      key: child?.rowUUID,
      parent: elem.case.caseNumber,
      item: { itemUUID: child.item?.itemUUID, name: child.item?.name },
      itemType: child?.itemType,
      sum: child?.sum,
      invoices: child.invoices?.map((invoice) => ({
        invoiceUUID: invoice.invoiceUUID,
        invoiceNumber: invoice.invoiceNumber,
        paid: invoice.paid,
        date: invoice.date,
      })),
    })),
  }));

  const rowSelection: TableRowSelection<CaseWithChildren> = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <Table
      rowSelection={mainTable ? { ...rowSelection, checkStrictly } : undefined}
      className={tableWithoutBorders && styles.table_container}
      columns={casesColumns}
      pagination={false}
      dataSource={data}
      scroll={{
        y: tableScrollHeight,
      }}
    />
  );
};

export default CustomCasesTable;
