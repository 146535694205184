import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { Checkbox, Form, Tabs, TabsProps } from "antd";

import styles from "./Results.module.scss";

import { Block } from "@/components/common";
import { useCaseFormContext } from "@/components/CaseForm/CaseFormContext";
import ProductItem from "./ProductItem";
import { Description } from "@/components/CaseForm/components";
import { FileUpload } from "@/components";

const Results = () => {
  const { t } = useTranslation();
  const [tab, setTab] = useState("results");
  const { state } = useCaseFormContext();
  const [displayParameters, setShowParameter] = useState(true);

  const leftBlockItems: TabsProps["items"] = [
    {
      key: "results",
      label: t("Результати"),
      children: (
        <Block className={styles.block}>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              gap: 15,
            }}
          >
            <Checkbox
              checked={displayParameters}
              onChange={(e) => setShowParameter(e.target.checked)}
            >
              {t("Включити параметри")}
            </Checkbox>
          </div>
          <div style={{ marginTop: 15 }}>
            {t("Опис")}: {state.description}
          </div>
          {state.jobs.map((job) => (
            <Fragment key={job.jobID}>
              {job.products.map((product) => (
                <ProductItem
                  displayParameters={displayParameters}
                  key={product.jobProductUUID}
                  product={product}
                />
              ))}
            </Fragment>
          ))}
        </Block>
      ),
    },
    {
      key: "description",
      label: t("Опис"),
      children: (
        <Block className={styles.block}>
          <Description />
        </Block>
      ),
    },
    {
      key: "files",
      label: t("Файли"),
      children: (
        <Block className={styles.block}>
          <Form.Item name="files">
            <FileUpload />
          </Form.Item>
        </Block>
      ),
    },
  ];

  return (
    <div style={{ flex: 1 }}>
      <Tabs
        tabBarStyle={{ marginBottom: 0 }}
        type="card"
        items={leftBlockItems}
        activeKey={tab}
        onTabClick={setTab}
      />
    </div>
  );
};

export default Results;
