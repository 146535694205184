import { useTranslation } from "react-i18next";
import { Space, Table } from "antd";
import useUsers from "@/hooks/useUsers";
import { User } from "@/root/models/user";
import { UserType } from "@/root/models/userType";
import useModalOpen from "@/hooks/useModalOpen";
import UsersTableActions from "./components/UsersTableActions";
import CreateUser from "./components/CreateUser";

const Users = () => {
  const { t } = useTranslation();
  const { users, getUsersLoading } = useUsers({});

  const { modal, onHandleCancel, onEditSuccess, isModalOpen } = useModalOpen();

  const usersColumn = [
    {
      title: t("Ім'я.name"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("Пошта"),
      dataIndex: "email",
      key: "email",
    },
    {
      title: t("Тип користувача"),
      dataIndex: "userType",
      key: "userType",
      render: (type: UserType) => <span>{type.name}</span>,
    },
    {
      title: t("Контрагент"),
      dataIndex: "client",
      key: "client",
      render: (client: User["client"]) => <span>{client?.name}</span>,
    },
    {
      title: "",
      key: "action",
      render: (_: any, user: User) => (
        <UsersTableActions
          modal={modal}
          onCancel={onHandleCancel}
          onEditSuccess={onEditSuccess}
          onModalOpen={() => {
            isModalOpen(user.userUUID);
          }}
          user={user}
        />
      ),
    },
  ];

  return (
    <Space direction="vertical">
      <CreateUser />
      <Table
        style={{ userSelect: "none" }}
        columns={usersColumn}
        dataSource={users.map((p) => ({ ...p, key: p.userUUID }))}
        pagination={false}
        loading={getUsersLoading}
        onRow={(user) => {
          return {
            onDoubleClick: () => isModalOpen(user.userUUID),
          };
        }}
      />
    </Space>
  );
};

export default Users;
