import {
  PaymentCreatePayloadProps,
  PaymentEditPayloadProps,
} from "@/api/payments";
import {
  Alert,
  Divider,
  Form,
  FormInstance,
  Input,
  Select,
  Spin,
  Tabs,
  TabsProps,
} from "antd";
import styles from "../../InvoicesPanel/InvoicePanel.module.scss";
import { t } from "i18next";
import useAdminClients from "@/hooks/useAdminClients";
import { useEffect, useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { ModifiedInvoiceWithItems } from "@/root/models/invoice";
import usePaymentTables from "../hooks/usePaymentTables";
import { Payment } from "@/root/models/payment";
import useClientDeposit from "@/hooks/useClientDeposit";
import CustomInvoicesTable from "./CustomInvoicesTable";
import TableSkeleton from "@/components/TableSkeleton";

interface PaymentFormProps {
  form: FormInstance;
  paymentItems: Payment.Items[];
  type: "create" | "view" | "edit";
  handlePaymentCreate?: (values: PaymentCreatePayloadProps) => Promise<void>;
  handlePaymentEdit?: (values: PaymentEditPayloadProps) => Promise<void>;
  setEditedItems?: (items: ModifiedInvoiceWithItems[]) => void;
  chosenClinic?: string;
}

const { TextArea } = Input;
const { Option } = Select;

export const PaymentForm = ({
  form,
  type,
  handlePaymentCreate,
  handlePaymentEdit,
  paymentItems,
  setEditedItems,
  chosenClinic = "",
}: PaymentFormProps) => {
  const { adminClients, getAdminClientsLoading } = useAdminClients({});
  const [chosenClient, setChosenClient] = useState(chosenClinic);

  //Clinic deposit
  const { deposit, depositLoading, refetch } = useClientDeposit(chosenClient);

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>();

  const [tab, setTab] = useState("unpaid");

  useEffect(() => {
    if (chosenClient) {
      refetch();
    }
  }, [chosenClient]);

  useEffect(() => {
    if (deposit !== undefined) {
      form.setFieldValue("deposit", deposit);
    }
  }, [deposit]);

  const {
    //First table
    paymentInvoices,
    setPaymentInvoices,

    //Second table
    unpaidInvoices,

    loading,
  } = usePaymentTables(paymentItems, chosenClient);

  //Additional cases are cases we are adding to selected cases in order to have possibility
  //without problems to add or remove cases if selected table already has added cases
  //e.g. in Invoice Edit
  const [additionalInvoices, setAdditionalInvoices] = useState<
    ModifiedInvoiceWithItems[]
  >([]);

  const onlyChildrenItems = paymentInvoices
    .concat(additionalInvoices)
    .map((elem) => elem.children)
    .flat();

  //Set added cases to edit form by Invoice edit
  useEffect(() => {
    if (type === "edit" && !!onlyChildrenItems.length) {
      setEditedItems?.(paymentInvoices.concat(additionalInvoices));
    }
  }, [onlyChildrenItems.length]);

  //Payment create
  const handleSubmit = (values: any) => {
    handlePaymentCreate &&
      handlePaymentCreate({
        clientUUID: chosenClient,
        description: values.description || "",
        amount: values.amount,
        items: paymentInvoices
          .concat(additionalInvoices)
          .map((invoice) =>
            invoice.children.map((item) =>
              item.items.map((elem) => ({
                sum: elem.sum,
                caseUUID: item.case.caseUUID,
                rowUUID: elem.rowUUID,
                invoiceUUID: invoice.invoiceUUID,
              }))
            )
          )
          .flat(2),
      });
  };

  //Recalculate sum on payment invoice cases remove
  useEffect(() => {
    form.setFieldValue(
      "amount",
      onlyChildrenItems.reduce((prev, cur) => prev + cur.sum, 0)
    );
  }, [onlyChildrenItems]);

  const paymentTables: TabsProps["items"] = [
    {
      key: "unpaid",
      label: t("Неоплачені"),
      children: (
        <>
          <div>
            {!chosenClient ? (
              <Alert
                type="info"
                message={t("Оберіть спочатку контрагента")}
                style={{ width: "50%", marginTop: 20 }}
              />
            ) : chosenClient && loading ? (
              <TableSkeleton />
            ) : (
              <CustomInvoicesTable
                tableInstancesArray={unpaidInvoices}
                setSelectedInstances={setAdditionalInvoices}
                setSelectedRowKeys={setSelectedRowKeys}
                selectedRowKeys={selectedRowKeys}
                type={type}
                mainTable={true}
              />
            )}
          </div>
        </>
      ),
    },
    {
      key: "selected",
      label: t("Обрані"),
      children: (
        <CustomInvoicesTable
          tableInstancesArray={paymentInvoices.concat(additionalInvoices)}
          type={type}
          alreadyAddedInstancesArray={paymentInvoices}
          setAlreadyAddedInstances={setPaymentInvoices}
        />
      ),
    },
  ];

  return (
    <>
      <Form
        className={styles.formItem_input}
        requiredMark={false}
        form={form}
        onFinish={type === "edit" ? handlePaymentEdit : handleSubmit}
      >
        <div
          style={{
            display: "flex",
            gap: type === "edit" ? 0 : 50,
            flexDirection: type === "edit" ? "column" : "row",
            maxWidth: type === "edit" ? "50%" : "100%",
          }}
        >
          <Form.Item
            name="paymentNumber"
            label={t("Номер оплати")}
            style={{ flex: 1 }}
            labelCol={{ span: type === "edit" ? 4 : 5 }}
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            name="description"
            label={t("Опис")}
            style={{ flex: 1 }}
            labelCol={{ span: 4 }}
          >
            <TextArea disabled={type === "view"} rows={1} />
          </Form.Item>
        </div>

        {type !== "edit" && (
          <div style={{ display: "flex", gap: 50 }}>
            <Form.Item
              name="client"
              label={t("Контрагент")}
              rules={[
                { required: true, message: t("Заповніть поле") as string },
              ]}
              style={{ flex: 1 }}
              labelCol={{ span: 5 }}
            >
              <Select
                listHeight={1000}
                loading={getAdminClientsLoading}
                optionFilterProp="children"
                disabled={type === "view"}
                onChange={(value: string) => setChosenClient(value)}
              >
                {adminClients.map((client) => (
                  <Option key={client.clientUUID} value={client.clientUUID}>
                    {client.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="deposit"
              label={t("Депозит")}
              style={{ flex: 1, position: "relative" }}
              labelCol={{ span: 4 }}
            >
              <Input disabled />
              {depositLoading && (
                <Spin
                  style={{ position: "absolute", top: 6, left: 10 }}
                  indicator={
                    <LoadingOutlined
                      style={{
                        fontSize: 20,
                      }}
                      spin
                    />
                  }
                />
              )}
            </Form.Item>
          </div>
        )}

        <div style={{ display: "flex", gap: type === "edit" ? 0 : 50 }}>
          <Form.Item
            name="amount"
            label={t("Повна сума")}
            style={{ flex: 1 }}
            labelCol={{ span: type === "edit" ? 4 : 5 }}
          >
            <Input disabled={type === "view"} />
          </Form.Item>
          <div style={{ flex: 1 }}></div>
        </div>
      </Form>

      <Divider />

      {(type === "create" || type === "edit") && (
        <Tabs
          tabBarStyle={{ marginBottom: 0 }}
          type="card"
          items={paymentTables}
          activeKey={tab}
          onTabClick={setTab}
        />
      )}

      {type === "view" && (
        <CustomInvoicesTable
          tableInstancesArray={paymentInvoices.concat(additionalInvoices)}
          type={type}
        />
      )}
    </>
  );
};
