import { CSSProperties, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import { useForm } from "antd/es/form/Form";
import useTasks from "@/hooks/useTasks";
import { CreateTaskPayload } from "@/api/tasks";
import { Block } from "@/components/common";
import { Case } from "@/root/models/case";
import TaskPhotos, { TaskPhoto } from "@/modules/Tasks/components/TaskPhotos";
import { CaseStatusEnum } from "@/root/types";
import useSelectedCaseStatus from "@/hooks/useSelectedCaseStatus";
import TaskForm from "@/modules/Tasks/components/TaskForm";
import CasesTableInTasks from "@/modules/Tasks/components/CasesTableInTasks/CasesTableInTasksProps";

interface CreateTaskProps {
  style?: CSSProperties;
  chosenCase?: Case;
  setChosenCase: (c: Case) => void;
  selectedCases: Case[];
  removeSelectedCase: (caseUUID: string) => void;
  clearSelectedCases: () => void;
}

const CreateTask = ({
  style,
  chosenCase,
  setChosenCase,
  selectedCases,
  removeSelectedCase,
  clearSelectedCases,
}: CreateTaskProps) => {
  const { t } = useTranslation();
  const [form] = useForm();
  const [error, setError] = useState("");
  const [photos, setPhotos] = useState<TaskPhoto[]>([]);

  const [casesWithUse, setCasesWithUse] = useState<
    { case: Case; use: boolean }[]
  >([]);

  const { caseStatus } = useSelectedCaseStatus(selectedCases);

  useEffect(() => {
    const uniqueSelectedCases = selectedCases.filter(
      (c) => !casesWithUse.some((pc) => pc.case.caseUUID === c.caseUUID)
    );
    setCasesWithUse((prev) => [
      ...prev,
      ...uniqueSelectedCases.map((c) => ({ case: c, use: true })),
    ]);
  }, [selectedCases]);

  const onCreateSuccess = () => {
    form.resetFields();
    clearSelectedCases();
    setCasesWithUse([]);
  };

  const {
    createTask,
    createLoading: loading,
    createError: createError,
  } = useTasks({ onCreateSuccess });

  useEffect(() => {
    if (selectedCases.length) {
      form.setFieldValue("clientName", selectedCases[0].client.name);
    }
  }, [selectedCases, form]);

  const handleCreate = async (values: CreateTaskPayload) => {
    createTask({
      ...values,
      clientUUID: selectedCases[0]?.client.clientUUID,
      cases: casesWithUse.map((c) => ({
        caseUUID: c.case.caseUUID,
        use: c.use,
      })),
      attachments: photos.map(({ fileUUID, filename, description }) => ({
        filename,
        description,
        fileUUID,
      })),
    });
  };

  //Check on wrong statuses
  const wrongStatusCases = selectedCases.filter(
    (elem) =>
      elem.status.caseStatusUUID !== CaseStatusEnum["New"] &&
      elem.status.caseStatusUUID !== CaseStatusEnum["Done"] &&
      elem.status.caseStatusUUID !== CaseStatusEnum["Ready for pickup"] &&
      elem.status.caseStatusUUID !== CaseStatusEnum["Ready for delivery"]
  );

  const wrongStatusCasesNumber = wrongStatusCases
    .map((elem) => elem.caseNumber)
    .join(", ");

  const submit = () => {
    if (!selectedCases.length) {
      setError(t("Оберіть замовлення") as string);
      return;
    }

    if (wrongStatusCases.length) {
      setError(
        (t(
          "Ви намагаєтеся створити завдання з неправильним статусом замовлень! Видаліть випадки з неправильним статусом і повторіть спробу"
        ) +
          " - " +
          wrongStatusCasesNumber) as string
      );
      return;
    }

    form.submit();
    setError("");
  };

  return (
    <Block style={{ ...style, overflow: "auto" }}>
      <div style={{ marginBottom: 10 }}>
        <CasesTableInTasks
          selectedCases={casesWithUse}
          setSelectedCases={setCasesWithUse}
          chosenCase={chosenCase}
          setChosenCase={setChosenCase}
          removeSelectedCase={removeSelectedCase}
        />
      </div>
      <TaskForm
        form={form}
        cases={undefined}
        handleSubmit={handleCreate}
        error={error || createError}
        caseStatusInitial={caseStatus}
      />
      <TaskPhotos photos={photos} setPhotos={setPhotos} />
      <Button
        style={{ marginTop: 20 }}
        type="primary"
        loading={loading}
        onClick={submit}
      >
        {t("Створити")}
      </Button>
    </Block>
  );
};
export default CreateTask;
