import dayjs from "dayjs";
import AdvancedCasesSearchForm, {
  AdvancedSearchParameters,
} from "@/modules/CasesPanel/pages/Cases/components/AdvancedSearch/AdvancedCasesSearchForm";
import { SearchCasesParameters } from "@/modules/CasesPanel/pages/Cases/hooks/useCases";
import { DATE_FORMAT } from "@/root/consts";
import { Space } from "antd";

interface AdvancedSearchProps {
  onSearch: (values: SearchCasesParameters) => void;
  full: boolean;
  setStatusesExclude: (statuses: string[]) => void;
}

const AdvancedSearch = ({
  onSearch,
  full,
  setStatusesExclude,
}: AdvancedSearchProps) => {
  const handleSubmit = (values: AdvancedSearchParameters) => {
    onSearch({
      status: values.statuses?.join(",") || undefined,
      patientUUID: values.patients?.join(",") || undefined,
      employeeUUID: values.employees?.join(",") || undefined,
      clientUUID: values.adminClients?.join(",") || undefined,
      clientsEmployeeUUID: values.clientEmployees?.join(",") || undefined,
      date:
        values.date
          ?.map((d) => dayjs(new Date(d)).format(DATE_FORMAT))
          .join(",") || undefined,
      dueDate: values.dueDate
        ?.map((d) => dayjs(new Date(d)).format(DATE_FORMAT))
        .join(","),
    });
  };

  return (
    <Space direction="vertical">
      <AdvancedCasesSearchForm
        full={full}
        handleSubmit={handleSubmit}
        setStatusesExclude={setStatusesExclude}
      />
    </Space>
  );
};

export default AdvancedSearch;
