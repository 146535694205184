import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import {
  Alert,
  Button,
  Form,
  FormInstance,
  Input,
  Select,
  Space,
  TreeSelect,
} from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

import styles from "../ProductParameterForm.module.scss";

import { CreateProductParameterPayload } from "@/api/admin/productsParameters";
import useParametersTypes from "@/modules/ProductCatalog/components/ProductsParameters/hooks/useParametersTypes";
import { ProductParameterType } from "@/root/models/productParameter";
import useFilters from "@/hooks/useFilters";
import { Filter } from "@/root/models/filter";
import useFormAutoFocusInput from "@/hooks/useFormAutoFocusInput";

interface CaseFormProps {
  form: FormInstance<CreateProductParameterPayload>;
  handleSubmit: (values: CreateProductParameterPayload) => void;
  error: string;
  withStatus?: boolean;
}

const { Option } = Select;

const findFilter = (
  filters: Filter[],
  uuid: string
): Filter | Filter[] | undefined => {
  let found;
  filters.forEach((f) => {
    if (f.filterUUID === uuid) {
      if (f.children) {
        found = f.children;
      } else {
        found = f;
      }
    } else if (f.children) {
      found = findFilter(f.children, uuid);
    }
  });
  return found || undefined;
};

export const getNestedFilters = (filters: Filter[], filterUUIDs: string[]) => {
  return filterUUIDs
    .reduce((sum, uuid) => {
      const foundFilters = findFilter(filters, uuid);
      if (Array.isArray(foundFilters)) {
        sum.push(...foundFilters);
      } else if (foundFilters) {
        sum.push(foundFilters);
      }

      return sum;
    }, [] as Filter[])
    .filter((value, index, self) => {
      return self.findIndex((v) => v.filterUUID === value.filterUUID) === index;
    });
};

const ProductParameterForm = ({ form, handleSubmit, error }: CaseFormProps) => {
  const { t } = useTranslation();
  const { types, loading: getParametersTypesLoading } = useParametersTypes();
  const { filters, getFiltersLoading } = useFilters();
  const [chosenFilters, setChosenFilters] = useState<Filter[]>();
  const [type, setType] = useState<ProductParameterType | undefined>();
  const focusInputRef = useFormAutoFocusInput();

  useEffect(() => {
    if (types.length) {
      setType(
        types.find(
          (t) =>
            t.productsParameterTypeUUID ===
            form.getFieldValue("productsParameterTypeUUID")
        )
      );
    }
  }, [types]);

  useEffect(() => {
    if (filters.length && type?.name.toLowerCase().includes("filter")) {
      const filtersValues = form.getFieldValue("filtersValues");
      if (filtersValues?.length) {
        setChosenFilters(getNestedFilters(filters, filtersValues));
      }
    }
  }, [filters, type]);

  const handleSelectType = (typeUUID: string) => {
    const type = types.find((t) => t.productsParameterTypeUUID === typeUUID);
    if (type) {
      setType(type);
      form.resetFields(["list", "defaultValue"]);
    }
  };

  const inputType = type?.name.toLowerCase().includes("number") && "number";

  const parseTree = (filters: Filter[]): any => {
    return filters.map((filter) => ({
      key: filter.filterUUID,
      title: filter.name,
      value: filter.filterUUID,
      children: filter.children ? parseTree(filter.children) : undefined,
    }));
  };

  const handleChooseFilters = (filterUUIDs: string[]) => {
    setChosenFilters(getNestedFilters(filters, filterUUIDs));
  };

  return (
    <Form requiredMark={false} form={form} onFinish={handleSubmit}>
      <Form.Item
        rules={[
          {
            required: true,
            message: t("Введіть назву") as string,
          },
        ]}
        name="name"
        label={t("Назва")}
      >
        <Input
          ref={focusInputRef}
          placeholder={t("Введіть значення") as string}
        />
      </Form.Item>

      <Form.Item
        rules={[
          {
            required: true,
            message: t("Оберіть тип значення параметру") as string,
          },
        ]}
        name="productsParameterTypeUUID"
        label={t("Тип значення параметру")}
      >
        <Select
          listHeight={1000}
          loading={getParametersTypesLoading}
          onSelect={handleSelectType}
        >
          {types.map((type) => (
            <Option
              key={type.productsParameterTypeUUID}
              value={type.productsParameterTypeUUID}
            >
              {type.name || " "}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item name="defaultValue" label={t("Значення за замовчуванням")}>
        {type?.name.toLowerCase().includes("boolean") ? (
          <Select>
            <Option key="yes" value={"true"}>
              {t("Так")}
            </Option>
            <Option key="no" value={"false"}>
              {t("Ні")}
            </Option>
          </Select>
        ) : type?.name.toLowerCase().includes("filter") ? (
          <Select>
            {chosenFilters?.map((filter) => (
              <Option key={filter.filterUUID} value={filter.filterUUID}>
                {filter.name}
              </Option>
            ))}
          </Select>
        ) : (
          <Input
            placeholder={t("Введіть значення") as string}
            type={inputType || undefined}
          />
        )}
      </Form.Item>

      <Form.Item name="filtersValues" label={t("Значення фільтрів")}>
        <TreeSelect
          onChange={handleChooseFilters}
          loading={getFiltersLoading}
          treeDefaultExpandAll
          multiple
          allowClear
          treeData={parseTree(filters)}
        />
      </Form.Item>

      {type?.name.toLowerCase().includes("list") && (
        <Form.List name="list" initialValue={[""]}>
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => (
                <Form.Item
                  label={t("Список")}
                  className={classNames({
                    [styles.transparentLabel]: index > 0,
                  })}
                  required={false}
                  key={field.key}
                >
                  <Space>
                    <Form.Item
                      {...field}
                      name={[field.name, "value"]}
                      validateTrigger={["onChange", "onBlur"]}
                      rules={[
                        {
                          required: true,
                          message: t("Введіть значення") as string,
                        },
                      ]}
                      noStyle
                    >
                      <Input
                        placeholder={t("Введіть значення") as string}
                        type={inputType || undefined}
                        style={{ width: "auto" }}
                      />
                    </Form.Item>
                    <Form.Item
                      name={[field.name, "filtersValues"]}
                      style={{ marginBottom: 0 }}
                    >
                      <TreeSelect
                        style={{ maxWidth: 200, minWidth: 200 }}
                        loading={getFiltersLoading}
                        treeDefaultExpandAll
                        multiple
                        allowClear
                        treeData={parseTree(filters)}
                      />
                    </Form.Item>
                    {fields.length > 1 ? (
                      <MinusCircleOutlined
                        className={styles.deleteButton}
                        onClick={() => remove(field.name)}
                      />
                    ) : null}
                  </Space>
                </Form.Item>
              ))}

              <Form.Item className={styles.addButton} label="List">
                <Button onClick={() => add()} icon={<PlusOutlined />} />
              </Form.Item>
            </>
          )}
        </Form.List>
      )}

      {!!error && <Alert message={error} type="error" />}
    </Form>
  );
};

export default ProductParameterForm;
