import { Button, Modal, Space, notification } from "antd";
import { t } from "i18next";
import { useForm } from "antd/es/form/Form";
import InvoiceForm from "../components/InvoiceForm";
import { Block, Print } from "@/components/common";
import styles from "@/components/LaboratoryCaseForm/LaboratoryCaseForm.module.scss";
import { useLocation, useNavigate } from "react-router-dom";
import useInvoices from "@/hooks/useInvoices";
import { Invoice } from "@/root/models/invoice";
import { useEffect, useState } from "react";
import { InvoiceEditPayloadProps } from "@/api/invoices";
import InvoicePrint from "../components/InvoicePrint";
import useInfo from "@/hooks/useInfo";
import { generateFileName } from "@/utils/generateFileName";

const { confirm } = Modal;

const EditInvoice = () => {
  const [form] = useForm();
  const navigate = useNavigate();
  const location = useLocation();

  const invoice = location.state as Invoice;

  const [invoiceCasesForEdit, setInvoiceCasesForEdit] = useState<
    Invoice.Products[]
  >([]);

  const { info } = useInfo();

  //Fill invoice form
  useEffect(() => {
    if (invoice) {
      form.setFieldsValue({
        invoiceNumber: invoice.invoiceNumber,
        client: invoice.client?.clientUUID,
        description: invoice.description,
        amount: invoice.amount,
        paid: invoice.paid,
      });
    }
  }, [invoice]);

  const invoiceEditSubmit = () => {
    form.submit();
  };

  const navigateToInvoices = () => {
    // it is safe to use -1 (back), because access to /create page is through other pages
    navigate(-1);
  };

  const onEditSuccess = () => {
    navigateToInvoices();
    notification.success({
      message: t("Рахунок-фактура був успішно змінений"),
    });
  };

  const { editInvoice, editLoading, editError } = useInvoices({
    onEditSuccess,
  });

  //Edit error
  useEffect(() => {
    if (!!editError) {
      notification.error({
        message: editError,
      });
    }
  }, [editError]);

  const handleEdit = async (values: InvoiceEditPayloadProps) => {
    const invoiceEditAction = () => {
      editInvoice(invoice.invoiceUUID, {
        description: values.description,
        amount: values.amount,
        clientUUID: invoice.client?.clientUUID
          ? invoice.client?.clientUUID
          : "",
        dueDate: invoice.dueDate ? invoice.dueDate : "",
        paid: values.paid,
        cases: invoiceCasesForEdit.map((elem) => ({
          caseUUID: elem.caseUUID,
          rowUUID: elem.rowUUID,
          sum: elem.sum,
        })),
      });
    };
    if (invoice.paid) {
      confirm({
        title: t("Рахунок-фактура вже сплачений!"),
        content: t("Ви дійсно хочете внести зміни?"),
        okText: t("Так"),
        okType: "danger",
        cancelText: t("No"),
        onOk: () => invoiceEditAction(),
      });
    } else {
      invoiceEditAction();
    }
  };

  return (
    <>
      <div className={styles.actionButtons}>
        <Button htmlType="button" onClick={() => navigateToInvoices()}>
          {t("Назад")}
        </Button>
        <Space>
          <Button
            htmlType="button"
            onClick={() => {
              generateFileName("Invoice", invoice.invoiceNumber, invoice.date);

              window.print();
            }}
          >
            {t("Надрукувати рахунок-фактуру")}
          </Button>
          <Button
            htmlType="button"
            disabled={editLoading}
            onClick={() => navigateToInvoices()}
          >
            {t("Скасувати")}
          </Button>
          <Button onClick={() => invoiceEditSubmit()} loading={editLoading}>
            {t("Зберегти")}
          </Button>
        </Space>
        <Print>
          <InvoicePrint info={info} invoice={invoice} />
        </Print>
      </div>
      <Block style={{ height: "calc(100vh - 62px)" }}>
        <InvoiceForm
          form={form}
          type="edit"
          invoiceClient={invoice.client?.clientUUID}
          paid={invoice.paid}
          invoiceCases={invoice.cases}
          handleEdit={handleEdit}
          setInvoiceCasesForEdit={setInvoiceCasesForEdit}
        />
      </Block>
    </>
  );
};

export default EditInvoice;
