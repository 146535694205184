import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Descriptions, Select } from "antd";
import {
  CourierTaskActions,
  TaskAttachments,
} from "@/modules/CourierTasks/components";
import { Task } from "@/root/models/task";
import useTaskStatuses from "@/hooks/useTaskStatuses";
import useTasks from "@/hooks/useTasks";
import { TaskPhoto } from "@/modules/Tasks/components/TaskPhotos";

interface TaskProps {
  task: Task;
  page: number;
}

const { Option } = Select;

const TaskItem = ({ task, page }: TaskProps) => {
  const { t } = useTranslation();
  const { taskStatuses } = useTaskStatuses();
  const [taskStatus, setTaskStatus] = useState(task.taskStatus.taskStatusUUID);
  const [caseStatus, setCaseStatus] = useState<string>();
  const [photos, setPhotos] = useState<TaskPhoto[]>([]);
  const [editableCases, setEditableCases] = useState<Task["cases"]>([]);
  const [isEditing, setIsEditing] = useState(false);

  const { editTask, editLoading, editError } = useTasks({
    page,
    pageLimit: 25,
  });

  useEffect(() => {
    setEditableCases(task.cases);
    setPhotos(task.attachments);
  }, [task]);

  const handleCancel = () => {
    setEditableCases(task.cases);
    setPhotos(task.attachments);
  };

  const handleSave = () => {
    editTask(task.taskUUID, {
      cases: editableCases.map((c) => ({
        caseUUID: c.case.caseUUID,
        use: c.use,
      })),
      attachments: photos.map(({ fileUUID, filename, description }) => ({
        filename,
        description,
        fileUUID,
      })),
      trackingNumber: task.trackingNumber,
      responsibleUUID: task.client.clientUUID,
      dueDate: task.dueDate,
      completionDate: task.dueDate,
      description: task.description,
      caseStatusUUID: caseStatus,
      taskStatusUUID: taskStatus,
    });
  };

  return (
    <>
      <CourierTaskActions task={task} page={page} />
      <Descriptions
        key={task.taskNumber}
        bordered
        column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
      >
        <Descriptions.Item label={t("Контрагент")}>
          {task.client.name}
        </Descriptions.Item>
        <Descriptions.Item label={t("Адреса")}>
          {task.client.address}
        </Descriptions.Item>
        <Descriptions.Item label={t("Номер завдання")}>
          {task.taskNumber}
        </Descriptions.Item>
        <Descriptions.Item label={t("Статус завдання")}>
          {isEditing ? (
            <Select
              style={{ width: 140 }}
              value={taskStatus}
              onSelect={setTaskStatus}
            >
              {taskStatuses.map((status) => (
                <Option
                  key={status.taskStatusUUID}
                  value={status.taskStatusUUID}
                >
                  {status.name}
                </Option>
              ))}
            </Select>
          ) : (
            task.taskStatus.name
          )}
        </Descriptions.Item>
      </Descriptions>
      <TaskAttachments
        task={task}
        editLoading={editLoading}
        editError={editError}
        editableCases={editableCases}
        photos={photos}
        isEditing={isEditing}
        onCancel={handleCancel}
        onSave={handleSave}
        setEditableCases={setEditableCases}
        setCaseStatus={setCaseStatus}
        setPhotos={setPhotos}
        setIsEditing={setIsEditing}
      />
    </>
  );
};

export default TaskItem;
