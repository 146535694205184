import { Block } from "@/components/common";
import useChatNotifications from "@/hooks/useChatNotifications";
import { Button } from "antd";
import { t } from "i18next";
import { useEffect } from "react";

interface UnreadChatMessagesProps {
  setIsChatOpen: (isChatOpen: boolean) => void;
}

const UnreadChatMessages = ({ setIsChatOpen }: UnreadChatMessagesProps) => {
  const { chatNotifications, refetchChatNotif } = useChatNotifications();

  useEffect(() => {
    refetchChatNotif();
  }, []);

  const unreadMessages = chatNotifications?.reduce(
    (sum, cur) => sum + Number(cur.quantity),
    0
  );

  return (
    <Block
      style={{
        display: "flex",
        gap: 10,
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <div style={{ position: "relative" }}>
        <div>{`${t("Непрочитних повідомлень")}: ${
          unreadMessages ? unreadMessages : "0"
        }`}</div>
        {unreadMessages && unreadMessages > 0 ? (
          <div
            style={{
              position: "absolute",
              width: 7,
              height: 7,
              backgroundColor: "red",
              borderRadius: "50%",
              right: -7,
              top: -5,
            }}
          ></div>
        ) : undefined}
      </div>
      <Button
        onClick={() => {
          setIsChatOpen(true);
          setTimeout(() => {
            setIsChatOpen(false);
          }, 0);
        }}
      >
        {t("До чату")}
      </Button>
    </Block>
  );
};

export default UnreadChatMessages;
