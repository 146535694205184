import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Tabs, TabsProps } from "antd";
import {
  Extras,
  CaseStatusColors,
  FiltersCatalog,
  ProductsParameters,
  ParametersGroups,
  Products,
} from "./components";

const ProductCatalog = () => {
  const { t } = useTranslation();
  const [tabKey, setTabKey] = useState("productCatalog");

  const items: TabsProps["items"] = [
    {
      key: "productCatalog",
      label: t("Каталог виробів"),
      children: <Products />,
    },
    {
      key: "parameterGroups",
      label: t("Групи параметрів"),
      children: <ParametersGroups />,
    },
    {
      key: "parameters",
      label: t("Параметри виробів"),
      children: <ProductsParameters />,
    },
    {
      key: "filtersCatalog",
      label: t("Каталог фільтрів"),
      children: <FiltersCatalog />,
    },
    {
      key: "extras",
      label: t("Додаткові роботи і послуги"),
      children: <Extras />,
    },
    {
      key: "caseStatusColors",
      label: t("Кольори статусу замовлення"),
      children: <CaseStatusColors />,
    },
  ];

  return (
    <Tabs type="card" items={items} activeKey={tabKey} onTabClick={setTabKey} />
  );
};

export default ProductCatalog;
